import React from "react";

// Rendering individual images
const Image = ({ image, index, imageRemoveAction }) => {
  return (
    <div className="file-item">
      <img alt={`img - ${image.id}`} src={image.src} className="file-img" width="120"
        height="100" />
        <span onClick={() => imageRemoveAction( index, image )} className="crs"><i className="fa fa-times" aria-hidden="true"></i></span>
    </div>
  );
};

// PreviewImageList Component
const PreviewImageList = ({ images, imageRemoveAction }) => {

  //console.log( images );
  // render each image by calling Image component
  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        key={`${index}-image`}
        index={index}
        imageRemoveAction={imageRemoveAction}
      />
    );
  };

  // Return the list of files
  return <section className="file-list">{images.map(renderImage)} </section>;
};

export default PreviewImageList;