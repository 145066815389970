export const APP_BASE_URL = 'https://carryon.bike/';
export const APP_BACKEND_API_URL = 'https://admin.carryon.bike/carryon/';
//export const APP_BACKEND_API_URL = 'https://pxlsystechnologies.in:5001/carryon/';
//export const GOOGLE_RECAPTCHA_SITE_KEY = '6LfUx7cZAAAAABXwwVA_jkTu1GUBpDQfO9I9Y6lW';
export const GOOGLE_RECAPTCHA_SITE_KEY = '6LdmCRMaAAAAAPLDxHbJRFitkVFx3GiXODPrJwTN'; //live
//export const GOOGLE_RECAPTCHA_SITE_KEY = '6LexgAsaAAAAAEJ-uyjmzx08S_6sg-rVi18glc7q';
//export const GOOGLE_RECAPTCHA_SITE_KEY = '6LeyfQsaAAAAAPlJoGoCprM31djw7oH-Iv7LeHLi'; // http://127.0.0.1/

export const GOOGLE_CLIENT_ID = '941564461781-9gmm59b05opjjhiiq5no4305ieabatmk.apps.googleusercontent.com';
export const GOOGLE_VIEW_ID = '231568930';

export const GOOGLE_API_KEY = 'AIzaSyBesQCdz9tMKn6rJG4YKLbYGx1cW0pfJKQ';

export const COOKIE_NAME = 'googtrans';
export const COOKIE_COUNTRY = 'cookie_country';
export const TRANSLATE_BASE_PATH = '/en/';

export const headerData =()=>{
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json', 
        //'_method': 'PATCH',
        //'Access-Control-Allow-Origin': '*'
        'Authorization':'Bearer ' + (localStorage.getItem('csrf_token') ? JSON.parse(localStorage.getItem('csrf_token')) :'')
        //'x-access-token': localStorage.getItem('csrf_token')
    }
}

export const notLoginHeaderData =()=>{
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json', 
    }
}

export const headerForFileData =()=>{
    return {
        'Accept': 'application/json', 
        'X-Requested-With': 'XMLHttpRequest', 
        'Authorization':'Bearer ' + (localStorage.getItem('csrf_token') ? JSON.parse(localStorage.getItem('csrf_token')) :'')
        //'x-access-token': localStorage.getItem('csrf_token')
    }
}

export const BACKEND_API_STATUS = {
    ok: 'success',
    error: 'error',

};
