import React, { useState, useEffect,forwardRef, useRef, useImperativeHandle } from 'react'
import {getAutoSearchData } from '../stores/actions/common-action'

const AutoSearchInput = forwardRef((props, ref)=>{

    const {type, placeholder, setAutoSearchValue, value, updateSearchValue, indexVal} = props
    const [searchData, setSearchData] = useState([]);
    const [showSearch, setShowSearch] = useState(false)
    const [selectedValue, setSelectedValue] = useState('')
    const searchField = useRef();
    const [selectedFullValue, setSelectedFullValue] = useState([])
    const [divValue, setDivValue] = useState([])
    const inputRef = useRef()
    
    useEffect(() => {
        if(value){
            inputRef.current.value = value
        }
        document.addEventListener("click", handleClickOutside, true);
        
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		}
    }, [value])


    useImperativeHandle(ref, () => ({
  
        clearValues() {
            setSelectedValue('')
            inputRef.current.value ='';
        }
    
      }));

    const search = (e) => {
        e.preventDefault()
        let typedKeyArray = e.target.value.trim().split(',');
        let searchdata = typedKeyArray.pop();

        if(searchdata){
            getAutoSearchData('autocomplete',{q:searchdata,type:type}).then(res=>{
                if(!res.error){
                    
                    /* let selectedVal = selectedFullValue.length ? selectedFullValue.map(obj=> parseInt(obj.value)):[]
                    let resData = res.data.length > 0 ? res.data.filter(obj=>selectedVal.indexOf(parseInt(obj.value)) == -1 ):[] */
                    if(res.data.length > 0){
                        setShowSearch(true)
                    }
                    setSearchData(res.data)
                }
            })
        }else{
            setShowSearch(false)
        }
        
    }

    const handleBlur =(e)=>{
        props.autoSearchBlur(e.target.value, indexVal)
       
    }

    const setSeachValue = (value) => {
        setShowSearch(false)       
        setSelectedFullValue(value.label)
        props.autoSearchBlur(value.label, indexVal)
        inputRef.current.value = value.label
    }

    const handleClickOutside = event => {
        if (searchField.current && !searchField.current.contains(event.target)) {
            setShowSearch(false)
        }
      };


    return (
        <div className="bannerInner" ref={searchField}>
            <input type="text" className={props.isMandatory ?'mandatory':''}  name="keyword" ref={inputRef}  maxLength="50" placeholder={placeholder} onKeyUp={search} onBlur={handleBlur} autoComplete="off"/>
            {
                showSearch &&
                <div className="state-container-dropdown">
                    {
                      searchData && searchData.length >0 &&  searchData.map((data, index) => (
                            <div key={index} className="state" onClick={()=>setSeachValue(data)}>
                                <span>{data.label}</span>
                            </div>
                        ))
                    }

                </div>
            }

            <span className="icon banfactoryICon"></span>
        </div>
    )
})

export default AutoSearchInput
