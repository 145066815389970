import React from 'react';
import {useDropzone} from 'react-dropzone';
 
const DragDropzone = ({ onDrop, accept }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept
      });
    
    const getClassName = (className, isActive) => {
        if (!isActive) return className;
        return `${className} ${className}-active`;
      };
 
  return (
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center dropzoneholder">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
      </div>
    </div>
  )
}

export default DragDropzone;