import React from 'react';

const NoData = (props) => {
    const { message } = props;
    return (
        <div className="col-lg-12 col-md-12 col-12 noData">
            <p>{ message || 'No data found' }</p>
        </div>
    )
}

export default NoData;
