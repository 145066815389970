import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect, Link } from 'react-router-dom';
import { getBikeExpertList, callLoading, clearSearch } from '../../stores/actions/common-action';
import Pagination from '../../reuseables/Pagination';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import MainStructure from '../MainStructure';
import SearchBar from '../../reuseables/SearchBar';
import { RouteNames } from '../../includes/route-names';
import Item from './components/item';
import queryString from 'query-string';
import NoData from '../../reuseables/no-data';
import SortDropDown from '../../reuseables/SortDropDown';
import GetInTouch from '../../reuseables/get-in-touch';

const BikeEnthisticList =(props)=>{

	const bikeExpertData = useSelector( state => state.common.bikeExpertData );
	const myCountry = useSelector( state => state.common.myCountry );
	const resetSearch = useSelector( state => state.common.resetSearch );
	const [ activePage, setActivePage ] = useState( 1 );
	const [sortValue, setSortValue] = useState('')
    const [searchKeyData,setSearchKeyData] = useState({})
	const [queryDataValue,setQueryDataValue] = useState({})
	const [show, setShow] = useState(false);
	const _openModalHandler = () => setShow(true);
	//const [ keywords, setKeywords ] = useState('');
	//const [ locationData, setLocationData ] = useState( {} );
	//const [ searchCountry, setSearchCountry ] = useState( null );
	const dispatch = useDispatch();
    const queryData = queryString.parse( window.location.search );
	const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getBikeExpertList( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}

    useEffect(()=>{
		window.scrollTo(0,0);
		if(Object.keys(queryData).length > 0){
			if(Object.keys(queryData).indexOf('field_country_value') === -1){
                let queryValue = queryData;
                queryValue['sort_by'] = 'search_api_relevance'
                setSortValue('search_api_relevance')
            }
			setQueryDataValue(queryData);
			_getDestinationData(0, { ...queryData });
		}else{
			if( myCountry )
				_getDestinationData( 0, {field_country_value: myCountry} );	
		}

		if( resetSearch ){
			clearData();
			dispatch( clearSearch( false ) );
		}

	},[myCountry, resetSearch]);

	const pageChangeHandler = ( page )=>{
		const { keywords, locationData } = searchKeyData;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
        }else if( myCountry ){
            paramData['field_country_value']= myCountry;
        }
        if(sortValue){
            paramData['sort_by']= sortValue;
        }
		_getDestinationData( page - 1, { ...paramData });
	}

	const searchSubmitHandler = ( data ) =>{
		setQueryDataValue({})
        setSearchKeyData(data)
		const { keywords, locationData } = data;
		let paramData = {};
		if( keywords )
			paramData['body'] = keywords;

			if( locationData && locationData.location ){
				paramData['field_country_value']= locationData.location;
				if(sortValue =='search_api_relevance'){
					setSortValue('');
				}
			}
		if(sortValue){
            if(Object.entries(locationData).length > 0 && keywords && sortValue !='search_api_relevance'){
            paramData['sort_by']= sortValue;
            }
		}
		
		if(Object.entries(locationData).length == 0 && keywords){
			paramData['sort_by']= 'search_api_relevance';
			setSortValue('search_api_relevance')
			_getDestinationData(0, { ...paramData });
		   }else{

				_getDestinationData(0, { ...paramData });
		   }

	}

	const clearData =()=>{
        _getDestinationData( 0 );
        setSortValue()
        setSearchKeyData({})
        setQueryDataValue({})
    }

    const callSort =(value)=>{
        setSortValue(value)
        let paramData = {};
        if(Object.keys(queryDataValue).length > 0){
            const { body, field_country_value } = queryDataValue;
            if( body )
                paramData['body'] = body;
            if( field_country_value ){
                paramData['field_country_value']= field_country_value;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getDestinationData(0, { ...paramData });

        }else if(Object.keys(searchKeyData).length > 0){
            const { keywords, locationData } = searchKeyData;
            
            if( keywords )
                paramData['body'] = keywords;
            if( locationData && locationData.location ){
                paramData['field_country_value']= locationData.location;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getDestinationData(0, { ...paramData });
        }else{
            if( myCountry ){
                if(value){
                    _getDestinationData(0, { field_country_value: myCountry,sort_by:value } );
                }else{
                    _getDestinationData(0, { field_country_value: myCountry} );
                }
                
            }else{
                if(value){
                    _getDestinationData( 0,{sort_by:value});
                }else{
                    _getDestinationData( 0);
                }
                
            }
                
         }           
        
    }


    return (
        <>
         <Header/>
            <Banner bannerImage='enthubanner.jpg' pageName='Bike Enthusiasts'>
            <div className="bannercontent">
				<div>
                    <h3>Meet the <span> Bike Enthusiasts</span></h3>
		    	</div>
	    	</div>
            <SearchBar 
				is_expert_ask={true} 
				onSearchSubmit={searchSubmitHandler}	
			/>
            </Banner>
            <main className="site-main inner-main ">  
			<div className="listing_panel listing_panelnor" id="nor">
				<div className="container"> 
					<div className="listing_holder">
						<div className="row">

							<div className="col-lg-12 col-md-12 col-12">
							<div className="listingtop clear">
									
                                    <form>
                                    <SortDropDown type='expert' callSort={callSort} valueData={sortValue}/>
                                    </form>

                                </div>
								<div className="listing_middle">
									{/* <div className="map">
										<iframe 
											src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d128083.26573165871!2d10.645033742183582!3d59.89395286100231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e61f267f039%3A0x7e92605fd3231e9a!2sOslo%2C%20Norway!5e0!3m2!1sen!2sin!4v1594795211178!5m2!1sen!2sin" 
											frameBorder="0" 
											style={{border:0}} 
											allowFullScreen="" 
											aria-hidden="false" 
											tabIndex="0">
										</iframe>
									</div> */}
									<div className="row">
										{
											bikeExpertData.data && bikeExpertData.data.length > 0 ?  bikeExpertData.data.map( ( item, key ) =>
												<Item key={key} data={item}/>
											) : <NoData/>
										}
										
									</div>
									<Pagination 
										activePage={activePage} 
										pageData={bikeExpertData && bikeExpertData.hasOwnProperty('pageData') ? bikeExpertData.pageData : null }
										onPageChange={pageChangeHandler}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			 
			
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="getintouch">
							<div className="btnholder">
								<p>If you want to list your brand, shop/store, or retail chain because you want to reach out to the global cycle community, please get in touch with us.</p>
								<Link className="btn contctBtn getintouchBtn" to="#" data-toggle="modal" data-target="#getintouch" onClick={() => _openModalHandler()}>
									<span>Get In Touch</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<GetInTouch showExpert={show} handleModal={() => setShow(false)} />
		</main>
         <Footer/>   
        </>
    )
}

export default BikeEnthisticList
