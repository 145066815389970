import React from 'react';

function Video(props) {
    const { video } = props;

    if ( !video ) return null;

    return (
        <div className="box">
            <h3>video</h3>
            <div className="vdo">
                <iframe 
                    src={video} 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Video;
