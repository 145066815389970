import React, {useEffect} from 'react';
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import SearchBar from '../../reuseables/SearchBar'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Banner from './components/banner';
import FunWithoutFuel from './components/fun-without-fuel';
import CycleCommunity from './components/cycle-community';
import StepByStep from './components/step-by-step';
import DiscoverBikeRoute from './components/discover-bike-route';
import Platform from './components/platform';
import ExploreCarryon from './components/explore-carryon';
import Articles from './components/articles';
import Events from './components/events';
import Partners from './components/partners';
import $ from 'jquery';
import InstagramFeed from '../bike-destination/components/instagram-feed';

const Home =(props)=>{

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true)
		return () => {
			window.addEventListener('scroll', handleScroll, true)
		}
	}, [])


	const handleScroll =(e)=>{
		let window_height = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;
		let window_top_position = document.documentElement.scrollTop || document.body.scrollTop;
		let window_bottom_position = window_top_position + window_height
		var $animation_elements = $('.animation-element');
		$.each($animation_elements, function() {

			var $element = $(this);
		
			var element_height = $element.outerHeight();
		
			var element_top_position = $element.offset().top;
		
			var element_bottom_position = (element_top_position + element_height);
			 
		
			if ((element_bottom_position >= window_top_position) &&
		
				(element_top_position <= window_bottom_position)) {
		
			  $element.addClass('in-view');
		
			} else {
		
			  $element.removeClass('in-view');
		
			}
		
		  });
	}


    return (
        <>
        <Header/>
	    <Banner is_home={true}/> 
        <main className="site-main">
			<FunWithoutFuel/>

			<CycleCommunity/>

			<StepByStep/>

			<DiscoverBikeRoute/>

			<Platform/>

			<ExploreCarryon/>

			<Articles/>

			<section className="testimonial_sec animation-element">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Customer Love
						</h2>  
					</div>
					<div className="test_panel">
						<div className="row">
							<div className="col-lg-7 col-md-12 col-sm-12 col-12">
								<h3 className="title1">customer stories</h3>
								<OwlCarousel
									className="testslider"
									items={1}  
									lazyLoad={true}
									autoplay={true}
									loop  
									nav  
									margin={0} 
								>
									<div className="item">
										<p>We really like you objective</p>
										<span className="name">- A smart solution provider</span>
									</div>
								</OwlCarousel>
							</div>
						</div>
					</div>
				</div>  
				<div className="testclient">
					<span className="client client1">
						<img src={ require("../../assets/images/test/testimg1.png") } alt="testimg"/>
						<span className="tltp blue">Hey You're amazing</span>
					</span>
					<span className="client client2">
						<img src={ require("../../assets/images/test/testimg2.png") } alt="testimg"/>
						<span className="tltp green">Hey You're amazing</span>
					</span>
					<span className="client client3">
						<img src={ require("../../assets/images/test/testimg3.png") } alt="testimg"/>
						<span className="tltp purple">Somthing Here</span>
					</span>
					<span className="client client4">
						<img src={ require("../../assets/images/test/testimg4.png") } alt="testimg"/>
						<span className="tltp chklt">Somthing Here</span>
					</span>
					<span className="client client5">
						<img src={ require("../../assets/images/test/testimg5.png") } alt="testimg"/>
						<span className="tltp red">Somthing Here</span>
					</span>
					<span className="client client6">
						<img src={ require("../../assets/images/test/testimg6.png") } alt="testimg"/>
						<span className="tltp blue">Somthing Here</span>
					</span>
					<span className="client client7">
						<img src={ require("../../assets/images/test/testimg7.png") } alt="testimg"/>
						<span className="tltp green">Somthing Here</span>
					</span>
					<span className="client client8">
						<img src={ require("../../assets/images/test/testimg8.png") } alt="testimg"/>
						<span className="tltp chklt">Somthing Here</span>
					</span>
					<span className="client client9">
						<img src={ require("../../assets/images/test/testimg9.png") } alt="testimg"/>
						<span className="tltp red">Somthing Here</span>
					</span>
					<span className="client client10">
						<img src={ require("../../assets/images/test/testimg10.png") } alt="testimg"/>
						<span className="tltp purple">Somthing Here</span>
					</span>
					<span className="client client11">
						<img src={ require("../../assets/images/test/testimg11.png") } alt="testimg"/>
						<span className="tltp green">Somthing Here</span>
					</span>
				</div>
			</section>

			{/* <Events/> */}
			<section>
				<div className='container'>
					<InstagramFeed/>
				</div>		
			</section>
			
			<Partners/>
		</main>
        <Footer/>
        </>
    )
}

export default Home
