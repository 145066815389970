import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//import Pagination from '../../../reuseables/Pagination'
import { Link } from 'react-router-dom';
import Footer from '../../../includes/Footer';
import Header from '../../../includes/Header';
import { getDestinationDetails, callLoading, getFormContent } from '../../../stores/actions/common-action';
import Banner from '../../../includes/Banner'
//import TopTitleLink from '../components/top-title-link';
//import TravelContent from '../components/travel-content';
import SearchBar from '../../../reuseables/SearchBar';
import BikeTypeAccessories from './components/bike-type-accessories';
import WhyChooseUs from './components/why-choose-us';
import Map from './components/map';
import Keywords from './components/keywords';
import PhotoGallary from './components/photo-gallary';
import Video from './components/video';
import ReviewForm from '../../../reuseables/review-form';
import Pagination from '../../../reuseables/Pagination';
import ReviewListing from '../../../reuseables/review-listing';
import { RouteNames } from '../../../includes/route-names';
import FavouriteDetail from '../../../reuseables/FavouriteDetail';
import queryString from 'query-string';

const City = (props) => {
    const { city } = useParams();
    const dispatch = useDispatch();
    const queryData = queryString.parse( window.location.search );
	//const [ details, setDetails ] = useState( [] );
	const [ activePage, setActivePage ] = useState( 1 );
	const [ keywords, setKeywords ] = useState('');
	const [ locationData, setLocationData ] = useState( {} );
    const destinationDetails = useSelector( state => state.common.destinationDetails );
    //const [ selectedCountryValue, setSelectedCountryValue ] = useState( null );
    //const [ selectedCityValue, setSelectedCityValue ] = useState( city );
    const [isPreview, setIsPreview] = useState(false)
    
    const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getDestinationDetails( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
    }
    
    const searchSubmitHandler = ( data ) =>{
        const { keywords, locationData } = data;
        //console.log( 'kkk======', keywords );
        let paramData = {}; let queryData ={};
        
        paramData['alias'] = `/${city}`;

		if( keywords ){
            paramData['body'] = keywords;
            queryData['body']= keywords;
            setKeywords( keywords );
        }
        if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}
        /*
		if( locationData && Object.keys(locationData).length > 0 ){
			paramData['lat'] = locationData.lat;
			paramData['long'] = locationData.long;
			if( locationData.city )
				paramData['field_city_value'] = locationData.city;
			if( locationData.country ){
				paramData['field_country_value'] = locationData.country;
				//setSearchCountry( locationData.country );
			}
        }
        */
       var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
        })
		
		props.history.push(RouteNames.biking + queryString)
		//console.log('TEST',queryString );
		return
		//console.log( locationData );
		_getDestinationData( 0, { ...paramData } );
    }
    
    //console.log( 'kkk======', keywords );
    
    useEffect(() => {
		//window.scrollTo(0, 0);
		// console.log("CITY====", `/${city}`);
		// dispatch(callLoading(true));
		// dispatch( getDestinationDetails(0, { alias: `/${city}` }) ).then( resp => { 
			
		// 	if( !resp.error ){
		// 		const { data } = resp.data;
		// 		if( data && data.length > 0 )
		// 			setDetails( data[0] );
        //     }
            
        //     window.scrollTo(0, 0);

		// 	dispatch(callLoading(false)); } )
		// .catch( error => dispatch(callLoading(false)) );
        //     //setSelectedCityValue( city );
        window.scrollTo(0, 0);
        if(queryData && queryData.preview){
            setIsPreview(true)
            dispatch(callLoading(true))
			dispatch(getFormContent(`bike-destination/userdata?page=0&content_id=${city}`,'fetch_destination_details')).then(res=>{
                dispatch(callLoading(false))
            })
		}else{
            _getDestinationData( 0, { alias: `/${city}` });
        }
        
    }, [city]);

    // const pageChangeHandler = ( page )=>{
    //     //console.log("CITY====", `/${city}`);
    //     _get
	// 	dispatch(callLoading(true))
	// 	dispatch( getDestinationDetails( page - 1, { field_city_value: `/${city}` } ) ).then( resp => { 
			
	// 		if( !resp.error && resp.data && resp.data.pageData ){
	// 			const { currentPage } = resp.data.pageData;
				
	// 			setActivePage( currentPage + 1 );
	// 			window.scrollTo(0,0);
	// 		}

	// 		dispatch(callLoading(false)); } )
	// 	.catch( error => dispatch(callLoading(false)) );
    // }

    const pageChangeHandler = ( page )=>{
		_getDestinationData( page - 1, { field_city_value: `/${city}` } );
	}
    
    //console.log( destinationDetails );
    
    return (
        <>
			<Header />
			<Banner bannerImage='destinationbnr.jpg' parentRoute={RouteNames.biking} parentPageName='Biking Destination' pageName={destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].title}>
			<div className="bannercontent">
				<div>
				<h3><span>Destinations To Explore On Bike</span></h3> 
		    	</div>
	    	</div>
            {
                !isPreview && <SearchBar is_expert_ask={true} onSearchSubmit={searchSubmitHandler} />
            }
                
            </Banner>
			<main className="site-main inner-main">
				 <div className="detailsmain">
                <div className="container">
                    <div className="detailsholder">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-12">
                                <div className="detailsleft">
                                    
                                    { destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_logo &&
                                        <span className="titlelogo titlelogo1">
                                            <img src={destinationDetails.data[0].field_logo}  alt=""/>
                                        </span>
                                    }
                                    
                                    <h2 className="notranslate" >{ destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].title }</h2> 
                                    <ul className="address">
                                        <li>
                                            {
                                                destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_city &&
                                                <>
                                                    <img src={require('../../../assets/images/addressico.png')} alt=""/>
                                                    <span>{ destinationDetails.data[0].field_city }</span>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            {
                                                destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_country_icon &&
                                                <img src={destinationDetails.data[0].field_country_icon} alt=""/>
                                            }
                                            {
                                                destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_country &&
                                                <span>{ destinationDetails.data[0].field_country }</span>
                                            }
                                            
                                        </li>
                                    </ul>
                                    <div className="dtldiv">
                                        <h3 className="brandname rtng"> 
                                        {
                                            destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_rating && 
                                            <span className="rating">
                                                <span className="rate" style={{width:`${ Number(destinationDetails.data[0].field_rating) * 20 }%`}}></span>
                                            </span>
                                        }
                                        {
                                            destinationDetails.data && destinationDetails.data.length > 0 &&
                                            <FavouriteDetail isPreview={isPreview}  data={destinationDetails.data[0]} content_id={destinationDetails.data[0].content_id} type='bdestination'/>
                                        }
                                            
                                            
                                        </h3>

                                        {
                                            destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].field_url &&
                                            <h3 className="shopweb brandname">
                                                <span className="ico">
                                                    <img src={require('../../../assets/images/url.png')} alt=""/>
                                                </span>
                                                <span>URL:</span>
                                                <a className="" href={ destinationDetails.data[0].field_url } target="_blank">
                                                    { destinationDetails.data[0].field_url }
                                                </a>
                                            </h3>
                                        }

                                                   
                                    </div>
                                     {
                                        destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].body &&
                                        <div className="brandav">
                                            <h3>Short Description</h3>
                                            <div className="main" dangerouslySetInnerHTML={{__html: destinationDetails.data[0].body }}>
                                            </div>
                                        </div>
                                     }
                                    {/* <BikeTypeAccessories/>    */}
                                    {/* <WhyChooseUs/>  */}
                                    <ReviewListing 
                                        contentId={
                                            destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].content_id
                                            ? destinationDetails.data[0].content_id : null
                                    }/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="reservationform"> 
                                {
                                    destinationDetails.data && destinationDetails.data.length > 0 && 
                                    <>
                                        <Map latLong={ destinationDetails.data[0].field_geofield }/>
                                        {
                                            destinationDetails.data[0].field_keywords &&
                                            <Keywords 
                                            keys={ destinationDetails.data[0].field_keywords} 
                                            selectedKey={keywords}
                                            onselect={searchSubmitHandler}/>
                                        }
                                        
                                            {
                                                destinationDetails.data[0].field_photo && <PhotoGallary photos={ destinationDetails.data[0].field_photo }/>
                                            }
                                        
                                        <Video video={ destinationDetails.data[0].field_video_embed}/>
                                        {
                                            !isPreview && <ReviewForm 
                                            contentId={
                                                destinationDetails.data && destinationDetails.data.length > 0 && destinationDetails.data[0].content_id
                                                ? destinationDetails.data[0].content_id : null
                                            }
                                        />
                                        }
                                        
                                    </>
                                }
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <Pagination 
                    activePage={activePage}
                    pageData={ destinationDetails && destinationDetails.hasOwnProperty('pageData') ? destinationDetails.pageData : null }
                    onPageChange={pageChangeHandler}
                />
            </div>
			</main>

			<Footer />
		</>
    )
}

export default City;
