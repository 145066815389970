import React from 'react';

const BikeType = (props) => {
    const { data, title } = props;
    const [ bikeTypes, setBikeTypes ] = React.useState([]);

    React.useEffect(()=>{
        if( data ){
            const _data = data.split(',').filter(el=>el);
        if( _data.length > 0 )
            setBikeTypes( [ ..._data ]);
        }
    },[data]);

    //console.log( data );

    return (
        <div className="detailstable">
            <div className="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th>
                                { title || 'Bike Types and accessories' }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bikeTypes.length > 0 && bikeTypes.map( (type, index) => 
                                <tr key={index}>
                                    <td>
                                        <span className="name" dangerouslySetInnerHTML={{ __html: type}}></span>
                                    </td>
                                </tr>
                            )
                        }
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BikeType;
