import React from 'react';

const Item = (props) => {
    const { title, img } = props;
    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
                <span className="img">
                    <img src={ require(`../../../assets/images/${img}`) } alt="" />
                </span>
                <h3>{ title }</h3> 
            </div>
        </div>
    )
}

export default Item;
