import React, { useState, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../constants/config';

const INPUT_CHANGED = 'input_changed';
const INPUT_TOUCHED = 'input_touched';

const expertReducer = (state, action) =>{
    switch( action.type ){
        case INPUT_CHANGED: {
            
            const { formData, fromError, isFormValid } = state;
            let errorFlag = false;
            
            if( action.value ){
                formData[ action.field ] = action.value;
                fromError[ action.field ] = false;
            }

            for( let _k in formData ){
                if( !formData[_k] )
                    errorFlag = true;
            }

            return {
                ...state,
                formData,
                fromError,
                isFormValid : !errorFlag
            };
        }

        case INPUT_TOUCHED: {
            const { formTouch } = state;
            formTouch[ action.field ] = true;
            
            return {
                ...state,
                formTouch
            };
        }
    }

    return state;
}

const AskExpert = (props) => {
    
    const {showExpert, handleModal } = props;
    const [ show, setShow ] = useState( showExpert );
    const handleClose = () => {setShow(false); handleModal(); }

    // state initialization
    const initState = {
        formData: {
            name : '',
            email: '',
            phoneNo : '',
            yourQuery: ''
        },

        fromError: {
            name : true,
            email: true,
            phoneNo : true,
            yourQuery : true
        },

        formTouch: {
            name : false,
            email: false,
            phoneNo : false,
            yourQuery : false
        },

        fromErrorMsg: {
            name : 'Name is required',
            email: 'Email is required',
            phoneNo : 'Phone number is required',
            yourQuery : 'Query is required',
        },
        isFormValid: false
    }

    const formSubmitHandler = ( ev ) =>{
        ev.preventDefault();
        const { formData, isFormValid } = expertState;
        
        if( isFormValid ){
            handleClose();
        }else{
            //console.log( isFormValid );
            _showErrors();
        }

            
    }

    function _showErrors(){
        const { formData } = expertState;
        for ( let _k in formData ){
            expertDispatch({ type: INPUT_TOUCHED, field: _k });
            expertDispatch({ type: INPUT_CHANGED, field: _k, value: formData[_k] });
        }
    }

    function onChangeCaptcha(value) {
        console.log("Captcha value:", value);
    }

    useEffect(()=>{
        setShow( showExpert );
    },[showExpert]);

    const [ expertState, expertDispatch ] = useReducer( expertReducer, initState );

    //console.log( showExpert);
    
    return (
        <Modal className="cmnmodal logmodal gensmallmodal" size="lg" centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form className="logholder" onSubmit={formSubmitHandler}> 
                    <Link className="logo" to="#">
                        <img src={ require("../assets/images/logo.png")} alt=""/>
                    </Link>
                    <h3>Do You Need An Expert Opinion?</h3>
                    <Form.Group> 
                        <Form.Control 
                            className={ expertState.fromError.name && expertState.formTouch.name ? 'error' : null }
                            type="text" 
                            placeholder="Name" 
                            onBlur={(ev) => expertDispatch({type: INPUT_TOUCHED, field: 'name'})}
                            onChange={(ev) => expertDispatch({ type: INPUT_CHANGED, field: 'name', value: ev.target.value })}
                        />
                        { expertState.fromError.name && expertState.formTouch.name &&
                            <span className='error'>
                                { expertState.fromErrorMsg.name }
                            </span>
                        }  
                    </Form.Group>
                    <Form.Group> 
                        <Form.Control 
                            className={ expertState.fromError.email && expertState.formTouch.email ? 'error' : null }
                            type="email" 
                            placeholder="Email"
                            onBlur={(ev) => expertDispatch({type: INPUT_TOUCHED, field: 'email'})}
                            onChange={(ev) => expertDispatch({ type: INPUT_CHANGED, field: 'email', value: ev.target.value })}
                        />
                        { expertState.fromError.email && expertState.formTouch.email &&
                            <span className='error'>
                                { expertState.fromErrorMsg.email }
                            </span>
                        }
                    </Form.Group>
                    <Form.Group> 
                        <Form.Control 
                            className={ expertState.fromError.phoneNo && expertState.formTouch.phoneNo ? 'error' : null }
                            type="text" 
                            placeholder="Phone No" 
                            onBlur={(ev) => expertDispatch({type: INPUT_TOUCHED, field: 'phoneNo'})}
                            onChange={(ev) => expertDispatch({ type: INPUT_CHANGED, field: 'phoneNo', value: ev.target.value })}
                        />
                        { expertState.fromError.phoneNo && expertState.formTouch.phoneNo &&
                            <span className='error'>
                                { expertState.fromErrorMsg.phoneNo }
                            </span>
                        } 
                    </Form.Group> 
                    <Form.Group>
                        <Form.Control 
                            className={ expertState.fromError.yourQuery && expertState.formTouch.yourQuery ? 'error' : null }
                            as="textarea" 
                            rows="3" 
                            placeholder="Your Query" 
                            onBlur={(ev) => expertDispatch({type: INPUT_TOUCHED, field: 'yourQuery'})}
                            onChange={(ev) => expertDispatch({ type: INPUT_CHANGED, field: 'yourQuery', value: ev.target.value })}
                        />
                        { expertState.fromError.yourQuery && expertState.formTouch.yourQuery &&
                            <span className='error'>
                                { expertState.fromErrorMsg.yourQuery }
                            </span>
                        }
                    </Form.Group>
                    <Form.Group>
                        <ReCAPTCHA
                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={onChangeCaptcha}
                        />
                    </Form.Group>
                    <Form.Group> 
                        <Button 
                            disabled={ !expertState.isFormValid ? true : false } 
                            className={ !expertState.isFormValid ? 'formDisabled' : null } 
                            type="submit"
                        >
                            Submit
                        </Button>  
                    </Form.Group>
                    <Form.Group className="dont">
                        Don't have an account?<Link to="/registration"> Create One</Link>
                    </Form.Group>
                </Form>
            </Modal.Body>
      </Modal>
    );
}

export default AskExpert
