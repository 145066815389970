import React from 'react';
import queryString from 'query-string';

const LinkedIn = (props) => {
    const { buttonTitle, children } = props;

    const queryData = queryString.parse( window.location.search );

  const getURLWithQueryParams = (base, params) => {
    const query = Object
      .entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
      
    return `${base}?${query}`
  }

  const LINKEDIN_STATE = '123456'
  const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress'
  //const LINKEDIN_RIDERECT = 'http://localhost:3000'
  //const LINKEDIN_RIDERECT = 'https://pxlsystechnologies.in'
  const LINKEDIN_RIDERECT = 'https://carryon.bike'
  const LINKEDIN_CLIENT_ID = '86y7qm6ugpc3xz'
  const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_RIDERECT,
    state: LINKEDIN_STATE,
    scope: LINKEDIN_SCOPE
  });
  
  const signInWithLinkedin = () =>{
    
    window.location.href = LINKEDIN_URL;
     
  }

  //console.log( queryData );

  return <button onClick={signInWithLinkedin}>
            { buttonTitle ? buttonTitle : null }
            { children }
        </button>;

}

export default LinkedIn
