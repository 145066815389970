import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from './route-names';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { openAuthModal } from '../stores/actions/auth-action';
import { useSelector, useDispatch } from 'react-redux';

const Footer = (props) => {
        const csrfToken = useSelector(state => state.auth.csrf_token);
        const dispatch = useDispatch();


    const checkLoginOpenModal = () => {
        if (!csrfToken) {
            dispatch(openAuthModal('login'))
        }
    }

    return (
        <footer className="site-footer">
            <div className="footertop">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                            <div className="ftrdiv">
                                <Link className="ftrlogo" to='/'>
                                    <img src={require('../assets/images/ftrlogo.png')} alt="" />
                                </Link>
                                <p>CarryOn.bike is a platform that is responsive to the GROWING NEEDS of CYCLISTS. The objective of CarryOn.Bike is to capture the consumer sentiments worldwide and develop a solution that will address the primary issues arising from more and more use of cycle in Commuting, Transport, Delivery of Items, Leisure, Sport and Fitness post the COVID outbreak.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-sm-6 col-12">
                            <div className="ftrdiv">
                                <h3>Explore</h3>
                                <ul className="ftrlist">
                                    <li><Link to={RouteNames.buy} onClick={()=>checkLoginOpenModal()}>Products</Link></li>
                                    <li><Link to={RouteNames.services} onClick={()=>checkLoginOpenModal()}>Services</Link></li>
                                    <li><Link to={RouteNames.smart_solution} onClick={()=>checkLoginOpenModal()}>smart solutions</Link></li>
                                     <li><Link to={RouteNames.bikeEnthusticList} onClick={()=>checkLoginOpenModal()}>Bike Enthusiasts</Link></li>
                                    {/* <li><Link to={RouteNames.usedBike}>used bike</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-sm-6 col-12">
                            <div className="ftrdiv">
                                <h3>About</h3>
                                <ul className="ftrlist">
                                    <li><Link to={RouteNames.member_ship}>membership plan</Link></li>
                                    <li><Link to={RouteNames.partners}>Partners</Link></li>
                                    <li><Link to={RouteNames.about}>about us</Link></li>
                                    <li><Link to={RouteNames.contactUs}>contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2 col-sm-6 col-12">
                            <div className="ftrdiv">

                                <h3>discover</h3>
                                <ul className="ftrlist">
                                    {/* <li><Link to={RouteNames.offers}>Offers</Link></li> */}
                                    <li><Link to={RouteNames.event} onClick={()=>checkLoginOpenModal()}>events</Link></li>
                                    <li><Link to={RouteNames.biking} onClick={()=>checkLoginOpenModal()}>Biking Destinations</Link></li>
                                    {/* <li><Link to={RouteNames.test}>Test</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-sm-6 col-12">
                            <div className="ftrdiv">
                                <h3>contact us</h3>
                                <ul className="ftrlist ftrlist1">
                                    <li>
                                        <address><i className="fa fa-map-marker" aria-hidden="true"></i>
												Registered in the Register of Business Enterprises, NORWAY,
												Organisation Number: 922 588 759
											</address>
                                    </li>
                                    <li>
                                        <h4>phone number</h4>
                                        <Link to="tel:4746577716" className="call"><i className="fa fa-phone" aria-hidden="true"></i>+47 46577716</Link>
                                    </li>
                                    <li>
                                        <h4>email address</h4>
                                        <Link className="mail" to="mailto:carryon.enquiry@gmail.com">
                                            <i className="fa fa-envelope-o" aria-hidden="true"></i>carryon.enquiry@gmail.com</Link>
                                    </li>
                                </ul>
                                <ul className="social">
                                    <li>
                                        <Link to={{pathname:"https://www.facebook.com/funwithoutfuel/"}} target="_blank">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname:"https://www.instagram.com/carryonbike/"}} target="_blank">
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname:"https://www.youtube.com/channel/UCas94cAYyAxiVhqhQoUo7Rw"}} target="_blank">
                                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname:"https://www.tripadvisor.com/Profile/funwithoutfuel"}} target="_blank">
                                            <i className="fa fa-tripadvisor" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footerbottom">
                <div className="container">
                    <p>Copyright &copy; 2020 Carryon bike . <Link to={RouteNames.privacyPolicy}>Privacy Policy</Link>|<Link to={RouteNames.termsAndCondition}>Terms & Condition</Link></p>
                </div>
            </div>
            <CookieConsent
                buttonText='Accept'
                onAccept={({ acceptedByScrolling }) => {
                    if (acceptedByScrolling) {

                    } else {

                    }
                }}
                enableDeclineButton
                declineButtonText='Decline'
                onDecline={() => {

                }}>We and our partners use <Link style={{ color: "#58aacc" }} to={RouteNames.privacyPolicy}>cookies</Link> to give you the best online experience, including to personalise advertising and content. Data about your interaction with this site and the ads shown to you may be shared with companies involved in the delivery and/or personalisation of ads on this site and elsewhere online.Please let us know if you agree</CookieConsent>
            
            {/*<CookieConsent
                buttonText='Accept'
                onAccept={({ acceptedByScrolling }) => {
                    if (acceptedByScrolling) {

                    } else {

                    }
                }}
                enableDeclineButton
                declineButtonText='Decline'
                onDecline={() => {

                }}>Information we collect about you. Each time you visit our website, we may automatically collect the following information:
                <p style={{ fontSize: "10px" }}>Technical information, including the internet protocol (IP)
                address used to connect your computer to the internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</p>
                <p style={{ fontSize: "12px" }}>Information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our website (including date and time); products you viewed or searched for; browser version; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.
                This information may be collected by placing Cookies on your computer. Please see below for more information about the way we use Cookies.</p></CookieConsent>*/}

        </footer>
    )
}

export default Footer
