import { headerData, headerForFileData } from '../../constants/config';
import Api from './api';
//const lindinApi = 'http://localhost/root/carryon/linkdin_auth.php';
//const lindinApi = 'https://pxlsystechnologies.in/linkdin_auth.php';
const lindinApi = 'https://carryon.bike/linkdin_auth.php';
export const USER_LOGIN = 'user_login';
export const USER_REGISTRATION = 'user_registration';
export const USER_SOCIAL_LOGIN = 'user_social_login';
export const USER_FORGOT_PASSWORD = 'user_forgot_password';
export const USER_RESET_PASSWORD = 'user_reset_password';
export const USER_LOGOUT = 'user_logout';
export const OPEN_LOGIN_MODAL = 'open_login_modal';
export const OPEN_REGISTRATION_MODAL = 'open_registration_modal';
export const OPEN_FORGOT_MODAL  = 'open_forgot_modal';
export const CLOSE_ALL_MODAL ='close_all_modal';
export const UPDATE_PROFILE ='update_profile';
export const UPDATE_PICTURE = 'update_picture';
export const EMAIL_VERIFICATION = 'email_verification';

export const login = (userData) => {

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParamsWithOutLOgin(`login`, 'POST', userData);
            if (result.status === 'ok') {
                return dispatch({
                    type: USER_LOGIN,
                    data: result.data,
                    csrf_token: result.csrf_token,
                    error: false
                });
            } else {
                return dispatch({
                    type: USER_LOGIN,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: USER_LOGIN,
                error: true,
                msg: error
            });
        }

    }
}

export const registration = (userData) => {

    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParamsWithOutLOgin(`register`, 'POST', userData);

            if (result.status === 'ok') {
                return dispatch({
                    type: USER_REGISTRATION,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: USER_REGISTRATION,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: USER_REGISTRATION,
                error: true,
                msg: error
            });
        }

    }
}


export const socialLogin = (userData) => {
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParamsWithOutLOgin(`social/login`, 'POST', userData);

            if (result.status === 'ok') {
                return dispatch({
                    type: USER_SOCIAL_LOGIN,
                    data: result.data,
                    csrf_token: result.csrf_token,
                    error: false
                });
            } else {
                return dispatch({
                    type: USER_SOCIAL_LOGIN,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: USER_SOCIAL_LOGIN,
                error: true,
                msg: error
            });
        }

    }
}

export const forgotPassword = async(email)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsWithOutLOgin(`reset/passwordlink`, 'POST', email);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data.msg
            }
         }else{
            return {
                error: true,
                data: [],
                msg:result.msg
            } 
         }
    }catch(err){

    }
}

export const verifyForgotToken = async(email)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsWithOutLOgin(`verify/token`, 'POST', email);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data.msg
            }
         }else{
            return {
                error: true,
                data: [],
                msg:result.msg
            } 
         }
    }catch(err){

    }
}

export const resetPassword = async(userData)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsWithOutLOgin(`update/password`, 'POST', userData);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data
            }
         }else{
            return {
                error: true,
                data: [],
                msg:result.msg
            } 
         }
    }catch(err){

    }
}

export const openAuthModal =(type)=>{
    return async (dispatch) => {
        if(type === 'login'){
            return dispatch({
                type: OPEN_LOGIN_MODAL
            });
        }else if(type === 'resgistration'){
            return dispatch({
                type: OPEN_REGISTRATION_MODAL
            });
        }else{
            return dispatch({
                type: OPEN_FORGOT_MODAL
            });
        }
    }
    
}

export const closeAuthModal =(type)=>{
    return async (dispatch) => {
            return dispatch({
                type: CLOSE_ALL_MODAL
            });      
    }
    
}

export const userLogout = (userData) =>{
    return async (dispatch) =>{
        return dispatch({
            type: USER_LOGOUT,
            data: {user:''},
            error: false
        });
        /* try {
            const api = new Api();
            const result = await api.submitWithParams(`logout`, 'POST', userData);

            if (result.status === 'ok') {
                return dispatch({
                    type: USER_LOGOUT,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: USER_LOGOUT,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: USER_LOGOUT,
                error: true,
                msg: error
            });
        } */
    }
};

export const linkdinGetUser = async(code)=>{
    try{
        const response = await fetch(lindinApi + '?code=' + code);

        const result = await response.json();
       
        if(result.status === 200){ 
            return {
                error: false,
                data: result.data
            }
         }else{
            return {
                error: true,
                data: []
            } 
         }
    }catch(err){

    }
}

export const updateUserProfile =(userData)=>{
    return async (dispatch)=>{
        try {
            const api = new Api();
            const result = await api.submitWithParams(`user`, 'PATCH', userData);
            if (result.status === 'ok') {
                return dispatch({
                    type: UPDATE_PROFILE,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: UPDATE_PROFILE,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: UPDATE_PROFILE,
                error: true,
                msg: error
            });
        }
    }
}


export const updateProfileImage =(image)=>{
    return async (dispatch)=>{
        try {
            const api = new Api();
            const result = await api.submitWithParams(`profile/image/upload`, 'POST', image);
            if (result.status === 'ok') {
                return dispatch({
                    type: UPDATE_PICTURE,
                    data: result.data,
                    error: false
                });
            } else {
                return dispatch({
                    type: UPDATE_PICTURE,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: UPDATE_PICTURE,
                error: true,
                msg: error
            });
        }
    }
}

export const emailVerifyLogin =(userData)=>{
    return async (dispatch) => {
        try {
            const api = new Api();
            const result = await api.submitWithParams(`email/verify`, 'POST', userData);
            console.log(result, 'heck')
            if (result.status === 'ok') {
                return dispatch({
                    type: EMAIL_VERIFICATION,
                    data: result.data,
                    csrf_token: result.csrf_token,
                    error: false
                });
            } else {
                return dispatch({
                    type: EMAIL_VERIFICATION,
                    error: true,
                    data: null,
                    msg: result.msg
                });
            }
        } catch (error) {
            return dispatch({
                type: EMAIL_VERIFICATION,
                error: true,
                msg: error
            });
        }

    }
}

export const contactUs = async(dataValue)=>{
    try{
        const api = new Api();
        const result = await api.submitWithParamsWithOutLOgin(`contactus`, 'POST', dataValue);
        if(result.status === 'ok'){ 
            return {
                error: false,
                data: result.data
            }
         }else{
            return {
                error: true,
                data: [],
                msg:result.msg
            } 
         }
    }catch(err){

    }
}
