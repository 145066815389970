import React, { useState } from 'react';

const WhyChooseUs = (props) => {
    const { dataChoose, dataOffers, priceMin, priceMax, currencySymbol } = props;

    const [ activeSection, setActiveSection ] = useState( 1 );

    const _handleTabActivation = ( activeTab ) =>{
        setActiveSection( activeTab );
    }
    
    return (
        <div className="detailstab">
            <div id="horizontalTab">
                <ul className="resp-tabs-list">
                    <li 
                        onClick={() => _handleTabActivation(1)}
                        className={activeSection === 1 ? 'active' : ''}
                    >Why Choose Us</li>
                    <li
                        onClick={() => _handleTabActivation(2)}
                        className={activeSection === 2 ? 'active' : ''}
                    >Offers</li>
                    <li
                        onClick={() => _handleTabActivation(3)}
                        className={activeSection === 3 ? 'active' : ''}
                    >price range</li>
                </ul>
                <div className="resp-tabs-container">
                    <div className={activeSection === 1 ? 'active' : ''} dangerouslySetInnerHTML={{ __html: dataChoose }}></div>
                    <div className={activeSection === 2 ? 'active' : ''} dangerouslySetInnerHTML={{__html: dataOffers }}></div>
                    <div className={activeSection === 3 ? 'active' : ''}>
                        {
                            priceMin && priceMax && 
                            `${currencySymbol}${Math.trunc(priceMin)} - ${currencySymbol}${Math.trunc(priceMax)}`
                        }
                </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs;
