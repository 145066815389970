import React, { useState, useRef, useEffect } from 'react'
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Login from '../pages/auth/Login';
import Registration from '../pages/auth/Registration';
import { RouteNames } from './route-names';
import { useSelector, useDispatch } from 'react-redux';
import { openAuthModal, userLogout, socialLogin, linkdinGetUser, emailVerifyLogin } from '../stores/actions/auth-action';
import { getUserRole, getInterestMaster, callLoading, getMyCountry, getServiceMaster} from '../stores/actions/common-action';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, Button, Form } from 'react-bootstrap';
import LanguageTranslate from './language';
import { COOKIE_NAME, COOKIE_COUNTRY, TRANSLATE_BASE_PATH } from '../constants/config';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Header = (props) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [openSideMenu, setOpenSideMenu] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const roles = useSelector(state => state.common.roles);
    const myCountry = useSelector(state => state.common.myCountry);
    const interest_master = useSelector(state => state.common.interest_master);
    const service_master = useSelector(state => state.common.service_master);
    const isLoading = useSelector(state => state.common.isLoading)
    //const [userToken, setUserToken] = useState('')
    const csrfToken = useSelector(state => state.auth.csrf_token);
    const userData = useSelector(state => state.auth.user);
    const userForms = localStorage.getItem('user_forms') ? JSON.parse(localStorage.getItem('user_forms')) : '' //useSelector(state => state.auth.userForms);
    const [openProfMenu, setOpenProfMenu] = useState(false)
    const queryData = queryString.parse(window.location.search);
    const [isCallLinkdin, setIsCallLinkdin] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    let history = useHistory();
    const profileMenuRef = useRef()
    const [allVendorForm, setAllVendorForm] = useState({
        destination: RouteNames.destinationForm, 
        enthusiasts: RouteNames.bikeEnthusticForm,
        product: RouteNames.buy_form, 
        event: RouteNames.eventForm, 
        solutions: RouteNames.smart_solution_form, 
        services: RouteNames.serviceForm
    })

    useEffect(() => {
       
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);

        if (roles.length == 0) {
            dispatch(getUserRole())
        }
        if (interest_master.length == 0) {
            dispatch(getInterestMaster())
        }
        if(service_master.length === 0){
            dispatch(getServiceMaster())
        }
        if (queryData && queryData.code && !csrfToken) {
            setIsCallLinkdin(true);
            dispatch(callLoading(true));
            linkdinGetUser(queryData.code).then(res => {
                if (res && res.data) {

                    let resData = JSON.parse(res.data);
                    const loginData = {
                        id: resData.id,
                        name: resData.localizedFirstName + ' ' + resData.localizedLastName,
                        email: '',
                        picture: resData.profilePicture ? resData.profilePicture : '',
                        social_type: 'linkdin',
                    }
                    dispatch(socialLogin(loginData)).then(res => {
                        setUserDataToStore(res, 'social')
                    })
                }
            }).catch(err => {
                dispatch(callLoading(false));
            })

        }
        if (queryData && queryData.token && queryData.hash && !csrfToken) {
            dispatch(emailVerifyLogin({ uuid: queryData.token, hash_value: queryData.hash })).then(resp => {
                if (!resp.error) {
                    setUserDataToStore(resp, 'normal')
                } else {
                    toast.error(resp.msg,
                        {
                            position: toast.POSITION.TOP_RIGHT
                        });
                }
            })
        }
        if(queryData && queryData.page && queryData.page=='registration'){
            dispatch(openAuthModal('resgistration'))
        }

        if (!myCountry) {
            dispatch(getMyCountry());
        }
        document.addEventListener("click", handleClickOutsideProfile, true);
        return () => {
            document.removeEventListener("click", handleClickOutsideProfile, true);
            window.removeEventListener('resize', handleResize);
        }

    }, [setIsCallLinkdin]);


    const handleClickOutsideProfile = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
            setOpenProfMenu(false)
        }

    }


    const setUserDataToStore = (resp, userType) => {
        dispatch(callLoading(false));
        let userData = resp.data;
        userData.user_type = userType
        localStorage.setItem('user', JSON.stringify(userData))
        localStorage.setItem('csrf_token', JSON.stringify(resp.csrf_token))
        var formArr = [];
        Object.entries(userData.form).forEach(val => {
            if (val[0] == 'bike_destination_form' && val[1] === true) {
                formArr.push({ name: 'destination', order: 5, icon: 'destinationico.png' })
            } else if (val[0] == 'bike_expert_form' && val[1] === true) {
                formArr.push({ name: 'enthusiasts', order: 3, icon: 'expertico.png' })
            } else if (val[0] == 'buy_form' && val[1] === true) {
                formArr.push({ name: 'product', order: 1, icon: 'productico.png' })
            } else if (val[0] == 'event_form' && val[1] === true) {
                formArr.push({ name: 'event', order: 4, icon: 'eventico.png' })
            } else if (val[0] == 'smart_solutions_form' && val[1] === true) {
                formArr.push({ name: 'solutions', order: 2, icon: 'smartico.png' })
            }
        })
        formArr.push({ name: 'services', order: 6, icon: 'destinationico.png' })
        localStorage.setItem('user_forms', JSON.stringify(formArr.sort((a, b) => (a.order - b.order))))
        toast.success('Welcome to carryon.bike platform. Enjoy the ride through our platform 🙂',
            {
                position: toast.POSITION.TOP_RIGHT
            });
    }

    const logOut = (e) => {
        dispatch(userLogout({ token: csrfToken })).then(res => {
            
            if (!res.error) {
                toast.success('Successfully logged out',
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
                localStorage.removeItem('user');
                localStorage.removeItem('csrf_token');
                setOpenProfMenu(false);
                history.push('/');
            }
        })
    }

    const registrationSuccess = () => {
        setOpenAlert(true)
    }

    const _handleLanguageSlide = (status) => {
        //setIsLanguageOpen( state => !state );
        setIsLanguageOpen(status);
    }

    const checkLoginOpenModal = () => {
        /* if (!csrfToken) {
            dispatch(openAuthModal('login'))
        } */
    }

    const _handleBaseLanguage = () => {
        //ev.preventDefault();

        const language = 'en';
        const tag = 'United States';
        const mainTag = '#googtrans(en|en)';
        var d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        //document.cookie = `${COOKIE_NAME}=${TRANSLATE_BASE_PATH}${language};${expires};path=/`;
        document.cookie = `${COOKIE_COUNTRY}=${tag};${expires};path=/`;
        //window.location.href = location.pathname + mainTag;
        //window.location.reload();

        var el = document.querySelector('.goog-te-combo');
        el.value = language;
        el.dispatchEvent(new Event('change'));

        localStorage.setItem('isDefaultSelected', '1');

        setIsLanguageOpen( false );
    }

    const openLanguageHandler = () =>{
        setIsLanguageOpen( false );
    }

    const _isActive = ( path ) =>{
        return path === location.pathname;
    }

    const _isActiveDetail = ( path ) =>{
      return  location.pathname.includes(path); 

       
    }

    const handleUserForm =()=>{
        if (!csrfToken) {
            dispatch(openAuthModal('login'))
        }
        if(userForms && userForms.length > 0 ){
            history.push(allVendorForm[userForms[0].name])           
        }
    }


    return (
        <>
            <header className="site-header">
                <div className="headertop">
                    <div className="container">
                        {
                            !csrfToken && <>
                                <Link className="login" to="#" onClick={() => dispatch(openAuthModal('login'))}> login</Link>
                                <Link className="register" to="#" onClick={() => dispatch(openAuthModal('resgistration'))} data-toggle="modal" data-target="#regmodal">register</Link>
                            </>
                        }
                        {/*
                            csrfToken && <Link className="notify" to="#"><i className="fa fa-bell" aria-hidden="true"></i>
                                    <span className="count">1</span>
                                </Link>
                        */}


                        <Link className="nicci" to={{ pathname: 'https://carryon.bike/partner' }} target="_blank">
                            <img src={require('../assets/images/nicci.png')} alt="nicci" />
                        </Link>
                        <div className="languageselection">
                            <span className="icondiv" onClick={() => _handleLanguageSlide(true)}>
                                <img src={require('../assets/images/language.png')} alt="nicci" />
                            </span>
                            
                            <div className={isLanguageOpen ? "coutrylistdropdown open" : "coutrylistdropdown"} >
                                <div className="top">
                                    <h2>Choose Language <span className="notranslate baseLang" onClick={_handleBaseLanguage}>Back to English</span></h2>
                                    <span onClick={() => _handleLanguageSlide(false)} className="close"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </div>
                                <LanguageTranslate onClose = {openLanguageHandler} />
                            </div>

                        </div>
                        {
                            csrfToken &&
                            <div className="profileaccount" ref={profileMenuRef}>
                                <Link className="acclk" to='#' onClick={(event) => { event.preventDefault(); setOpenProfMenu(prevstate => !prevstate); }}>
                                    {
                                        userData && userData.picture ? <img src={userData.picture} alt="Carryon.bike" style={{ height: 25, width: 25, borderRadius: 25 / 2 }} /> : <img src={require('../assets/images/profile.png')} alt="Carryon.bike" />
                                    }

                                </Link>
                                <div className="accountlist" style={{ display: openProfMenu ? 'block' : 'none' }}>
                                    <div className="top">
                                        <span className="img">
                                            {
                                                userData && userData.picture ? <img src={userData.picture} alt="Carryon.bike" style={{ borderRadius: 50 / 2 }} /> : <img src={require('../assets/images/profile.png')} style={{  borderRadius: 50 / 2 }} alt="Carryon.bike" />
                                            }
                                        </span>
                                        <div className="text">
                                            <span className="name">{userData.name}</span>
                                            {/*  <span className="brand">Based In UK</span> */}
                                        </div>
                                    </div>
                                    <div className="bottom">
                                        <Link to={RouteNames.myprofile} className="profile" onClick={() => setOpenProfMenu(false)}>my profile</Link>
                                        <h3>Account</h3>
                                        <ul>
                                            {
                                                userForms.length > 0 && userForms.map((form, index) => (
                                                    <li key={index}><Link to={allVendorForm[form.name]} onClick={() => setOpenProfMenu(false)}><img src={form.icon ? require('../assets/images/' + form.icon) : require('../assets/images/destinationico.png')} alt='' />Post Listing in {form.name}</Link></li>
                                                ))
                                            }

                                            {/* <li><Link to={RouteNames.myStatistic} onClick={() => setOpenProfMenu(false)}><i className="fa fa-line-chart" aria-hidden="true"></i>my statistics</Link></li> */}
                                            {
                                                userData != null && userData.user_type && userData.user_type !== 'social' &&
                                                <li><Link to={RouteNames.myprofile + '?tab=3'} onClick={() => setOpenProfMenu(false)}><i className="fa fa-unlock-alt" aria-hidden="true"></i>change  password</Link></li>
                                            }

                                            <li><Link to="#" onClick={logOut}><i className="fa fa-sign-out" aria-hidden="true"></i>logout</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }

                        <ul className="social">
                            <li>
                                <a href="https://www.facebook.com/funwithoutfuel/" target="_blank">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/carryonbike/" target="_blank">
                                    <i className="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCas94cAYyAxiVhqhQoUo7Rw" target="_blank">
                                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tripadvisor.com/Profile/funwithoutfuel" target="_blank">
                                    <i className="fa fa-tripadvisor" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="headerbottom clear">
                    <div className="container">
                        <Link className="logo" to="/" title="Carryon.bike">
                            <img src={require('../assets/images/logo.png')} alt="Carryon.bike" />
                        </Link>
                        <ul className="menulist">
                            <li className={(_isActive(RouteNames.buy) || _isActiveDetail(RouteNames.buy_detail) )? 'active' : null}><Link to={RouteNames.buy} onClick={() => checkLoginOpenModal()}>Products</Link>
                            </li>
                            <li className={ (_isActive(RouteNames.services) || _isActiveDetail(RouteNames.servicesDetail) )? 'active' : null}><Link to={RouteNames.services} onClick={() => checkLoginOpenModal()}>Services</Link>
                            </li>
                            {/* <li><Link to={RouteNames.repair} onClick={() => checkLoginOpenModal()}>repair</Link></li> */}
                            <li className={(_isActive(RouteNames.smart_solution) || _isActiveDetail(RouteNames.smart_solution_detail))? 'active' : null}><Link to={RouteNames.smart_solution} onClick={() => checkLoginOpenModal()}>smart solutions</Link></li>
                            <li className={(_isActive(RouteNames.bikeEnthusticList) || _isActiveDetail(RouteNames.bikeEnthusticDetails) )? 'active' : null}><Link to={RouteNames.bikeEnthusticList} onClick={() => checkLoginOpenModal()}>Bike Enthusiasts</Link></li>
                            {/* <li><Link to={RouteNames.usedBike}>used bikes</Link></li> */}
                            <li className={(_isActive(RouteNames.event) || _isActiveDetail(RouteNames.eventDetails))? 'active' : null}><Link to={RouteNames.event} onClick={() => checkLoginOpenModal()}>events</Link></li>
                            <li className={(_isActive(RouteNames.biking)  || _isActiveDetail(RouteNames.bikingDestinationDetails))? 'active' : null}><Link to={`${RouteNames.biking}`} onClick={() => checkLoginOpenModal()}>Biking Destinations</Link></li>
                            <li className={_isActive(RouteNames.member_ship)? 'active' : null}><Link to={RouteNames.member_ship}>membership plan</Link></li>
                            {/* <li><Link to={`${RouteNames.biking}/${ myCountry ? myCountry : null }`}>Biking Destinations</Link></li> */}
                            <li><Link to={{ pathname: 'https://blog.carryon.bike' }} target="_blank">blog</Link></li>
                            
                        </ul>
                        <div className="dnthldr">
                            <Link className="donate" to={{ pathname: 'https://act.girlup.org/onlineactions/EH3R6XBuiEW-1voB0njM_w2' }} target="_blank"><i className="fa fa-heart-o" aria-hidden="true"></i> donate</Link>

                            {
                                    !csrfToken ? 
                                  
                                    <Link className="register mb-register" to='#'  onClick={() => { setOpenSideMenu(false); dispatch(openAuthModal('resgistration'))}}><i className="fa fa-pencil-square-o" aria-hidden="true"></i> Register</Link>
                                    : <Link className="getexpert mb-register" to="#" onClick={handleUserForm}>
                                        <img src={require('../assets/images/expertIcon.png')} alt="expertIcon" />
                                        <span>
                                            List Yourself With CarryOn
                                        </span>
                                    </Link>
                                    
                                }

                            
                        </div>

                        {/* <div className="newrt">
                            <Link className="new" to="#">Route Planner</Link>
                            <ul className="nwrtlist">
                                <li><Link to="best-route.php"><i className="fa fa-road" aria-hidden="true"></i>Best Bike Route</Link></li>
                                <li><Link to="blank-route.php"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Start with Blank Route</Link></li>
                                <li><Link to="route-planner.php"><i className="fa fa-retweet" aria-hidden="true"></i>Collaborator</Link></li>
                            </ul>
                        </div> */}

                        <div className="menu">
                            <Link className="menubtn" to="#" onClick={() => setOpenSideMenu(prevState => !prevState)}></Link>
                        </div>
                    </div>
                </div>
            </header>


            <div className="loaddiv" style={{ display: isLoading ? 'block' : 'none' }}>
                <span className="loader"></span>
            </div>


            <div className={`menuholder ${openSideMenu ? 'menuholderadd' : ''}`} >

                <div>
                    <Link className="cross" to="#" onClick={() => setOpenSideMenu(false)}></Link>
                    <h3><img src={require('../assets/images/ftrlogo.png')} alt="Carryon.bike" /></h3>
                    <ul className="menulist1">
                        {windowDimensions.width < 1024 &&
                            <>
                                <li><Link to={RouteNames.buy} onClick={() => checkLoginOpenModal()}>Products</Link>
                                </li>
                                <li><Link to={RouteNames.services} onClick={() => checkLoginOpenModal()}>Services</Link>
                                </li>
                                {/* <li><Link to={RouteNames.repair} onClick={() => checkLoginOpenModal()}>repair</Link></li> */}
                                <li><Link to={RouteNames.smart_solution} onClick={() => checkLoginOpenModal()}>smart solutions</Link></li>
                                <li><Link to={RouteNames.bikeEnthusticList} onClick={() => checkLoginOpenModal()}>Bike Enthusiasts</Link></li>
                                {/* <li><Link to={RouteNames.usedBike}>used bikes</Link></li> */}
                                <li><Link to={RouteNames.event} onClick={() => checkLoginOpenModal()}>events</Link></li>
                                <li><Link to={`${RouteNames.biking}`} onClick={() => checkLoginOpenModal()}>Biking Destinations</Link></li>
                                <li><Link to={RouteNames.member_ship}>membership plan</Link></li>
                                {/* <li><Link to={`${RouteNames.biking}/${ myCountry ? myCountry : null }`}>Biking Destinations</Link></li> */}
                                <li><Link to={{ pathname: 'https://blog.carryon.bike' }} target="_blank">blog</Link></li>
                                {
                                    !csrfToken ?  <>
                                    <li><Link className="login" to='#' onClick={() => { setOpenSideMenu(false); checkLoginOpenModal()} }>Log In</Link></li>
                                    <li><Link className="register" to='#'  onClick={() => { setOpenSideMenu(false); dispatch(openAuthModal('resgistration'))}}>Register</Link></li>
                                    </>: <li><Link to='#' className="mobile-loginout" onClick={() => { setOpenSideMenu(false);logOut()} }>LogOut</Link></li>
                                }
                                
                            </>
                        }

                    </ul>
                </div>


            </div>




            <Login />
            <Registration registrationSuccess={registrationSuccess} />
            <ForgotPassword />


            <Modal className="cmnmodal logmodal gensmallmodal sucess_alert" centered show={openAlert} onHide={() => {setOpenAlert(false);history.push('/')}}>
                <Modal.Header closeButton>
                    <Modal.Title className="logholder">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="logholder">
                        <div>
                            <Link className="logo" to="#">
                                <img src={require('../assets/images/logo.png')} alt="" />
                            </Link>
                            <h3>Registration Success</h3>
                            <div className="formdiv">
                                <span><p>You have registered successfully.<br />A mail has been sent to your email <br />address for verification.<br />Please click on the link provided to confirm.</p></span>
                            </div>
                            <input type="submit" value="OK" onClick={() => {setOpenAlert(false);history.push('/')}} />

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Header
