import React, { useState, useEffect } from 'react';
import TotalUser from './total-user';
import UserSessionWise from './userSessionWise';
import UserWise from './userWise';
import { renderButton, checkSignedIn } from "./utils";

const MainReportWrapper = (props) => {
    const [isSignedIn, setIsSignedIn] = useState(false);

    const { component } = props;

    const updateSignin = ( signedIn ) => { //(3)
        setIsSignedIn( signedIn );
        if (!signedIn) {
          renderButton();
        }
      };
    
      const init = () => { //(2)
        checkSignedIn()
          .then((signedIn) => {
            updateSignin(signedIn);
          })
          .catch((error) => {
            console.error("SignIN", error);
          });
      };
    
      useEffect(() => {
        //window.gapi.load("auth2", init); //(1)
      });

    return (
        <div>
            {/* {!isSignedIn ? (
                <div id="signin-button"></div>
            ) : (
                component
            )} */ component}
        </div>
    )
}

export default MainReportWrapper
