import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';
import Characterstic from '../../../reuseables/Characterstic';
import Favourite from '../../../reuseables/Favourite';

const Item = (props) => {
    const { title, body, field_photo, field_video_embed, field_currency_type, field_note, alias } = props.item;
    
    const _getFirstImageUrl = ( photoLinks ) => {
        const photoArray = photoLinks.split(',');
        return photoArray[0];
    }

    //console.log( alias );
    
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="travelOuterSec travelOuterSec3">
                <div className="travelImg">
                    { field_photo && 
                        <>
                            <Link to={`${RouteNames.bikingDestinationDetails}${alias}`}>
                                <img src={ _getFirstImageUrl( field_photo ) } alt={title} />
                            <Favourite content_id={props.item.content_id} type='destination' is_already_favourite={props.item.is_already_favourite} fullData={props.item}/>
                            </Link>
                        </>
                        
                    }
                    
                    { !field_photo && field_video_embed && 
                        <>
                        <iframe 
                            width={360} 
                            height={230} 
                            src={ field_video_embed }
                            frameBorder={0}  
                            allowFullScreen>
                        </iframe>
                        <Favourite content_id={props.item.content_id} type='destination' is_already_favourite={props.item.is_already_favourite} fullData={props.item}/>
                        </>
                    }
                    {
                        field_note && 
                        <span className="note">
                            <strong>Note:</strong>
                            { field_note }
                        </span>
                    }
                    
                    {
                        field_currency_type && <span className="credit">{ field_currency_type }</span>
                    }
                      
                </div>
                <div className="travelContent">
                    <h4 className="notranslate" ><Link to={`${RouteNames.bikingDestinationDetails}${alias}`}>{ title }</Link></h4>
                    
                    <p>{ body.length > 100 ? body.substring(0, 100).concat('...') : body }</p>
                </div>
                {
                       props.item.field_shop_icon &&  <Characterstic field_shop_icon={props.item.field_shop_icon}/>
                    }
            </div>
        </div>
    )
}

export default Item;
