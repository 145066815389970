import React, { useState, useEffect } from 'react';
import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import GetInTouch from './components/get-in-touch';
import OurAvailabilityDetails from './components/our-availability-details';
import SocialLinks from './components/social-links';

const ContactUs = ( props ) => {

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    return (
        <>
            <Header/>
            <div className="banner innerbanner">
                <img  src={ require("../../assets/images/contactbanner.jpg") } alt="blog" />
                <div className="bannercontent">
                    <div>
                        <h3><span>Contact</span> Us</h3> 
                    </div>
                </div>	       	 
            </div> 
            <main className="site-main inner-main "> 
                <div className="contactinfoSection">
                    <div className="container">
                        <div className="row"> 
                            <div className="col-12">
                                <div className="tophead">
                                    <h2 className="title1">
                                        We love to help!
                                    </h2>
                                    <p>Carryon.bike is all about making your experience of cycling fun filled. We wish to partner with people who are open minded and share similar sentiments. Please reach out to us for any queries on carryon.bike</p>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="contactusSection">
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                                    <div className="touchForm">
                                        <h2 className="headerclassName">Get in Touch</h2>
                                            <GetInTouch />  
                                    </div> 
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div className="availDtls">
                                        <OurAvailabilityDetails/>
                                        <SocialLinks/>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d65492.6849482748!2d10.137343516245306!3d59.13831574989828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4646c090553a802b%3A0xc35b51a25c3ce1a2!2sSandefjord%2C%20Norway!5e0!3m2!1sen!2sin!4v1596019865383!5m2!1sen!2sin" frameBorder="0" style={{ border: 0, allowfullscreen: "", ariaHidden: false, tabindex: 0}}></iframe>
                </div>
            </main>
            <Footer/>   
        </>
    )
}

export default ContactUs;
