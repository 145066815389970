import React from 'react'

const Platform = (props) => {
    return (
        <section className="platform_sec animation-element">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Carryon.bike platform - For Whom
						</h2> 
						<p>Bringing the cycle community under 1 umbrella</p>
					</div>
					<div className="platform_panel">
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico1.png")} alt=""/>
									</span>
									<h3>Commuting</h3>
									<p>Are you commuting by bike to the office or university? Keep visiting carryon.bike for top tips.</p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0.5s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico2.png")} alt=""/>
									</span>
									<h3>Transportation</h3>
									<p>Its always a smart choice to use Cargo Bikes for transportation as an alternative to four wheelers.</p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="1s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico3.png")} alt=""/>
									</span>
									<h3>Recreation</h3>
									<p>Use the carryon.bike collaboration tool, to plan for a weekend with friends on a  bike route that suits all. </p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="1.5s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico4.png")} alt=""/>
									</span>
									<h3>Racing</h3>
									<p>Want to know the racing events in your city and around? Also get to compare the gears and invest on the best.</p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="2s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico5.png")} alt=""/>
									</span>
									<h3>Physical Traning</h3>
									<p>Unable to visit the gym? Check out the bike workouts,  ideal for beginners. </p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="2.5s">
									<span className="ico">
										<img src={ require("../../../assets/images/plico6.png")} alt=""/>
									</span>
									<h3>others</h3>
									<p>Carryon.bike could be the platform for corporate events, community events, Ability to meet experts via webinars and ask unanswered questions.</p>
									<a className="read" href="#">read more</a>
								</div>
							</div>
						</div>
					</div> 
				</div>
			</section>
    )
}

export default Platform
