import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addingFavourite } from '../stores/actions/common-action';

const FavouriteDetail =(props)=>{
    const { data,type,content_id,isPreview } = props;
    const dispatch = useDispatch();
    const [alreadyFavourite, setAlreadyFavourite] = useState()
    const [favouriteCount, setFavouriteCount] = useState()
    const [viewCount, setViewCount] = useState()

    useEffect(() => {
        setFavouriteCount(data && data.favourite_count ? data.favourite_count: 0)
        setAlreadyFavourite(data.is_already_favourite)
        setViewCount(data.view_count)
        return () => {
            
        }
    }, [data.favourite_count])

    const addToFavourite = (e) => {
        e.preventDefault()
        if(data.is_already_favourite === '1' || isPreview){
            return
        }
        dispatch(addingFavourite(content_id, type)).then(res => {
            if (!res.error) {
                setFavouriteCount(prevCount => Number(prevCount) +1)
                setAlreadyFavourite('1')
            }
        })
    }

    return (
            <div className="hoverdiv">
                <div>
                    <Link className="review" to="#" onClick={(e) =>{e.preventDefault()}}><i className="fa fa-comment-o" aria-hidden="true"></i>
                        
                    </Link>
                <span className="count">{data && data.feedback_count ? data.feedback_count : 0}</span>
                </div>
                <div>
                    <Link className="wishlist" to="#" onClick={(e) =>addToFavourite(e)}><i className="fa fa-heart" style={{color:alreadyFavourite == '1'?'#ffa8a8':''}} aria-hidden="true"></i>
                        
                    </Link>
                <span className="count">{favouriteCount}</span>
                </div>
                <div>
                    <Link className="view" title="View" to="#" onClick={(e) =>{e.preventDefault()}}><i className="fa fa-eye" aria-hidden="true"></i>
                        
                    </Link>
                    <span className="count">{viewCount ? viewCount :0}</span>
                </div>
            </div>
    )
}

export default FavouriteDetail
