import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addingFavourite } from '../stores/actions/common-action';

const Favourite = (props) => {
    const { content_id, type, is_already_favourite, fullData } = props
    const dispatch = useDispatch();
    const [alreadyFavourite, setAlreadyFavourite] = useState()
    const [favouriteCount, setFavouriteCount] = useState()
    const [viewCount, setViewCount] = useState()

    useEffect(() => {
        setFavouriteCount(fullData && fullData.favourite_count ? fullData.favourite_count: 0)
        setAlreadyFavourite(is_already_favourite)
        setViewCount(fullData && fullData.view_count ? fullData.view_count: 0)
        return () => {
            
        }
    }, [content_id])

    const addToFavourite = () => {
        if(is_already_favourite === '1'){
            return
        }
        dispatch(addingFavourite(content_id, type)).then(res => {
            console.log(res, '566')
            if (!res.error) {
                setFavouriteCount(prevCount => Number(prevCount) +1)
                setAlreadyFavourite('1')
            }
        })
    }

    return (
        <div className="hoverdiv">
            <span className="wishlist" to="#" onClick={() => addToFavourite()}><i className="fa fa-heart" aria-hidden="true" style={{color: alreadyFavourite == '1'?'#ffa8a8':''}}></i>
                <span className="count">{favouriteCount}</span>
            </span>
            <span className="view" title="View" to="#"><i className="fa fa-eye" aria-hidden="true"></i>
            <span className="count">{viewCount}</span>
            </span>
        </div>
    )
}

export default Favourite
