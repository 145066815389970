import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouteNames } from '../../../includes/route-names';
import { openAuthModal } from '../../../stores/actions/auth-action';

const FunWithoutFuel = (props) => {
    const dispatch = useDispatch();
    const csrfToken = useSelector(state => state.auth.csrf_token);

    const checkLoginOpenModal = () => {
        if (!csrfToken) {
            dispatch( openAuthModal('login'))
        }
    }

    return (
    <section className="service_sec animation-element">
    <div className="container">
        <div className="tophead">
            <h2 className="title1">
                Fun Without Fuel
            </h2>
            <p>Carryon.bike offers One-Stop-Service for Beginners and Casual Riders</p>
        </div>
        <div className="service_panel">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay="0s">
                        <span className="ico"> 
                            <img src={require('../../../assets/images/serviceico3.png')} alt=""/>
                        </span>
                        <div className="text"> 
                            <h3><Link to={RouteNames.buy} onClick={checkLoginOpenModal}>compare & buy</Link></h3>
                            <p>Choose the best brandsto fit your needs for cycling</p> 
                            <div className="vd">
                                <video controls>
                                    <source src={require('../../../assets/videos/polarvdo.mp4')}  type="video/mp4"/>
                                </video>
                            </div> 
                        </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay=".5s">
                        <span className="ico"> 
                            <img src={require('../../../assets/images/serviceico5.png')} alt=""/>
                        </span>
                        <div className="text">
                            <h3><Link onClick={checkLoginOpenModal} to={`${RouteNames.smart_solution}?body=park`}>Smart Parking</Link></h3>
                            <p>Find secure parking solutionsto feel safe from bike theft</p>
                            <div className="vd">
                                <iframe

                                    src="https://www.youtube.com/embed/OweUIcDMkPI" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay="1s">
                        <span className="ico"> 
                            <img src={require('../../../assets/images/serviceico6.png')} alt=""/>
                        </span>
                        <div className="text"> 
                            <h3><Link onClick={checkLoginOpenModal} to={RouteNames.biking}>Discover Biking Destinations</Link></h3>
                            <p>Find the perfect bike trails for family trips, holidays, weekends and adventures.</p> 
                            <div className="vd">
                                <iframe 
                                    src="https://www.youtube.com/embed/iguezaNmRbc" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen></iframe>
                            </div> 
                        </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay="1.5s">
                        <span className="ico"> 
                            <img src={require('../../../assets/images/serviceico4.png')} alt=""/>
                        </span>
                        <div className="text"> 
                            <h3><Link onClick={checkLoginOpenModal} to={RouteNames.smart_solution}>Smart Solutions</Link></h3> 
                            <p>Explore Smart Solutions for Smart Biking.</p> 
                            <div className="vd">
                                <iframe 
                                    src="https://www.youtube.com/embed/IpuNrQk-c9M" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay="2s">
                        <span className="ico"> 
                            <img src={require('../../../assets/images/serviceico1.png')} alt=""/>
                        </span>
                        <div className="text">
                            <h3><Link onClick={checkLoginOpenModal} to={`${RouteNames.services}?body=rent`}>rent</Link></h3>
                            <p>Want to rent an electric bike? Know the best options to rent a bike</p> 
                            <div className="vd"> 
                                <img src={require('../../../assets/images/rent1.jpeg')} alt=""/>
                            </div>
                        </div>
                </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="servicediv wow fadeIn" data-wow-duration=".5s" data-wow-delay="2.5s">
                        <span className="ico">
                            <img src={require('../../../assets/images/serviceico2.png')} alt=""/>
                        </span>
                        <div className="text">
                            <h3><Link onClick={checkLoginOpenModal} to={`${RouteNames.services}?body=repair`}>repair</Link></h3>
                            <p>Get quick help from your friendly Repair Buddy when your cycle breaks down.</p>
                            <div className="vd">
                                <iframe 
                                    src="https://www.youtube.com/embed/3OFt0upCntg" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    )
}
export default FunWithoutFuel;