import React, { useState, useCallback, useRef } from 'react'
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import { useSelector, useDispatch } from 'react-redux';
import { submitForm, callLoading, getFormEditContent, uploadedImages } from '../../stores/actions/common-action'
import { toast } from 'react-toastify';
import DragDropzone from '../../reuseables/drag-drop';
import PreviewImageList from '../../reuseables/preview-image-list';
import AutoSearchInput from '../../reuseables/AutoSearchInput';
import GoogleSearchAutocomplete from '../../reuseables/google-search-autocomplete';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RouteNames } from '../../includes/route-names';
import Goto from '../../reuseables/goto';
import queryString from 'query-string';

const ServicesForm = (props) => {
    const [form_error, setFormError] = useState({});
    const dispatch = useDispatch();
    const queryData = queryString.parse(window.location.search);
    const service_type_master = useSelector(state => state.common.service_master);
    const [images, setImages] = useState([]);
    const [imagesFile, setImagesFile] = useState([]);
    const [brandArr, setBrandArr] = useState([1]);
    const [brandArrValue, setBrandArrValue] = useState([]);
    const [bikeTypeArr, setBikeTypeArr] = useState([1]);
    const [bikeTypeArrValue, setBikeTypeArrValue] = useState([]);
    const [keyWordArr, setKeyWordArr] = useState([1]);
    const [keyWordArrValue, setKeyWordArrValue] = useState([]);
    const [logoName, setLogoName] = useState('');
    const [isFinalSubmit, setIsFinalSubmit] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false)
    const dropDownRef = useRef()
    const [selectedLabel, setSelectdLabel] = useState([])
    const shopTypeMaster = useSelector(state => state.common.shopTypeMaster);

    React.useEffect(() => {
        if (pageLoadCount == 0) {
            window.scrollTo(0, 0);
            setPageLoadCount(1);
            if (queryData && queryData.content_id) {
                dispatch(callLoading(true))
                getFormEditContent('service', { content_id: queryData.content_id }).then(res => {
                    dispatch(callLoading(false))
                    if (!res.error) {
                        editFormData(res.data.data[0])
                    }
                })
            }
        }
        if (images.length == 1) {
            if (images.length && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === 'field_image')) {
                let errors = {};
                Object.entries(form_error.errors).filter(obj => obj[0] !== 'field_image').map(obj => errors[obj[0]] = obj[1]);
                setFormError({ ...form_error, errors });
            }
        }

        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        }

    }, [images]);

    const [pageLoadCount, setPageLoadCount] = useState(0)
    const [serviceForm, setServicetForm] = useState({
        title: '',
        type: 'service',
        field_brands: [],
        field_service: '',
        field_customer_review: '',
        field_why_should_customer_choose: '',
        field_keywords: [],
        field_international_product: '0',
        field_maximum_price: '',
        field_minimum_price: '',
        field_offers: '',
        field_urls: '',
        field_video_embed: '',
        field_image: [],
        field_logo: '',
        field_currency: '€',
        body: '',
        field_address: '',
        field_email: '',
        field_phone: '',
        field_shop_type: []
    });
    const callToAutoSearchRef1 = useRef()
    const callToAutoSearchRef3 = useRef()
    const callToAutoComp = useRef()
    const offerRef = useRef()
    const bodyRef = useRef()
    const usefulRef = useRef()
    const requiredFiled = ['title', 'field_maximum_price', 'field_minimum_price', 'field_address', 'field_service'];

    const clearForm = () => {
        setServicetForm({
            title: '',
            type: 'service',
            field_brands: [],
            field_service: '',
            field_customer_review: '',
            field_why_should_customer_choose: '',
            field_keywords: [],
            field_international_product: '0',
            field_maximum_price: '',
            field_minimum_price: '',
            field_offers: '',
            field_urls: '',
            field_video_embed: '',
            field_image: [],
            field_logo: '',
            field_currency: '€',
            body: '',
            field_address: '',
            field_email: '',
            field_phone: '',
            field_shop_type: []
        })
        setImages([]);
        setLogoName('');
        setBrandArr([1])
        setBrandArrValue([])
        setBikeTypeArr([1])
        setBikeTypeArrValue([])
        setKeyWordArr([1])
        setKeyWordArrValue([])
        setSelectdLabel([])
        callToAutoSearchRef1.current.clearValues()
        callToAutoSearchRef3.current.clearValues()
        callToAutoComp.current.clearValues()
    }

    const editFormData = (data) => {
        const regex = /<br\s*[\/]?>/gi;
        setServicetForm({
            title: data.title,
            type: 'service',
            field_brands: data.field_brands,
            field_service: data.field_service,
            field_customer_review: data.field_customer_review.replace(regex, ""),
            field_why_should_customer_choose: data.field_why_should_customer_choose,
            field_keywords: data.field_keywords,
            field_international_product: data.field_international_product,
            field_maximum_price: Number(data.field_maximum_price),
            field_minimum_price: Number(data.field_minimum_price),
            field_offers: data.field_offers,
            field_urls: data.field_urls,
            field_video_embed: data.field_video_embed,
            field_image: data.field_image,
            field_logo: data.field_logo,
            field_currency: data.field_currency,
            body: data.body,
            field_address: data.field_address,
            field_email: data.field_email,
            field_phone: data.field_phone,
            field_shop_type: data.field_shop_type.map(obj => Number(obj))
        })
        let arr = shopTypeMaster.map((obj, index) => ({ key: String(obj.key), label: obj.label }))
        let shopLabel = arr.filter(obj => data.field_shop_type.indexOf(obj.key) != -1).map(obj => obj.label)
        setSelectdLabel(shopLabel)

        bodyRef.current.editor.setData(data.body);
        offerRef.current.editor.setData(data.field_offers);
        usefulRef.current.editor.setData(data.field_why_should_customer_choose);
        if (data.field_brands.length) {
            data.field_brands.forEach((obj, index) => {
                if (index > 0) {
                    setBrandArr([...brandArr, index + 1])
                }
            })
            setBrandArrValue(data.field_brands)
        }

        if (data.field_keywords.length) {
            data.field_keywords.forEach((obj, index) => {
                if (index > 0) {
                    setKeyWordArr([...keyWordArr, index + 1])
                }
            })
            setKeyWordArrValue(data.field_keywords)
        }
        let formLogo = data.field_logo ? data.field_logo.split('/').pop() : ''
        setLogoName(formLogo.length > 20 ? formLogo.slice(0, 20) + '...' : formLogo)

        if (data.field_image && data.field_image.length > 0) {
            let _photo = [];
            for (const photo in data.field_image) {
                _photo.push({
                    fromFile: true,
                    src: data.field_image[photo]
                });
            }
            setImages([..._photo]);
        }


    }

    const handleClickOutside = event => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setShowDropDown(false)
        }
    };

    const handleInput = (e) => {
        setServicetForm({ ...serviceForm, [e.target.name]: e.target.value })
        if (e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === e.target.name)) {
            let errors = {};
            Object.entries(form_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
            setFormError({ ...form_error, errors });
        }
        if (e.target.value === '' && requiredFiled.indexOf(e.target.name) !== -1) {
            console.log(form_error)
            let errors = {};
            errors[e.target.name] = `This is required field`;
            if (form_error.errors && Object.entries(form_error.errors).length) {
                Object.entries(form_error.errors).map(obj => errors[obj[0]] = obj[1]);
            }
            setFormError({ ...form_error, errors });
        }
    }

    const handlecheckBox = (e) => {
        if (e.target.checked) {
            setServicetForm({ ...serviceForm, [e.target.name]: '1' })
        } else {
            setServicetForm({ ...serviceForm, [e.target.name]: '0' })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        setFormError({ ...form_error, errors });
        let isValid = true;
        let formArr = Object.keys(serviceForm).map(obj => ({ key: String(obj), value: serviceForm[obj] }));
        if (formArr && formArr.length) {
            formArr.forEach(obj => {
                if (Array.isArray(obj.value)) {
                    if (obj.value.length === 0 && requiredFiled.indexOf(obj.key) !== -1) {
                        errors[obj.key] = `This is required field`;
                        setFormError({ ...form_error, errors });
                        isValid = false;
                    }
                } else {
                    if (obj.value === '' && requiredFiled.indexOf(obj.key) !== -1) {
                        errors[obj.key] = `This is required field`;
                        setFormError({ ...form_error, errors });
                        isValid = false;
                    }
                }
                if (images.length === 0) {
                    errors['field_image'] = `Upload atleast one image`;
                    isValid = false;
                }
            });

        }

        if (isValid) {
            let formdata = {};
            formArr.forEach(field => {
                if (isFinalSubmit) {
                    formdata['moderation_state'] = 'in_review'
                }
                if (field.key === 'field_image') {
                    let fileImages = [];
                    if (images.length > 0) {
                        images.forEach((img) => {
                            if (img.id) {
                                fileImages.push(img.id)
                            } else {
                                fileImages.push(img.src)
                            }
                        })

                    }
                    formdata[field.key] = fileImages;
                } else if (field.key === 'field_brands') {
                    formdata[field.key] = brandArrValue.filter(function (e) { return e });
                } else if (field.key === 'field_keywords') {
                    formdata[field.key] = keyWordArrValue.filter(function (e) { return e });
                }
                else {
                    formdata[field.key] = field.value
                }

            });

            dispatch(callLoading(true))

            const finalUrl = queryData && queryData.content_id ? `content/${queryData.content_id}` : 'content';
            const _method = queryData && queryData.content_id ? 'PATCH' : 'POST';

            await submitForm(finalUrl, formdata, _method).then(resp => {
                if (!resp.error) {
                    if (isFinalSubmit) {
                        dispatch(callLoading(false))
                        clearForm()
                        props.history.push(RouteNames.services)
                    } else {
                        dispatch(callLoading(false))
                        const contId = resp.content_id;
                        const detailRoute = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + RouteNames.serviceFromPreview + '/' + contId + '?preview=1'
                        props.history.push(RouteNames.serviceForm + '?content_id=' + contId)
                        openInNewTab(detailRoute)

                    }

                    toast.success(`Successfully ${isFinalSubmit ? 'submitted' : 'saved'}`,
                        {
                            position: toast.POSITION.TOP_RIGHT
                        });
                } else {
                    toast.error(resp.msg,
                        {
                            position: toast.POSITION.TOP_RIGHT
                        });
                }
            });
        }
    }

    const openInNewTab = (href) => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: href,
        }).click();
    }

    const onDrop = useCallback((acceptedFiles, files, savedImages) => {
        // Loop through accepted files
        const selectedImageLength = files.length;
        const totalImageLength = Number(acceptedFiles.length) + Number(savedImages.length)
        if (acceptedFiles.length > 5 || (acceptedFiles.length + selectedImageLength) > 5) {
            toast.error('Maximum 5 image can be upload...');
            return;
        }

        if (totalImageLength > 5) {
            toast.error('Maximum 5 image can be upload...');
            return;
        }
        handleSubmitImage(acceptedFiles, savedImages)
    }, []);

    const handleSubmitImage = (imageFile, savedImages) => {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        let formdata = new FormData();
        imageFile.forEach((file, index) => {
            formdata.append('file[]', file);
        })
        formdata.append('data', timestamp);
        dispatch(callLoading(true))
        uploadedImages('service/field_image', formdata).then(res => {
            if (!res.error) {
                dispatch(callLoading(false))
                let _photo = savedImages.length ? savedImages : [];
                res.data.data.forEach((image, indx) => {
                    _photo.push({
                        id: image.id,
                        fromFile: true,
                        src: image.url
                    });
                })
                setImages([..._photo]);

            }
        })
    }


    const handleLogo = (e) => {
        const file = e.target.files[0];
        setLogoName(file.name)
        if (!file.type.includes('image')) {
            toast.error('Please upload image file',
                {
                    position: toast.POSITION.TOP_RIGHT
                });
            return
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            console.log(event.target.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""))
            setServicetForm({ ...serviceForm, ['field_logo']: event.target.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, "") });
        }
        reader.readAsDataURL(file);
        if (file && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === 'field_logo')) {
            let errors = {};
            Object.entries(form_error.errors).filter(obj => obj[0] !== 'field_logo').map(obj => errors[obj[0]] = obj[1]);
            setFormError({ ...form_error, errors });
        }
    }

    const mapDataHandler = (data) => {
        setServicetForm({ ...serviceForm, ['field_address']: data.query })
        if (data.query && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === 'field_address')) {
            let errors = {};
            Object.entries(form_error.errors).filter(obj => obj[0] !== 'field_address').map(obj => errors[obj[0]] = obj[1]);
            setFormError({ ...form_error, errors });
        }
    }

    const setAutoSearchBlur = (value) => {
        if (!value) {
            setServicetForm({ ...serviceForm, ['field_address']: '' })
            if (value === '' && requiredFiled.indexOf('field_address') !== -1) {
                console.log(form_error)
                let errors = {};
                errors['field_address'] = `This is required field`;
                if (form_error.errors && Object.entries(form_error.errors).length) {
                    Object.entries(form_error.errors).map(obj => errors[obj[0]] = obj[1]);
                }
                setFormError({ ...form_error, errors });
            }
        }
    }

    const _removeFileFromImageList = (file) => {
        const { field_image } = serviceForm;
        const updatedPhotoField = field_image.filter(p => p === file);
        setServicetForm({
            ...serviceForm,
            field_image: updatedPhotoField
        });
    }

    const imageRemoveHandler = (index, image) => {
        images.splice(index, 1);
        if (image.fromFile) {
            _removeFileFromImageList(image.src);
        }
        setImages([...images]);
        imagesFile.splice(index, 1);
        setImagesFile([...imagesFile]);
    }

    const setAutoSearchValue = (value, type) => {

        if (type === 'brands') {
            setServicetForm({ ...serviceForm, ['field_brands']: [...serviceForm.field_brands, value] });
        } else if (type === 'tags') {
            setServicetForm({ ...serviceForm, ['field_keywords']: [...serviceForm.field_keywords, value] });
        }
    }

    const handleCheckDropDown = (e) => {
        const labelValue = shopTypeMaster.find(obj => obj.key == e.target.value)
        if (e.target.checked) {
            if (serviceForm.field_shop_type && serviceForm.field_shop_type.length) {
                setServicetForm({ ...serviceForm, ['field_shop_type']: serviceForm.field_shop_type.concat(Number(e.target.value)) });
                setSelectdLabel(selectedLabel.concat(labelValue.label))

            } else {
                setServicetForm({ ...serviceForm, ['field_shop_type']: [Number(e.target.value)] });
                setSelectdLabel([labelValue.label])
            }

        } else {
            setServicetForm({ ...serviceForm, ['field_shop_type']: serviceForm.field_shop_type.filter(obj => obj != Number(e.target.value)) });
            setSelectdLabel(selectedLabel.filter(obj => obj != labelValue.label))
        }
        if (e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === 'field_shop_type')) {
            let errors = {};
            Object.entries(form_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
            setFormError({ ...form_error, errors });
        }

    }


    const handleAutoKeyword = (value, index) => {
        let keyValues = [...keyWordArrValue];
        keyValues[index] = value
        setKeyWordArrValue(keyValues)
    }


    const handleAutoBrand = (value, index) => {
        let keyValues = [...brandArrValue];
        keyValues[index] = value
        setBrandArrValue(keyValues)
    }

    const handleAutoBikeType = (value, index) => {
        let keyValues = [...bikeTypeArrValue];
        keyValues[index] = value
        setBikeTypeArrValue(keyValues)
    }

    const addMultiple = (e, type) => {
        e.preventDefault();
        if (type === 'field_brands') {
            if (brandArr.length < 10) {
                setBrandArr([...brandArr, brandArr.length + 1])
            }
        } else if (type === 'field_keywords') {
            if (keyWordArr.length < 10) {
                setKeyWordArr([...keyWordArr, keyWordArr.length + 1])
            }
        }
    }

    const searchSubmitHandler = ( data ) =>{
		const { keywords, locationData } = data;
        let paramData = {};
        let queryData ={};

		if( keywords ){
			paramData['body'] = keywords;
			queryData['body'] = keywords;
		}
		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}

		var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
		})
		props.history.push(RouteNames.services + queryString)
    }

    return (
        <>
            <Header />
            <Banner bannerImage='buybanner.jpg' pageName='Services Form'>

                <SearchBar is_expert_ask={true} onSearchSubmit={searchSubmitHandler}/>
            </Banner>
            <main className="site-main inner-main">
                <section className="revwadd-form-sec">
                    <div className="container">
                        <div className="tophead tophead1">
                            <Goto to='services' />
                            <p>
                                <b>We are excited to have you onboard. Thank you for becoming a part of the carryon.bike community!</b></p>
                            <p>Please fill the form highlighting how you are unique, how you want your brand/ shop/ service/ product/ profile to be discovered by others. Thank you for your time filling this. Please drop any queries to <a href="mailto:carryon.enquiry@gmail.com"> carryon.enquiry@gmail.com</a></p>
                        </div>
                        <div className="revwadd-frm-holder">
                            <div className="formleft">
                                <img width="419" height="807" src={require('../../assets/images/login.jpg')} alt="" />
                            </div>
                            <div className="formright">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-inline">
                                        <div className="form-group">
                                            <input className="mandatory" type="text" name="title" placeholder="Shop/Store Name" value={serviceForm.title} onChange={handleInput} />
                                            {
                                                form_error['errors'] && form_error['errors']['title'] && (
                                                    <span className='require_error'>{form_error['errors']['title']}</span>
                                                )

                                            }
                                        </div>
                                        <div className="form-group">
                                            <GoogleSearchAutocomplete
                                                ref={callToAutoComp}
                                                onInput={mapDataHandler}
                                                onblurEvent={setAutoSearchBlur}
                                                autoLoad={true}
                                                isrequired={true}
                                                value={serviceForm.field_address}
                                            />

                                            {
                                                form_error['errors'] && form_error['errors']['field_address'] && (
                                                    <span className='require_error'>{form_error['errors']['field_address']}</span>
                                                )

                                            }

                                        </div>
                                    </div>

                                    <div className="form-inline">


                                        <div className="form-group">
                                            <input className="mandatory" type="number" value={serviceForm.field_minimum_price} placeholder="Min price" name="field_minimum_price" onChange={handleInput} />
                                            {
                                                form_error['errors'] && form_error['errors']['field_minimum_price'] && (
                                                    <span className='require_error'>{form_error['errors']['field_minimum_price']}</span>
                                                )

                                            }
                                        </div>

                                        <div className="form-group">
                                            <input className="mandatory" type="number" value={serviceForm.field_maximum_price} placeholder="Max price" name="field_maximum_price" onChange={handleInput} />
                                            {
                                                form_error['errors'] && form_error['errors']['field_maximum_price'] && (
                                                    <span className='require_error'>{form_error['errors']['field_maximum_price']}</span>
                                                )

                                            }
                                        </div>
                                    </div>

                                    <div className="form-inline">
                                        <div className="form-group">
                                            <select className="mandatory" value={serviceForm.field_currency} name='field_currency' onChange={handleInput}>
                                                <option value='€'>Euro</option>
                                                <option value='kr'>NOK</option>
                                                <option value='$'>USD</option>
                                                <option value='£'>Pound</option>
                                            </select>
                                            {
                                                form_error['errors'] && form_error['errors']['field_currency'] && (
                                                    <span className='require_error'>{form_error['errors']['field_currency']}</span>
                                                )

                                            }

                                            <input className="" type="text" placeholder="URLs" value={serviceForm.field_urls} name="field_urls" onChange={handleInput} />
                                            {
                                                form_error['errors'] && form_error['errors']['field_urls'] && (
                                                    <span className='require_error'>{form_error['errors']['field_urls']}</span>
                                                )

                                            }
                                        </div>
                                        <div className="form-group">
                                            <textarea placeholder="Customer Review/ Testimonial" value={serviceForm.field_customer_review} name='field_customer_review' onChange={handleInput}></textarea>
                                            {
                                                form_error['errors'] && form_error['errors']['field_customer_review'] && (
                                                    <span className='require_error'>{form_error['errors']['field_customer_review']}</span>
                                                )

                                            }
                                        </div>
                                    </div>
                                    {
                                        brandArr.map((brand, index) => (
                                            <div className="form-inline" key={index}>
                                                <div className="form-group">
                                                    <AutoSearchInput ref={callToAutoSearchRef1} indexVal={index} setAutoSearchValue={setAutoSearchValue} autoSearchBlur={handleAutoBrand} value={brandArrValue[index]} type='brands' placeholder='Brand names' />
                                                    {
                                                        form_error['errors'] && form_error['errors']['field_brands'] && (
                                                            <span className='require_error'>{form_error['errors']['field_brands']}</span>
                                                        )

                                                    }
                                                </div>
                                                {
                                                    index === 0 ? <Link to='#' onClick={(e) => addMultiple(e, 'field_brands')}><i className="fa fa-plus" style={{ fontSize: 24 }} ></i></Link> :
                                                        <Link to='#' onClick={(e) => { e.preventDefault(); setBrandArr(brandArr.filter(obj => obj !== index + 1)); setBrandArrValue(brandArrValue.filter(obj => obj !== brandArrValue[index])) }}><i className="fa fa-minus" style={{ fontSize: 24 }} ></i></Link>
                                                }
                                            </div>
                                        ))
                                    }


                                    <div className="form-inline">
                                        <CKEditor
                                            ref={bodyRef}
                                            editor={ClassicEditor}
                                            data={''}
                                            id='body1'
                                            config={{ placeholder: 'Why should a customer choose you' }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setServicetForm({ ...serviceForm, ['body']: data })
                                            }}
                                        />

                                    </div><br />
                                    <div className="form-inline">
                                        <CKEditor
                                            ref={offerRef}
                                            editor={ClassicEditor}
                                            data={''}
                                            id='body3'
                                            config={{ placeholder: 'Offers' }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setServicetForm({ ...serviceForm, ['field_offers']: data })
                                            }}
                                        />

                                    </div><br />
                                    <div className="form-inline">
                                        <CKEditor
                                            ref={usefulRef}
                                            editor={ClassicEditor}
                                            data={''}
                                            id='field_why_should_customer_choose'
                                            config={{ placeholder: 'Useful Info' }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setServicetForm({ ...serviceForm, ['field_why_should_customer_choose']: data })
                                            }}
                                        />

                                    </div><br />
                                    <div className="form-inline">
                                        <div className="form-group">
                                            <input placeholder="Email" type="text" name="field_email" value={serviceForm.field_email} onChange={handleInput} />
                                        </div>
                                        <div className="form-group">
                                            <input placeholder="Phone no" type="number" name="field_phone" value={serviceForm.field_phone} onChange={handleInput} />
                                        </div>
                                    </div>

                                    <div className="form-inline">

                                        <div className="form-group">
                                            <input placeholder="Video Link" type="text" name="field_video_embed" value={serviceForm.field_video_embed} onChange={handleInput} />
                                            {
                                                form_error['errors'] && form_error['errors']['field_video_embed'] && (
                                                    <span className='require_error'>{form_error['errors']['field_video_embed']}</span>
                                                )

                                            }
                                        </div>
                                        <div className="form-group">
                                            <input id="field_logo1" className="field_logo"
                                                type="file"
                                                name="field_logo"
                                                onChange={handleLogo}
                                            />
                                            <label htmlFor="field_logo1">{logoName ? logoName : 'Upload Logo'}</label>
                                            {
                                                form_error['errors'] && form_error['errors']['field_logo'] && (
                                                    <span className='require_error'>{form_error['errors']['field_logo']}</span>
                                                )

                                            }

                                        </div>
                                    </div>
                                    <div className="form-inline">
                                        <div className="form-group multiselect" ref={dropDownRef}>
                                            <input type="text" placeholder="Select Characteristics" onClick={() => setShowDropDown(prevState => !prevState)} id="msinp" value={selectedLabel && selectedLabel.length ? (selectedLabel.length > 3 ? selectedLabel.slice(0, 3).toString() : selectedLabel.toString()) : ''} autoComplete='off' style={{ color: 'black' }} />
                                            <ul className="dropdown" style={{ display: showDropDown ? 'block' : 'none' }}>
                                                {
                                                    shopTypeMaster.length && shopTypeMaster.map((data, index) => (
                                                        <li key={index}>
                                                            <input type="checkbox" name={data.label} checked={serviceForm.field_shop_type && serviceForm.field_shop_type.indexOf(data.key) != -1 ? true : false} value={data.key} onChange={handleCheckDropDown} />
                                                            <label>{data.label}</label>
                                                        </li>
                                                    ))
                                                }


                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <select className="mandatory" value={serviceForm.field_service} name='field_service' onChange={handleInput}>
                                                <option value=''>Select service type</option>
                                                {
                                                    service_type_master && service_type_master.length > 0 && service_type_master.map((type, index) => (
                                                        <option value={type.tid}>{type.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                form_error['errors'] && form_error['errors']['field_service'] && (
                                                    <span className='require_error'>{form_error['errors']['field_service']}</span>
                                                )

                                            }
                                        </div>
                                    </div>
                                    {
                                        keyWordArr.map((key, index) => (
                                            <div className="form-inline" key={index}>
                                                <div className="form-group">
                                                    <AutoSearchInput ref={callToAutoSearchRef3} indexVal={index} setAutoSearchValue={setAutoSearchValue} autoSearchBlur={handleAutoKeyword} value={keyWordArrValue[index]} type='tags' placeholder='Keywords' />
                                                    {
                                                        form_error['errors'] && form_error['errors']['field_keywords'] && (
                                                            <span className='require_error'>{form_error['errors']['field_keywords']}</span>
                                                        )

                                                    }
                                                </div>
                                                {
                                                    index === 0 ? <Link to='#' onClick={(e) => addMultiple(e, 'field_keywords')}><i className="fa fa-plus" style={{ fontSize: 24 }} ></i></Link> :
                                                        <Link to='#' onClick={(e) => { e.preventDefault(); setKeyWordArr(keyWordArr.filter(obj => obj !== index + 1)); setKeyWordArrValue(keyWordArrValue.filter(obj => obj !== keyWordArrValue[index])) }}><i className="fa fa-minus" style={{ fontSize: 24 }} ></i></Link>
                                                }
                                            </div>
                                        ))
                                    }

                                    <div className="form-inline">

                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" checked={serviceForm.field_international_product === '1' ? true : false} name="field_international_product" onChange={handlecheckBox} />&nbsp; Is worldwide shipping
                                            </label>
                                        </div>
                                        <br /><br />
                                    </div>

                                    <div className="form-group">
                                        <label>Upload Images upto 5 images</label>
                                        <DragDropzone onDrop={(event) => onDrop(event, imagesFile, images)} accept={"image/*"} files={imagesFile} />
                                        <PreviewImageList images={images} imageRemoveAction={imageRemoveHandler} />
                                        {
                                            form_error['errors'] && form_error['errors']['field_image'] && (
                                                <span className='require_error' style={{ top: -35 }}>{form_error['errors']['field_image']}</span>
                                            )

                                        }
                                    </div>

                                    <div className="form-group submit-form-inline">
                                    <div className="form-group text-center submit-frm-grp">
                                        <input type="submit" className="big-btn" value="Preview" onClick={() => setIsFinalSubmit(false)} />
                                    </div>
                                    
                                    <div className="form-group text-center submit-frm-grp">
                                        <input type="submit" value="Submit" onClick={() => { setIsFinalSubmit(true) }} />
                                    </div>
                                    </div>

                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default ServicesForm
