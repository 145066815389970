import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import {login, forgotPassword, closeAuthModal, openAuthModal } from '../../stores/actions/auth-action'
import { useSelector, useDispatch } from 'react-redux';
import Login from './Login';
import { toast } from 'react-toastify';

const ForgotPassword = (props) => {
    const [show, setShow] = useState(false);
	const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	const [form_error, setFormError] = useState({});
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch();
	const [forgotForm, setForgotForm] = useState({
		mail: '',
    })
	const forgotOpen = useSelector( state => state.auth.forgotOpen );

    
	const clearForm = () => {
		setForgotForm({
			mail: ''
		})
	}
	const handleClose = () => {
		dispatch(closeAuthModal())
	}

	const handleInput = (e) => {
		setForgotForm({ ...forgotForm, [e.target.name]: e.target.value })
	}

    const handleSubmit = async (e) => {
		e.preventDefault();
		let errors = {};
		setFormError({ ...form_error, errors });
		let isValid = true;
		let formArr = Object.keys(forgotForm).map(obj => ({ key: String(obj), value: forgotForm[obj] }));
		if (formArr && formArr.length) {
			formArr.forEach(obj => {
				if (obj.value === '') {
					errors[obj.key] = `${obj.key.split('_')[1] ? obj.key.split('_')[1] : obj.key.split('_')[0]} field is required`;
					setFormError({ ...form_error, errors });
					isValid = false;
				}
			});

		}

		if (isValid) {
			setFormError({})
			setIsLoading(true)
			await forgotPassword(forgotForm).then(resp => {
				console.log(resp)
				setIsLoading(false)
				if (!resp.error) {
					toast.success('Reset password link has been sent successfully.',
					{
						position: toast.POSITION.TOP_RIGHT,
						autoClose:3000
					});
					clearForm()
					handleClose();
				} else {
					toast.error(resp.msg,
						{
							position: toast.POSITION.TOP_RIGHT,
							autoClose:3000
						});
				}
			});
		}
	}
    
    return (
		<>
		<Modal className="cmnmodal logmodal" size="lg" centered show={forgotOpen} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="logholder">

				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="img">
					<img src={require('../../assets/images/login.jpg')} alt="" />
				</div>
				<div className="logholder">
					<div>
						<Link className="logo" to="#">
							<img src={require('../../assets/images/logo.png')} alt="" />
						</Link>
						<h3>Forgot Password</h3>
						<h4>
							<span>or log in using your carry on bike account</span>
						</h4>
						<div className="formdiv">
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<input type="mail" name="mail" placeholder="Email" onChange={handleInput}/>
									{
										form_error['errors'] && form_error['errors']['mail'] && (
											<span className='form_error'>{form_error['errors']['mail']}</span>
										)

									}
								</div>
								<div className="form-group">
									<input type="submit" name="" value="Send" />
								</div>
							</form>
						</div>
						<Link className="forgot" to="#" onClick={()=>dispatch(openAuthModal('login'))}>Go to login?</Link>

					</div>
				</div>
			</Modal.Body>
			<div className="loaddiv loaddiv1" style={{ display: isLoading ? 'block' : 'none' }}>
				<span className="loader"></span>
			</div>
		</Modal>
		</>
    )
}

export default ForgotPassword
