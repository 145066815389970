import React from 'react';
import { Route, Redirect, Switch, Router} from 'react-router-dom';
import Login from '../pages/auth/Login';
import Registration from '../pages/auth/Registration';
import Home from '../pages/home/Home';
import Repair from '../pages/repair/repair';
import { RouteNames } from '../includes/route-names';
import BuyList from '../pages/buy/BuyList';
import ServicesList from '../pages/services/ServicesList';
import OfferList from '../pages/offers/OfferList';
import MemberShip from '../pages/member-ship/MemberShip';
import SmartSolution from '../pages/solutions/SmartSolution';
import UsedBike from '../pages/used-bike/UsedBike';
import Compare from '../pages/compare/compare';
import BuyDetail from '../pages/buy/BuyDetail';
import ServicesDetail from '../pages/services/ServicesDetail';
import RepairDetails from '../pages/repair/repair-details';
import AboutUs from '../pages/about/about-us';
import ContactUs from '../pages/contact-us/contact-us';
import BikeDestination from '../pages/bike-destination/BikeDestination';
//import Roldal from '../pages/bike-destination/roldal/roldal';
//import Romsdal from '../pages/bike-destination/romsdal/romsdal';
//import Nordfjord from '../pages/bike-destination/nordfjord/nordfjord';
import EventList from '../pages/events/EventList';
//import LilleHamer from '../pages/bike-destination/lillehamer/LilleHamer';
//import GeiRangerfjord from '../pages/bike-destination/gei-rangerfjord/GeiRangerfjord';
//import DestinationCountry from '../pages/bike-destination/destination-country/DestinationCountry';
import Test from '../pages/test';
import ResetPassword from '../pages/auth/ResetPassword';
import BuyForm from '../pages/buy/BuyForm';
import ServicesForm from '../pages/services/ServicesForm';
import RepairForm from '../pages/repair/repair-form';
import SmartSolutionForm from '../pages/solutions/SmartSolutionForm';
import DestinationForm from '../pages/bike-destination/destination-form';
import SmartSolutionDetail from '../pages/solutions/SmartSolutionDetail';
import MyProfile from '../pages/user/my-profile';
import {AuthenticateRoute} from './authenticate-route'
import Statistic from '../pages/user/my-statistic/statistic';
import City from '../pages/bike-destination/details/city';
import BikeEnthisticList from '../pages/bike-enthusiasts/BikeEnthisticList';
import BikeEnthisticForm from '../pages/bike-enthusiasts/BikeEnthisticForm';
import EventForm from '../pages/events/EventForm';
import EventDetail from '../pages/events/EventDetail';
import BikeEnthisticDetail from '../pages/bike-enthusiasts/BikeEnthisticDetail';
import List from '../pages/user/my-list/list';
import TermCondition from '../pages/static-pages/TermCondition';
import PrivacyPolicy from '../pages/static-pages/PrivacyPolicy';
import Partners from '../pages/home/components/partners';
import Partner from '../pages/static-pages/Partner';

const MainRoutes =(props)=>{
	return (
		<Switch>
			<Route path='/' exact component={Home}/>
			<Route path={RouteNames.reset_password} exact component={ResetPassword}/>
			<Route path={RouteNames.about} exact component={AboutUs}/>
			<Route path={RouteNames.contactUs} exact component={ContactUs}/>

			<Route path={RouteNames.buy} exact component={BuyList} />
			<Route path={`${RouteNames.buy_detail}/:alias?`} exact component={BuyDetail}/>
			<AuthenticateRoute path={RouteNames.buy_form} exact component={BuyForm}/>
			<AuthenticateRoute path={ `${RouteNames.buy_form_preview}/:alias?`} exact component={BuyDetail}/>

			<Route path={RouteNames.services} exact component={ServicesList}/>
			<Route path={`${RouteNames.servicesDetail}/:alias?`} exact component={ServicesDetail}/>
			<AuthenticateRoute path={RouteNames.serviceForm} exact component={ServicesForm} />
			<AuthenticateRoute path={ `${RouteNames.serviceFromPreview}/:alias?`} exact component={ServicesDetail}/>

			{/* <AuthenticateRoute path={RouteNames.repair} exact component={Repair}/>
			<AuthenticateRoute path={RouteNames.repairDetails} exact component={RepairDetails}/>
			<AuthenticateRoute path={RouteNames.repair_form} exact component={RepairForm} /> */}

			<Route path={RouteNames.event} exact component={EventList}/>
			<Route path={`${RouteNames.eventDetails}/:alias?`} exact component={EventDetail} />
			<AuthenticateRoute path={`${RouteNames.eventForm_preview}/:alias?`} exact component={EventDetail} />
			<AuthenticateRoute path={RouteNames.eventForm} exact component={EventForm}/>

			<Route path={RouteNames.smart_solution} exact component={SmartSolution}/>
			<Route path={`${RouteNames.smart_solution_detail}/:alias?`} exact component={SmartSolutionDetail}/>
			<AuthenticateRoute path={RouteNames.smart_solution_form} exact component={SmartSolutionForm} />
			<AuthenticateRoute path={`${RouteNames.smart_solution_form_preview}/:alias?`} exact component={SmartSolutionDetail}/>
			
			<Route path={`${RouteNames.biking}/:country?`} exact component={BikeDestination}/>
			<Route path={`${RouteNames.bikingDestinationDetails}/:city`} exact component={City}/>
			<AuthenticateRoute path={`${RouteNames.destinationFormPreview}/:city`} exact component={City}/>
			<AuthenticateRoute path={RouteNames.destinationForm} exact component={DestinationForm}/>			

			<Route path={RouteNames.bikeEnthusticList} exact component={BikeEnthisticList}/>
			<Route path={`${RouteNames.bikeEnthusticDetails}/:alias?`} exact component={BikeEnthisticDetail} />
			<AuthenticateRoute path={`${RouteNames.bikeEnthusticFormPreview}/:alias?`} exact component={BikeEnthisticDetail} />
			<AuthenticateRoute path={RouteNames.bikeEnthusticForm} exact component={BikeEnthisticForm}/>

			{/* <Route path={RouteNames.offers} exact component={OfferList}/> */}
			<Route path={RouteNames.member_ship} exact component={MemberShip}/>

			{/* <Route path={RouteNames.usedBike} exact component={UsedBike}/> */}
			<Route path={RouteNames.compare} exact component={Compare}/>		
			
			{/* <Route path={RouteNames.roldal} exact component={Roldal}/>
			<Route path={RouteNames.romsdal} exact component={Romsdal}/>
			<Route path={RouteNames.lille_harmer} exact component={LilleHamer}/>
			<Route path={RouteNames.gei_rangerfjord} exact component={GeiRangerfjord}/>
			<Route path={RouteNames.destination_country} exact component={DestinationCountry}/>
			<Route path={RouteNames.nordfjord} exact component={Nordfjord}/> */}

			<Route path={RouteNames.test} exact component={Test} />

			<AuthenticateRoute path={RouteNames.myprofile} exact component={MyProfile}/>
			<AuthenticateRoute path={RouteNames.myStatistic} exact component={Statistic}/>
			<AuthenticateRoute path={`${RouteNames.userList}/:listType`} exact component={List}/>
			<Route path={RouteNames.termsAndCondition} exact component={TermCondition}/>
			<Route path={RouteNames.privacyPolicy} exact component={PrivacyPolicy}/>
			<Route path={RouteNames.partners} exact component={Partner}/>
			<Redirect to='/' />
		</Switch>
	)
}

export default MainRoutes;