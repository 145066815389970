import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserFormDataList, callLoading } from '../../../../stores/actions/common-action';
import { RouteNames } from '../../../../includes/route-names';
import Banner from '../../../../includes/Banner'
import Pagination from '../../../../reuseables/Pagination';
import NoData from '../../../../reuseables/no-data';
import Favourite from '../../../../reuseables/Favourite';

const BikeDestination = (props) => {
    
    const { country, url  } = props;
    const [destinationList, setDestinationList ] = useState( {} );
	const [ activePage, setActivePage ] = useState( 1 );
	const dispatch = useDispatch();

	const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		getUserFormDataList( url, page, filterData ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				setDestinationList( resp.data );
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}

	//field_city_value
	useEffect(()=>{
		
		_getDestinationData( 0 );
		
	},[]);

	const pageChangeHandler = ( page )=>{
		_getDestinationData( page - 1 );
	}

	//console.log("DES", country);


    return (
        <>
			<Banner extra_class bannerImage='destinationbnr.jpg' pageName='Biking Destination'>
				<div className="bannercontent">
					<div>
					<h3><span>Destinations To Explore On Bike </span></h3> 
					</div>
				</div>
            </Banner>
             
			<main className="site-main inner-main"> 	
				<section className="travelSection">
					<div className="container">
						{/*<TopTitleLink 
							activeCountry={selectedCountryValue} 
							activeCity={selectedCityValue} 
							cityList={ city }
							onChangeCountry={changeCountryHandler}
							onChangeCity = {ChangeCityHandler}
						/>*/}
						<div className="listing_panel listing_panelnor" id="nor">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-12"> 
									<div className="destholder">
										<div className="row">
											{ 
												destinationList && destinationList.data && destinationList.data.length > 0 ? destinationList.data.map((item, key ) =>  
													<Item key={key} item={item}/> 
												) : <NoData/>
											}
											
										</div>  
									</div>
								</div>
							</div>
							<Pagination 
								activePage={activePage} 
								pageData={destinationList && destinationList.hasOwnProperty('pageData') ? destinationList.pageData : null }
								onPageChange={pageChangeHandler}
							/>
						</div> 
					</div>
				</section> 
			</main>
        </>
    )
}

const Item = (props) => {
    const { title, body, field_photo, field_video_embed, field_currency_type, field_note, alias, editable, content_id } = props.item;
    
    //console.log( alias );
    
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="travelOuterSec travelOuterSec3">
                <div className="travelImg">
                    { field_photo && field_photo.length > 0 && 
                        <>
                            <>
                            <img src={ field_photo[0] } alt=""/>

                                {
                                    editable && parseInt( editable ) === 1 &&
                                    <Link 
                                        className="edit" 
                                        title="Edit" 
                                        to={`${RouteNames.destinationForm}?content_id=${content_id}`}>
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Link> 
                                }
                                
                            </>
                        </>
                        
                    }
                    
                    { !field_photo && field_video_embed && 
                        <>
                        <iframe 
                            width={360} 
                            height={230} 
                            src={ field_video_embed }
                            frameBorder={0}  
                            allowFullScreen>
                        </iframe>
                        {
                            editable && parseInt( editable ) === 1 &&
                            <Link 
                                className="edit" 
                                title="View" 
                                to={`${RouteNames.destinationForm}?content_id=${content_id}`}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link> 
                        }
                        
                        </>
                    }
                    {
                        field_note && 
                        <span className="note">
                            <strong>Note:</strong>
                            { field_note }
                        </span>
                    }
                    
                    {
                        field_currency_type && <span className="credit">{ field_currency_type }</span>
                    }
                      
                </div>
                <div className="travelContent">
                    <h4 className="notranslate" ><Link to='#'>{ title }</Link></h4>
                    
                    <p dangerouslySetInnerHTML={{ __html: body.length > 100 ? body.substring(0, 100).concat('...') : body }}></p>
                </div>
            </div>
        </div>
    )
}

export default BikeDestination;
