import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import MainStructure from '../MainStructure';
import SearchBar from '../../reuseables/SearchBar';
import { RouteNames } from '../../includes/route-names';
import Pagination from '../../reuseables/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { getBuyList, callLoading, getBuySubList, addingFavourite, clearSearch } from '../../stores/actions/common-action';
import { Button } from 'react-bootstrap';
import GetInTouch from '../../reuseables/get-in-touch';
import NoData from '../../reuseables/no-data';
import Favourite from '../../reuseables/Favourite';
import SortDropDown from '../../reuseables/SortDropDown';
import Characterstic from '../../reuseables/Characterstic';

const BuyList =(props)=>{
    const { country } = useParams();
    //const paramData = queryString.parse( location.search );
    const buyListData = useSelector(state => state.common.buyData);
    const myCountry = useSelector( state => state.common.myCountry );
    const resetSearch = useSelector( state => state.common.resetSearch );
	const [activePage, setActivePage] = useState(1);
	const [ searchCountry, setSearchCountry ] = useState( country );
	const [show, setShow] = useState(false);
    const _openModalHandler = () => setShow(true);
    const dispatch = useDispatch();
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const queryData = queryString.parse( window.location.search );
    const [sortValue, setSortValue] = useState('')
    const [searchKeyData,setSearchKeyData] = useState({})
    const [queryDataValue,setQueryDataValue] = useState({})
    
    const _getButData = (page, filterData = {}) => {
        //console.log( filterData );
		dispatch(callLoading(true))
		dispatch(getBuyList(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;

				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

    }
    
    //console.log( myCountry );

    React.useEffect(()=>{
        window.scrollTo(0,0);
        if(Object.keys(queryData).length > 0 && !resetSearch ){
            if(Object.keys(queryData).indexOf('field_country_value') === -1){
                let queryValue = queryData;
                queryValue['sort_by'] = 'search_api_relevance'
                setSortValue('search_api_relevance')
            }          
            setQueryDataValue(queryData);
            _getButData(0, { ...queryData });
        }else{
            if( myCountry )
                _getButData(0, { field_country_value: myCountry } );
        }

        if( resetSearch){
            clearData();
            dispatch( clearSearch( false ) );
        }
        
    },[myCountry, resetSearch]);

    const searchSubmitHandler = (data) => {
        setQueryDataValue({})
        setSearchKeyData(data)
        const { keywords, locationData } = data;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
            if(sortValue =='search_api_relevance'){
                setSortValue('');
            }
        }
        if(sortValue){
            if(Object.entries(locationData).length > 0 && keywords && sortValue !='search_api_relevance'){
            paramData['sort_by']= sortValue;
            }
        }

       if(Object.entries(locationData).length == 0 && keywords){
        paramData['sort_by']= 'search_api_relevance';
        setSortValue('search_api_relevance')
        _getButData(0, { ...paramData });
       }else{
        _getButData(0, { ...paramData });
       }
        
		
    }

	const pageChangeHandler = (page) => {
        const { keywords, locationData } = searchKeyData;
        let paramData = {};
        
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
        }
        else if( myCountry ){
            paramData['field_country_value']= myCountry;
        }

        if(sortValue){
            paramData['sort_by']= sortValue;
        }
		_getButData(page - 1, { ...paramData });
    }

   const clearData =()=>{
        _getButData( 0 );
        setSortValue()
        setSearchKeyData({})
        setQueryDataValue({})
    }

    const callSort =(value)=>{
        setSortValue(value)
        let paramData = {};
        if(Object.keys(queryDataValue).length > 0){
            const { body, field_country_value } = queryDataValue;
            if( body )
                paramData['body'] = body;
            if( field_country_value ){
                paramData['field_country_value']= field_country_value;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getButData(0, { ...paramData });

        }else if(Object.keys(searchKeyData).length > 0){
            const { keywords, locationData } = searchKeyData;
            
            if( keywords )
                paramData['body'] = keywords;
            if( locationData && locationData.location ){
                paramData['field_country_value']= locationData.location;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getButData(0, { ...paramData });
        }else{
            if( myCountry ){
                if(value){
                    _getButData(0, { field_country_value: myCountry,sort_by:value } );
                }else{
                    _getButData(0, { field_country_value: myCountry} );
                }
                
            }else{
                if(value){
                    _getButData( 0,{sort_by:value});
                }else{
                    _getButData( 0);
                }
                
            }
                
         }           
        
    }

    return (
        <>
         <Header/>
            <Banner bannerImage='buybanner.jpg' pageName='Products'>
            <div className="bannercontent">
				<div>
		    		<h3><span>Want to Buy a Bike? </span></h3>
		    		<p>Compare and get the best match for your needs!</p>
		    	</div>
	    	</div>
            <SearchBar 
                is_expert_ask={true} 
                onSearchSubmit={searchSubmitHandler}
            />
            </Banner>
			<main className="site-main inner-main">
            <div className="listing_panel listing_panelnor" id="nor">
                <div className="container">
                    <div className="listing_holder">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="listingtop clear">
									
                                   {/*  <span className="total">1 - 1 of 2 Shops</span> */}
									
                                    <form>
                                    {/* <div className="rangeholder"> 
                                            <span>price range</span> 
        								<div className="range">     
                                              <input type="text" id="amount" readonly/> 
                                            <div id="slider-range"></div>
                                        </div>
                                    </div> */}
                                    {/* <div className="sort" style={{marginLeft:10}}>
									<select name='min_price' onChange={(e)=>{setMinPrice(e.target.value);handleRangeDropDown(e)}}>
										<option>Min price</option>
										<option value='0'>€0</option>
                                        <option value='100'>€100</option>
                                        <option value='200'>€200</option>
                                        <option value='300'>€300</option>
                                        <option value='400'>€400</option>
                                        <option value='500'>€500</option>
                                        <option value='600'>€600</option>
                                        <option value='700'>€700</option>
                                        <option value='800'>€800</option>
                                        <option value='900'>€900</option>
                                        <option value='1000'>€1000</option>
                                        <option value='2000'>€2000</option>
                                        <option value='3000'>€3000</option>
                                        <option value='4000'>€4000</option>
                                        <option value='5000'>€5000</option>
									</select>
								</div> */}
                                {/* <div className="sort" style={{marginLeft:10}}>
									<select name='max_price' onChange={(e)=>{setMaxPrice(e.target.value);handleRangeDropDown(e)}}>
										<option>Max price</option>
                                        <option value='0'>€0</option>
                                        <option value='100'>€100</option>
                                        <option value='200'>€200</option>
                                        <option value='300'>€300</option>
                                        <option value='400'>€400</option>
                                        <option value='500'>€500</option>
                                        <option value='600'>€600</option>
                                        <option value='700'>€700</option>
                                        <option value='800'>€800</option>
                                        <option value='900'>€900</option>
                                        <option value='1000'>€1000</option>
                                        <option value='2000'>€2000</option>
                                        <option value='3000'>€3000</option>
                                        <option value='4000'>€4000</option>
                                        <option value='5000'>€5000</option>
									</select>
								</div> */}
								{/* <div className="sort" style={{marginLeft:10}}>
									<select name='brands' onChange={handleDropdown}>
										<option>Brand</option>
										{
												buyListData['facets'] && buyListData['facets']['brands'] && 
												buyListData['facets']['brands'].map((brand,index)=>(
													<option value={brand.url} key={index}>{brand.values.value}</option>
												))
											}
									</select>
								</div>
								<div className="sort" style={{marginLeft:10}}>
									<select name='bike_type' onChange={handleDropdown}>
										<option>Bike Type</option>
										{
												buyListData['facets'] && buyListData['facets']['Bike_type'] && 
												buyListData['facets']['Bike_type'].map((bike_type,index)=>(
													<option value={bike_type.url} key={index}>{bike_type.values.value}</option>
												))
											}
									</select>
								</div> */}
                                        {/* <div className="sort"  style={{ marginLeft: 10 }}>
                                            <select>
                                                <option>Customer type</option>
                                                <option>Local</option>
                                                <option>Cross Kommune</option>
                                                <option>Tourist(Pre-Book)</option>
                                                <option>Company</option>
                                            </select>
                                        </div> */}
                                        {/* <div className="sort" style={{ marginLeft: 10 }}>
                                            <select>
                                                <option>Price Low to High</option>
                                                <option>Price High to Low</option>
                                            </select>
                                        </div> */}
                                        {/* <Button onClick={()=>clearData()} style={{ marginLeft: 10 }}>Clear</Button> */}
                                        <SortDropDown type='buy' callSort={callSort} valueData={sortValue}/>
                                    </form>

                                </div>
                                <div className="listing_middle">
                                    <div className="row">
                                        {
                                            buyListData.data && buyListData.data.length > 0 ? buyListData.data.map((item, index) => (
                                                <BuyCardContainer dataValue={item} detail_link="" key={index}/>
                                            )) : <NoData message="No data found.."/>
                                        }
                                    </div>
									<Pagination 
										activePage={activePage} 
										pageData={buyListData && buyListData.hasOwnProperty('pageData') ? buyListData.pageData : null }
										onPageChange={pageChangeHandler}
									/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="getintouch">
                            <div className="btnholder">
                                <p>If you want to list your brand, shop/store, or retail chain because you want to reach out to the global cycle community, please get in touch with us.</p>
                                <Link className="btn contctBtn getintouchBtn" to="#" onClick={() => _openModalHandler()}>
                                    <span>Get In Touch</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GetInTouch showExpert={show} handleModal={() => setShow(false)} />
        </main>
         <Footer/>   
        </>
    )
}


const BuyCardContainer = (props) => {
    const { detail_link, dataValue } = props;

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                <Link to={`${RouteNames.buy_detail}${dataValue.alias ? dataValue.alias : ''}`}>
                    
                    
                    {/* {
                        dataValue.field_shop_icon &&
                        <span className="shptp">
                            <img src={dataValue.field_shop_icon} alt="icon" className="icon"/>
                        </span>
                    } */}
                     
                    <img src={dataValue.field_image? dataValue.field_image: require('../../assets/images/mountainbike2.jpg')} alt="mountainbike" />
                   
                    <span className="ovrly"></span>
                    
                    <Favourite content_id={dataValue.content_id} type='buy' is_already_favourite={dataValue.is_already_favourite} fullData={dataValue}/>
                </Link>
                </div>
                <div className="texthl">
                    <div className="texthlleft">
                        <h4 className="notranslate"><Link to={`${RouteNames.buy_detail}${dataValue.alias ? dataValue.alias : ''}`}>{dataValue.title}</Link></h4>
                        <span className="cmpnyname">
                            {dataValue.field_address}
                        </span>
                    </div>
                    <span className="price">
                        <span>{dataValue.field_currency} {Math.round(dataValue.field_minimum_price)} -<br /> {dataValue.field_currency} {Math.round(dataValue.field_maximum_price)}</span>
                    </span>
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{ width: '100%' }}></span>
                    </span>
                </div>
                    {
                        dataValue.field_shop_icon &&  <Characterstic field_shop_icon={dataValue.field_shop_icon}/>
                    }
               
            </div>
        </div>


    )
}

export default BuyList
