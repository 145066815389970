import React, { useState } from 'react';
import { submitReview, callLoading, resetReviewListing } from '../stores/actions/common-action';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ReviewForm = (props) => {

    const { contentId, title } = props;
    const dispatch = useDispatch();
    const [ rating, setRating ] = useState( 0 );
    const [ selectedRating, setSelectedRating ] = useState( 0 );
    const [ message, setMessage ] = useState( '' );
    const [ isFormValid, setIsFormValid ] = useState( true );
    
    const _ratingSelectHandler = ( rate ) =>{
        setRating( rate );
    }

    const _ratingClass = ( ratingNumber ) =>{
        return rating >= ratingNumber ? "singlerate active" : "singlerate";
    }

    const _selectRatingHandler = () =>{
        setSelectedRating( rating );
        if( message !== '' && selectedRating > 0 )
            setIsFormValid( true );
        else
            setIsFormValid( true );
    }

    const RatingHtml = () =>{
        return (
            <div className="ratingholder">
                <span 
                    onMouseEnter={ ( ev ) => _ratingSelectHandler(20) }
                    onMouseLeave={()=> _ratingSelectHandler( selectedRating ? selectedRating : 0 ) } 
                    onClick={_selectRatingHandler}
                    className={ _ratingClass(20) }
                ></span>
                <span 
                    onMouseEnter={ ( ev ) => _ratingSelectHandler(40) }
                    onMouseLeave={()=> _ratingSelectHandler( selectedRating ? selectedRating : 0 ) } 
                    onClick={_selectRatingHandler}
                    className={ _ratingClass(40) }
                ></span>
                <span 
                    onMouseEnter={ ( ev ) => _ratingSelectHandler(60) }
                    onMouseLeave={()=> _ratingSelectHandler( selectedRating ? selectedRating : 0 ) } 
                    onClick={_selectRatingHandler}
                    className={ _ratingClass(60) }
                ></span>
                <span 
                    onMouseEnter={ ( ev ) => _ratingSelectHandler(80) }
                    onMouseLeave={()=> _ratingSelectHandler( selectedRating ? selectedRating : 0 ) } 
                    onClick={_selectRatingHandler}
                    className={ _ratingClass(80) }
                ></span>
                <span 
                    onMouseEnter={ ( ev ) => _ratingSelectHandler(100) }
                    onMouseLeave={()=> _ratingSelectHandler( selectedRating ? selectedRating : 0 ) } 
                    onClick={_selectRatingHandler}
                    className={ _ratingClass(100) }
                ></span>
            </div>
        );
    }

    const _resetFormData = () =>{
        setSelectedRating( 0 );
        setRating( 0 );
        setMessage( '' );
    }

    const reviewFormSubmit = ( ev ) =>{
        ev.preventDefault();
        //console.log( selectedRating, message );
        if( message ){
            dispatch( callLoading( true ) );
            submitReview({
                content_id: contentId,
                body: message,
                rating: ( Number( selectedRating ) / 20 )
            }).then( resp => {
                dispatch( callLoading( false ) );
                //console.log( resp.data );
                if( !resp.error )
                    toast.success( resp.data && resp.data ? resp.data.msg : '' );
                
                _resetFormData();
                dispatch( resetReviewListing( true ) );
                
            }).catch( error => { console.log( error ); 
                dispatch( callLoading( false ) );
                toast.error( error );
                _resetFormData();
            });
        }
        else{
            toast.error( "Please fill rating and your message" );
        }
    }

    return (
        <div className="box">
            <h3>{ title || 'post your review' }</h3>
            <form onSubmit={reviewFormSubmit}>
                <div className="reviewright">
                    <div className="form-group">
                        <label>your review</label>
                        <RatingHtml/>
                    </div>
                    <div className="form-group">
                        <label>your message</label>
                        <textarea
                            value={message}
                            onChange={(ev) => {
                                setMessage( ev.target.value );
                                if( ev.target.value && selectedRating > 0 )
                                    setIsFormValid( true );
                                else
                                    setIsFormValid( true );
                            }} 
                        ></textarea>
                    </div>
                    <div className="form-group">
                            <input 
                                type="submit" 
                                name=""
                                disabled={ !isFormValid }
                            />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ReviewForm;
