import React from 'react';

function UsefulInfo(props) {
    const { data } = props;
    return (
        <div className="brandav">
            <h3>Useful Info</h3>
            <div className="main" dangerouslySetInnerHTML={{ __html: data}}></div>
        </div>
    )
}

export default UsefulInfo
