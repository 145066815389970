import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import Item from './components/item';


const Compare = (props) => {
    const location = useLocation();
    const { route } = queryString.parse(location.search);
    const compareList = useSelector( state => state.compare.compareList );
    const compareItemCount = useSelector( state => state.compare.itemCount );

	React.useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
	
	return (
        <>
        <Header/>
        <div className="banner innerbanner">
	    	<img  src={ require("../../assets/images/comparebnr.jpg") } alt="blog"/>  	       	 
		</div> 
		<main className="site-main inner-main  comparemain"> 
			<div className="container">
				<div className="compareholder">
					<div className="tophead">
						<h2 className="title3">
							Compare Bike
						</h2>
					</div>
					<div className="comparetop"> 
						<div className="comparelist">
							<div className="catdiv">
								<div className="top"></div>
								<ul className="listholder">
									<li className="head"><i className="fa fa-map-marker" aria-hidden="true"></i> Location</li>
									<li className="head"><i className="fa fa-arrows-h" aria-hidden="true"></i> Distance</li>
									<li className="head"><i className="fa fa-ravelry" aria-hidden="true"></i>Repairing Center</li>
									<li className="head"><i className="fa fa-cogs" aria-hidden="true"></i>Bike Types</li>
									<li className="head"><i className="fa fa-cogs" aria-hidden="true"></i>Accessories</li>
									<li className="head"><i className="fa fa-question" aria-hidden="true"></i>Why Choose</li>
									<li className="head"><i className="fa fa-eur" aria-hidden="true"></i>Price Range</li>
									<li className="head"><i className="fa fa-exchange" aria-hidden="true"></i>Offers</li>
									<li className="head"><i className="fa fa-star-half-o" aria-hidden="true"></i>Ratings</li>
								</ul>
							</div>
							<Item/>
							<div className="catdiv">
								<div className="top">
									<div className="catimg">
										<img src={ require("../../assets/images/product2.jpg") } alt="catimg"/>  
									</div>
									<div className="texthl"> 
										<div className="texthlleft">
											<h4><a href="repair-details.php">Track & Trail Bicycles</a></h4> 
										</div> 
									</div> 
								</div>

								<ul className="listholder">
									<li>Oslo</li>
									<li>300m</li>
									<li> Berg, Sandes</li>
									<li>Tandem, Road Eike, Racing Bike</li>
									<li>Gear, Helmet, Glass</li>
									<li>Luggage lockers available in our shops. From €3,50 per day.</li>
									<li> 
										<span className="price">15,00-18,00</span> 
										<select className="converter">
											<option>Euro</option>
											<option>Pound</option>
											<option>Rupee</option>
										</select> 
									</li>
									<li>15% off</li>
									<li>
										<span className="rating">
											<span className="rate"  style={{ width: "70%" }}></span>
										</span>
									</li>
								</ul>
							</div>
							<div className="catdiv addmore">
								<div className="top">
									<Link className="add" to={ route ? route : '#' }>
										<i className="fa fa-plus" aria-hidden="true"></i>
										<span>add cycle</span>
									</Link>
								</div>
							</div> 
						</div> 
					</div> 
				</div>
			</div>
		</main>
        <Footer/>
        </>
    )
}

export default Compare
