import React from 'react';
import Header from '../../../includes/Header';
import Footer from '../../../includes/Footer';
import Banner from '../../../includes/Banner';
import SearchBar from '../../../reuseables/SearchBar';
import UserDataReport from './components/user-data-report';
import ReportWithFilter from './components/report-with-filter';

const Statistic = () => {
    return (
        <> 
            <Header/>
            <Banner bannerImage="eventbanner.jpg" pageName="My Statistic">
                <SearchBar  is_expert_ask={true} />
            </Banner> 
            <main className="site-main inner-main about-main"> 
                <section className="analyticsmain">
                    <div className="container">
                        <div className="tophead">
                            <h2 className="title1">
                                Carryon.bike Analytics
                            </h2>
                            <p>Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p>
                        </div>
                        <UserDataReport/>
                        <ReportWithFilter/>
                    </div>
                </section>

            </main>

            <Footer />
		</>
    )
}

export default Statistic
