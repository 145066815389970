import { FETCH_COMPARE_LIST, ADD_TO_COMPARE, REMOVE_FROM_COMPARE_LIST } from '../actions/compare';
const initState = {
    compareList : [],
    itemCount : 0 
}

export default (state = initState, action ) =>{

    switch( action.type ){
        case FETCH_COMPARE_LIST : {
            return {
                ...state
            };
        }

        case ADD_TO_COMPARE : {
            return {
                compareList : !action.error ? [...state.compareList, action.data ] : state.compareList,
                itemCount: state.itemCount + 1,
            }
        }

        case REMOVE_FROM_COMPARE_LIST : {
            return {
                compareList: !action.error ? state.compareList.filter( comp => comp !== action.data ) : state.compareList,
                itemCount: !action.error ? state.itemCount - 1 : state.itemCount,
            }
        }
    }

    return state;
}