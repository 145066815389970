import React from 'react';

const InstagramFeed = (props) => {
    return (
        <div className="inswidget" >				
            <div className="elfsight-app-7f2b8253-93d5-4a4c-adde-777ab8bc7465"></div> 
            <div 
                className='embedsocial-instagram' 
                data-ref="9d05571d34c50e985a42db97c42015b43a86d245">
            </div> 
        </div>
    )
}

export default InstagramFeed
