import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch} from 'react-redux';
import MainQuery from './main-query';

const TotalUser = (props) => {
    const { noOfDays, title, slug } = props;
    const [totalUser, setTotalUser] = useState( 0 );  
    const [loading, setLoading ] = useState( true );
    const displayResults = (response) => {
        //console.log("Total User ---------", response );
        const queryResult = response.result.reports[0].data.rows;
        let total = 0;
        queryResult.forEach((row, idx) => {
            if ( row.dimensions[0] === slug ) {
                total += parseInt( row.metrics[0].values[1] );
            }
        });
        
        setTotalUser( total );
        setLoading( false );    
    };

    useEffect(() => {
        
        const request = {
          startDate : noOfDays ? moment().subtract(noOfDays,'d').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate : moment().format('YYYY-MM-DD'),
          metrics: [ "ga:pageviews", "ga:users" ],
          dimensions: [ "ga:pagePath", "ga:date" ],
          filter: "ga:pagePath!@localhost/"
        };
        setLoading( true );
        setTimeout(()=>{
          //MainQuery( request ).then( resp => displayResults( resp ) ).catch( error => console.error(error));
        }, 600);

      }, []);

    return (
        <div className="col-md-3 col-lg-3 col-sm-12 col-12">
            <div className="planDtlsInner">
                <div className="top"> 
                    <div>
                        <h4>{ title }</h4>
                        {
                            loading && <h2>10</h2>
                        }
                        { !loading && <h2>{ totalUser }</h2> }
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default TotalUser
