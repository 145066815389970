import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import Pagination from '../../reuseables/Pagination';
import ReviewListing from '../../reuseables/review-listing';
import ReviewForm from '../../reuseables/review-form';
import ReviewDetails from './components/review-details';
import BikeType from './components/bike-type';
import WhyChooseUs from './components/why-choose-us';
import UsefulInfo from './components/useful-info';
import Keywords from './components/keywords';
import PhotoGallary from './components/photo-gallery';
import Video from './components/video';
import Map from './components/map';
import { getBuyDetails, callLoading, getFormContent } from '../../stores/actions/common-action';
import { RouteNames } from '../../includes/route-names';
import queryString from 'query-string';
import SocialShare from '../../reuseables/social-share';


const BuyDetail = (props) => {

	const { alias } = useParams();
	const _location = useLocation();
	const queryData = queryString.parse( window.location.search );
	const dispatch = useDispatch();
	const [ activePage, setActivePage ] = useState( 1 );
	const [ keywords, setKeywords ] = useState('');
	const [ locationData, setLocationData ] = useState( {} );
	const buyDetailsData = useSelector( state => state.common.buyDetailsData );
	const [isPreview, setIsPreview] = useState(false)

	/* if(buyDetailsData && buyDetailsData.data.length){
		console.log(buyDetailsData.data[0], '99990')
	} */

	const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getBuyDetails( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}
	
	const searchSubmitHandler = ( data ) =>{
		//props.history.push(`/listing-to-buy-bikes-and-bike-accessories?body=${keywords}&lat=${locationData.lat}&long=${locationData.long}&`)
		const { keywords, locationData } = data;
		console.log(locationData, '999')
        //console.log( 'kkk======', keywords );
        let paramData = {};
        let queryData ={};
        paramData['alias'] = `/${alias}`;

		if( keywords ){
			paramData['body'] = keywords;
			queryData['body'] = keywords;
			setKeywords( keywords );
		}
		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}
		/* if( locationData && Object.keys(locationData).length > 0 ){
			paramData['lat'] = locationData.lat;
			paramData['long'] = locationData.long;
			queryData['lat'] = locationData.lat;
			queryData['long'] = locationData.long;
			if( locationData.city ){
				paramData['field_city_value'] = locationData.city;
				queryData['field_city_value'] = locationData.city;
			}

			if( locationData.country ){
				paramData['field_country_value'] = locationData.country;
				queryData['field_country_value'] = locationData.country;
				
			}
		} */
		var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
		})
		props.history.push(RouteNames.buy + queryString)

		return;
		//_getDestinationData( 0, { ...paramData } );
    }

	useEffect(() => {
		if(queryData && queryData.preview){
			setIsPreview(true)
			dispatch(callLoading(true))
			dispatch(getFormContent(`buy/userdata?page=0&content_id=${alias}`,'fetch_buy_details')).then(res=>{
				dispatch(callLoading(false))
			})
		}else{
			_getDestinationData( 0, { alias: `/${alias}` });
		}
		
	}, [alias]);

	const pageChangeHandler = ( page )=>{
		_getDestinationData( page - 1, { field_city_value: `/${alias}` } );
	}

	console.log( _location );

	return (
		<>
			<Header />
			<Banner bannerImage='buybanner.jpg' parentRoute={RouteNames.buy} parentPageName='Products' pageName={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].title}>
				{
					!isPreview && <SearchBar onSearchSubmit={searchSubmitHandler} />
				}
				
			</Banner>
			<main className="site-main inner-main">
				<div className="detailsmain">
					<div className="container">
						<div className="detailsholder">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-12">
									<div className="detailsleft">
										
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].title &&
											<>
											{
												buyDetailsData.data[0].field_logo && 	
												<span className="titlelogo">
													<img src={buyDetailsData.data[0].field_logo} alt="" />
												</span>
											}
												
												<h2 className="notranslate">{ buyDetailsData.data[0].title }</h2>
											</>
										}
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_address && 
											<ul className="address">
												<li>
													<img src={require('../../assets/images/addressico.png')} alt="" />
													<span>{ buyDetailsData.data[0].field_address }</span>
												</li>
												<li>
													<img src={buyDetailsData.data[0].field_country_icon} alt="" />
													<span>{ buyDetailsData.data[0].field_country || '' }</span>
												</li>
											</ul>
										}
										
										<div className="dtldiv"> 
												<ReviewDetails isPreview={isPreview} buyData={ buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0] ? buyDetailsData.data[0] : null }/>
												{
													buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_brands &&
													<h3 className="brandname">
														<span className="ico">
															<img src={require('../../assets/images/brandico.png')} alt="" />
														</span>
														<span>Manufacturer/Brand Name:</span>
														{ buyDetailsData.data[0].field_brands }
													</h3>
												}
												
												{
													buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_email && 
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/mail.png')} alt="" />
														</span>
														<span>Email:</span>
														<a className="" href={`mailto:${buyDetailsData.data[0].field_email}`}>
															{ buyDetailsData.data[0].field_email }
														</a>
													</h3>
												}
												{ 
													buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].is_international && 
													<h3 className="brandname">
														<span className="ico">
															<img src={require('../../assets/images/shippingico.png')} alt="" />
														</span>
														<span>World Wide Shipping:</span>
														{ buyDetailsData.data[0].is_international }
													</h3>
												}
												
												{
													buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_phone && 
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/phone.png')} alt="" />
														</span>
														<span>Phone:</span>
														<a className="" href={`tel:${ buyDetailsData.data[0].field_phone }`}>
															{ buyDetailsData.data[0].field_phone }
														</a>
														
													</h3>
												}
												
												{
													buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_urls &&
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/url.png')} alt="" />
														</span>
														<span>URL:</span>
														<Link className="" to={{pathname:buyDetailsData.data[0].field_urls}} target="_blank">
															{ buyDetailsData.data[0].field_urls }
														</Link>
													</h3>
												}
												<h3 className="shopweb brandname">
													<SocialShare
														fb={true}
														whatsapp={true} 
														linkedin={true} 
														pinterest={true} 
														tw={true}
														shareUrl={_location.pathname}
													/>
												</h3>
												  
										</div>

										<BikeType data={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_bike_type ? buyDetailsData.data[0].field_bike_type : null }/>
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 &&
											<WhyChooseUs
											currencySymbol={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_currency ? buyDetailsData.data[0].field_currency : null}
											dataChoose={ buyDetailsData.data[0].why_choose_us ? buyDetailsData.data[0].why_choose_us : (buyDetailsData.data[0].body ? buyDetailsData.data[0].body : null) }
											dataOffers={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_offers ? buyDetailsData.data[0].field_offers : null }
											priceMin={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_minimum_price ? buyDetailsData.data[0].field_minimum_price : null }
											priceMax={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_maximum_price ? buyDetailsData.data[0].field_maximum_price : null }
										/>
										}
										
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 && 
											<UsefulInfo data={ buyDetailsData.data[0].useful_info ? buyDetailsData.data[0].useful_info :  (buyDetailsData.data[0].field_why_should_customer_choose?buyDetailsData.data[0].field_why_should_customer_choose : null ) }/>
										}
										
										<ReviewListing title="Review" contentId={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].content_id ? buyDetailsData.data[0].content_id : null}/>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-4 col-12">
									<div className="reservationform">
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_geofield &&
											<div className="map">
												<Map latLong={ buyDetailsData.data[0].field_geofield }/>
											</div>
										}
										{
											buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_keywords &&
											<Keywords 
												keys={ buyDetailsData.data[0].field_keywords} 
												selectedKey={keywords}
												onselect={searchSubmitHandler}
											/>
										}
										{
											buyDetailsData.data && buyDetailsData.data.length > 0  && buyDetailsData.data[0].field_image &&
											<PhotoGallary photos={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_image ? buyDetailsData.data[0].field_image : null}/>
										}
										
										<Video video={ buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].field_video_embed ? buyDetailsData.data[0].field_video_embed : null }/>
										{
											!isPreview && <ReviewForm contentId={buyDetailsData.data && buyDetailsData.data.length > 0 && buyDetailsData.data[0].content_id ? buyDetailsData.data[0].content_id : null} />
										}
										
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pagination 
						activePage={activePage}
						pageData={ buyDetailsData && buyDetailsData.hasOwnProperty('pageData') ? buyDetailsData.pageData : null }
						onPageChange={pageChangeHandler}
					/>
				</div>
			</main>
			<Footer />
		</>
	)
}

export default BuyDetail
