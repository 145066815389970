import React from 'react';
import MainReportWrapper from '../../../../reuseables/reports/main-report-wrapper';
import TotalUser from '../../../../reuseables/reports/total-user';
import UserSessionWise from '../../../../reuseables/reports/userSessionWise';
import UserWise from '../../../../reuseables/reports/userWise';

const UserDataReport = ( props ) => {
    return (
        
        <div className="membershipPlans">
            <div className="planDtls">
                <div className="row">
                    <MainReportWrapper 
                        component={<TotalUser noOfDays={7} title="Total Users" slug="/"/>}
                    />
                    
                     <MainReportWrapper component={<TotalUser title="New Users" slug="/"/>}/>
                    <MainReportWrapper 
                        component={<UserWise noOfDays={7} slug="/"/>} />
                    <MainReportWrapper component={<UserSessionWise slug="/" />}/> 

                </div>
            </div> 
        </div>
    )
}

export default UserDataReport;
