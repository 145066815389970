import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch} from 'react-redux';
import MainQuery from './main-query';

const UserWise = (props) => {
    const [reportData, setReportData] = useState([]);  
    const { noOfDays, slug } = props;
    const displayResults = (response) => {
        console.log( response );
        
        const queryResult = response.result.reports[0].data.rows;
            //setTotalPages(queryResult.length);
            const total = response.result.reports[0].data.totals[0].values[0];
            let newReportData = [];
            queryResult.forEach((row, idx) => {
            if ( row.dimensions[0] === slug ) {
              //row.dimensions[0] === '/listing-bike-repair-and-bike-accessory-repairing'
              //idx < 10
                let tempObj = {
                path: row.dimensions[0],
                views: row.metrics[0].values[0],
                //perc: `${parseFloat((row.metrics[0].values[0] / total) * 100).toFixed(
                //    1
                //)}%`,
                };
            newReportData.push(tempObj);
        }
        });
        setReportData(newReportData);      
    };

    useEffect(() => {
        
        const request = {
          startDate : noOfDays ? moment().subtract(noOfDays,'d').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate : moment().format('YYYY-MM-DD'),
          metrics: [ "ga:pageviews" ],
          dimensions: [ "ga:pagePath" ],
          filter: "ga:pagePath!@localhost/"
        };

        setTimeout(()=>{
          //MainQuery( request ).then( resp => displayResults( resp ) ).catch( error => console.error(error));
        }, 600);

      }, []);

    return (
        <div className="col-md-3 col-lg-3 col-sm-12 col-12">
            <div className="planDtlsInner">
                <div className="top"> 
                    <div>
                        <h4>Page Views</h4> 
                        {reportData && reportData.map((row, id) => (
                            <h2 key={id}>{row.views}</h2>
                            ))
                        }
                        
                        {
                            !reportData.length &&
                            <h2>
                                10
                            </h2>
                            }
                        
                        
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default UserWise;
