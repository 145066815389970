import React from 'react';

const Video = (props) => {
    const { video } = props;
    return (
        <div className="box">
            {
                video &&
                <>
                    <h3>video</h3>
                    <div className="vdo">
                        <iframe src={video} 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen></iframe>
                    </div>
                </>
            }
        </div>
    )
}

export default Video
