import React, { useEffect, useState } from 'react'
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link, Route } from 'react-router-dom';
import GetInTouch from '../../reuseables/get-in-touch';

const Partner =(props)=>{
	const [show, setShow] = useState(false);

	const _openModalHandler = () => setShow(true);
	
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        }
    }, [])


    return (
        <>
          <Header />
          <div className="banner innerbanner ">
                <img  src={require('../../assets/images/eventbanner.jpg')} alt="blog"/> 	       	 
            </div> 
            <main className="site-main inner-main "> 
			
			<div className="partner-section">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							our Partners
						</h2>  
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
							<div className="patrner-img-hldr">
								<Link to={{pathname:"https://mywindsock.com/"}} target="_blank">
								  <img src={require('../../assets/images/partnr7.png')} alt="partner1"/>
								</Link>
							</div>
						</div>

						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
							<div className="patrner-img-hldr">
							<Link to={{pathname:"https://www.naturtrack.com/en/"}} target="_blank">
							  <img src={require('../../assets/images/partnr8.png')} alt="partner2"/>
							</Link>
							</div>
						</div>

						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
							<div className="patrner-img-hldr">
							<Link to={{pathname:"https://www.naturtrack.com/en/"}} target="_blank">
							  <img src={require('../../assets/images/nicci.png')} alt="partner2"/>
							</Link>
							</div>
						</div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
							<div className="patrner-img-hldr">
							<Link to={{pathname:"https://bikeep.com/"}} target="_blank">
							  <img src={require('../../assets/images/partnr9.png')} alt="partner2"/>
							</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="getintouch">
							<div className="btnholder">
								<p>If you want to list your brand, shop/store, or retail chain because you want to reach out to the global cycle community, please get in touch with us.</p>
								<Link className="btn contctBtn" to="#" onClick={() => _openModalHandler()}>
									<span>Contact Us</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>  
		<GetInTouch showExpert={show} handleModal={() => setShow(false)} />
        <Footer />
        </>
    )
}

export default Partner
