import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import Pagination from '../../reuseables/Pagination';
import ReviewListing from '../../reuseables/review-listing';
import ReviewForm from '../../reuseables/review-form';
import ReviewDetails from '../buy/components/review-details';
import BikeType from '../buy/components/bike-type';
import WhyChooseUs from '../buy/components/why-choose-us';
import UsefulInfo from '../buy/components/useful-info';
import Keywords from '../buy/components/keywords';
import PhotoGallary from '../buy/components/photo-gallery';
import Video from '../buy/components/video';
import Map from '../buy/components/map';
import { getServicesDetails, callLoading, getFormContent } from '../../stores/actions/common-action';
import { RouteNames } from '../../includes/route-names';
import queryString from 'query-string';

const ServicesDetail =(props)=>{
	const { alias } = useParams();
	const queryData = queryString.parse( window.location.search );
	const dispatch = useDispatch();
	const [ activePage, setActivePage ] = useState( 1 );
	const [ keywords, setKeywords ] = useState('');
	const [ locationData, setLocationData ] = useState({});
	const servicesDetailData = useSelector( state => state.common.servicesDetailData );
	const [isPreview, setIsPreview] = useState(false)

	const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getServicesDetails( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}

	const searchSubmitHandler = ( data ) =>{
		const { keywords, locationData } = data;
        let paramData = {};
        let queryData ={};
        paramData['alias'] = `/${alias}`;

		if( keywords ){
			paramData['body'] = keywords;
			queryData['body'] = keywords;
			setKeywords( keywords );
		}
		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}

		var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
		})
		props.history.push(RouteNames.services + queryString)

		return;
		//_getDestinationData( 0, { ...paramData } );
    }

	useEffect(() => {
		if(queryData && queryData.preview){
			window.scrollTo(0,0);
			setIsPreview(true)
			dispatch(callLoading(true))
			dispatch(getFormContent(`service/userdata?page=0&content_id=${alias}`,'fetch_services_details')).then(res=>{
				dispatch(callLoading(false))
			})
		}else{
			_getDestinationData( 0, { alias: `/${alias}` });
		}
		
	}, [alias]);

	const pageChangeHandler = ( page )=>{
		_getDestinationData( page - 1, { field_city_value: `/${alias}` } );
	}

	return (
		<>
			<Header />
			<Banner bannerImage='rentbanner.jpg' parentRoute={RouteNames.services} parentPageName='Services' pageName={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].title}>
				{
					!isPreview && <SearchBar onSearchSubmit={searchSubmitHandler} />
				}
				
			</Banner>
			<main className="site-main inner-main">
				<div className="detailsmain">
					<div className="container">
						<div className="detailsholder">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-12">
									<div className="detailsleft">
										
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].title &&
											<>
											{
												servicesDetailData.data[0].field_logo && 	
												<span className="titlelogo">
													<img src={servicesDetailData.data[0].field_logo} alt="" />
												</span>
											}
												
												<h2 className="notranslate">{ servicesDetailData.data[0].title }</h2>
											</>
										}
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_address && 
											<ul className="address">
												<li>
													<img src={require('../../assets/images/addressico.png')} alt="" />
													<span>{ servicesDetailData.data[0].field_address }</span>
												</li>
												<li>
													<img src={servicesDetailData.data[0].field_country_icon} alt="" />
													<span>{ servicesDetailData.data[0].field_country || '' }</span>
												</li>
											</ul>
										}
										
										<div className="dtldiv"> 
												<ReviewDetails isPreview={isPreview} buyData={ servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0] ? servicesDetailData.data[0] : null }/>
												{
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data.field_brands &&
													<h3 className="brandname">
														<span className="ico">
															<img src={require('../../assets/images/brandico.png')} alt="" />
														</span>
														<span>Manufacturer/Brand Name:</span>
														{ servicesDetailData.data.field_brands }
													</h3>
												}
												{
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_email && 
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/mail.png')} alt="" />
														</span>
														<span>Email:</span>
														<a className="" href={`mailto:${servicesDetailData.data[0].field_email}`}>
															{ servicesDetailData.data[0].field_email }
														</a>
													</h3>
												}

												{
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_brands &&
													<h3 className="brandname">
														<span className="ico">
															<img src={require('../../assets/images/brandico.png')} alt="" />
														</span>
														<span>Brand Name:</span>
														{ servicesDetailData.data[0].field_brands }
													</h3>
												}

												{ 
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].is_international && 
													<h3 className="brandname">
														<span className="ico">
															<img src={require('../../assets/images/shippingico.png')} alt="" />
														</span>
														<span>World Wide Services:</span>
														{ servicesDetailData.data[0].is_international }
													</h3>
												}
												{
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_phone && 
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/phone.png')} alt="" />
														</span>
														<span>Phone:</span>
														<a className="" href={`tel:${ servicesDetailData.data[0].field_phone }`}>
															{ servicesDetailData.data[0].field_phone }
														</a>
														
													</h3>
												}
												
												{
													servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_urls &&
													<h3 className="shopweb brandname">
														<span className="ico">
															<img src={require('../../assets/images/url.png')} alt="" />
														</span>
														<span>URL:</span>
														<Link className="" to={{pathname:servicesDetailData.data[0].field_urls}} target="_blank">
															{ servicesDetailData.data[0].field_urls }
														</Link>
													</h3>
												}  
										</div>

										<BikeType
											title="Service Types"
											data={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_service ? servicesDetailData.data[0].field_service : null }
										/>
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 &&
											<WhyChooseUs
											currencySymbol={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_currency ? servicesDetailData.data[0].field_currency : null}
											dataChoose={ servicesDetailData.data[0].why_choose_us ? servicesDetailData.data[0].why_choose_us : (servicesDetailData.data[0].body ? servicesDetailData.data[0].body : null) }
											dataOffers={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_offers ? servicesDetailData.data[0].field_offers : null }
											priceMin={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_minimum_price ? servicesDetailData.data[0].field_minimum_price : null }
											priceMax={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_maximum_price ? servicesDetailData.data[0].field_maximum_price : null }
										/>
										}
										
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 && 
											<UsefulInfo data={ servicesDetailData.data[0].useful_info ? servicesDetailData.data[0].useful_info :  (servicesDetailData.data[0].field_why_should_customer_choose?servicesDetailData.data[0].field_why_should_customer_choose : null ) }/>
										}
										
										<ReviewListing title="Review" contentId={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].content_id ? servicesDetailData.data[0].content_id : null}/>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-4 col-12">
									<div className="reservationform">
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_geofield &&
											<div className="map">
												<Map latLong={ servicesDetailData.data[0].field_geofield }/>
											</div>
										}
										{
											servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_keywords &&
											<Keywords 
												keys={ servicesDetailData.data[0].field_keywords} 
												selectedKey={keywords}
												onselect={searchSubmitHandler}
											/>
										}
										{
											servicesDetailData.data && servicesDetailData.data.length > 0  && servicesDetailData.data[0].field_image &&
											<PhotoGallary photos={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_image ? servicesDetailData.data[0].field_image : null}/>
										}
										
										<Video video={ servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].field_video_embed ? servicesDetailData.data[0].field_video_embed : null }/>
										{
											!isPreview && <ReviewForm contentId={servicesDetailData.data && servicesDetailData.data.length > 0 && servicesDetailData.data[0].content_id ? servicesDetailData.data[0].content_id : null} />
										}
										
									</div>
								</div>
							</div>
						</div>
					</div>
					<Pagination 
						activePage={activePage}
						pageData={ servicesDetailData && servicesDetailData.hasOwnProperty('pageData') ? servicesDetailData.pageData : null }
						onPageChange={pageChangeHandler}
					/>
				</div>
			</main>
			<Footer />
		</>
	)
}

export default ServicesDetail
