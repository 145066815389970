import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useParams, useHistory, Redirect, useLocation } from 'react-router-dom';
import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import { getDestinationList, callLoading, clearSearch } from '../../stores/actions/common-action';
import InstagramFeed from './components/instagram-feed';
import Pagination from '../../reuseables/Pagination';
import Item from './components/item';
import Banner from '../../includes/Banner'
import SearchBar from '../../reuseables/SearchBar';
import NoData from '../../reuseables/no-data';
import SortDropDown from '../../reuseables/SortDropDown';

const BikeDestination = (props) => {
	const { country } = useParams();
	const location = useLocation();
	const paramData = queryString.parse(location.search);
	const myCountry = useSelector(state => state.common.myCountry);
	const resetSearch = useSelector(state => state.common.resetSearch);
	//console.log( paramData );
	const destinationList = useSelector(state => state.common.destinationData);
	const [activePage, setActivePage] = useState(1);
	const [keywords, setKeywords] = useState( paramData && paramData.body ? paramData.body : '');
	const [locationData, setLocationData] = useState({});
	const [searchCountry, setSearchCountry] = useState(country);
	const [sortValue, setSortValue] = useState('')
	const [searchKeyData, setSearchKeyData] = useState({})
	const [queryDataValue, setQueryDataValue] = useState({})

	const dispatch = useDispatch();

	const _getDestinationData = (page, filterData = {}) => {

		dispatch(callLoading(true))
		dispatch(getDestinationList(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;

				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}

	//field_city_value
	useEffect(() => {
		if (Object.keys(paramData).length > 0 && !resetSearch) {
			if(Object.keys(paramData).indexOf('field_country_value') === -1){
                let queryValue = paramData;
                queryValue['sort_by'] = 'search_api_relevance'
                setSortValue('search_api_relevance')
            }
			setQueryDataValue(paramData);
			_getDestinationData(0, { ...paramData });
		}
		else {
			if (myCountry)
				_getDestinationData(0, { field_country_value: myCountry });
		}

		if (resetSearch) {
			clearData();
			dispatch(clearSearch(false));
		}
		//setSelectedCountryValue( country );
	}, [myCountry, resetSearch]);

	const pageChangeHandler = (page) => {
		const { keywords, locationData } = searchKeyData;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
        }else if( myCountry ){
            paramData['field_country_value']= myCountry;
        }
        if(sortValue){
            paramData['sort_by']= sortValue;
        }
		_getDestinationData(page - 1, { ...paramData });
	}

	//console.log("DES", country);

	const searchSubmitHandler = (data) => {
		setQueryDataValue({})
		setSearchKeyData(data)
		const { keywords, locationData } = data;
		let paramData = {};
		if (keywords)
			paramData['body'] = keywords;
		if (locationData && locationData.location) {
			paramData['field_country_value'] = locationData.location;
			if(sortValue =='search_api_relevance'){
                setSortValue('');
            }
		}
		if(sortValue){
            if(Object.entries(locationData).length > 0 && keywords && sortValue !='search_api_relevance'){
            paramData['sort_by']= sortValue;
            }
        }

		if(Object.entries(locationData).length == 0 && keywords){
			paramData['sort_by']= 'search_api_relevance';
			setSortValue('search_api_relevance')
			_getDestinationData(0, { ...paramData });
		   }else{
				_getDestinationData(0, { ...paramData });
		   }
			

	}

	const clearData = () => {
		_getDestinationData(0);
		setSortValue()
		setKeywords( null );
		setSearchKeyData({})
		setQueryDataValue({})
	}

	const callSort = (value) => {
		setSortValue(value)
		let paramData = {};
		if (Object.keys(queryDataValue).length > 0) {
			const { body, field_country_value } = queryDataValue;
			if (body)
				paramData['body'] = body;
			if (field_country_value) {
				paramData['field_country_value'] = field_country_value;
			}
			if (value) {
				paramData['sort_by'] = value
			}
			_getDestinationData(0, { ...paramData });

		} else if (Object.keys(searchKeyData).length > 0) {
			const { keywords, locationData } = searchKeyData;

			if (keywords)
				paramData['body'] = keywords;
			if (locationData && locationData.location) {
				paramData['field_country_value'] = locationData.location;
			}
			if (value) {
				paramData['sort_by'] = value
			}
			_getDestinationData(0, { ...paramData });
		} else {
			if (myCountry) {
				if (value) {
					_getDestinationData(0, { field_country_value: myCountry, sort_by: value });
				} else {
					_getDestinationData(0, { field_country_value: myCountry });
				}

			} else {
				if (value) {
					_getDestinationData(0, { sort_by: value });
				} else {
					_getDestinationData(0);
				}

			}

		}

	}

	return (
		<>
			<Header />
			<Banner bannerImage='destinationbnr.jpg' pageName='Biking Destination'>
				<div className="bannercontent">
					<div>
						<h3><span>Destinations To Explore On Bike </span></h3>
					</div>
				</div>
				<SearchBar
					is_expert_ask={true}
					onSearchSubmit={searchSubmitHandler}
					keyword={keywords}
				//removeLocation={true}	
				/>
			</Banner>

			<main className="site-main inner-main">
				<div className="listingtop clear">
					<div className="container">
						<form>
							<SortDropDown type='destination' callSort={callSort} valueData={sortValue} />
						</form>

					</div>
				</div>
				<section className="travelSection">

					<div className="container">
						<div className="listing_panel listing_panelnor" id="nor">
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 col-12">

									<div className="destholder">
										<div className="row">
											{
												destinationList && destinationList.data && destinationList.data.length > 0 ? destinationList.data.map((item, key) =>
													<Item key={key} item={item} />
												) : <NoData />
											}

										</div>

										{/* <InstagramFeed /> */}
									</div>
								</div>
							</div>
							<Pagination
								activePage={activePage}
								pageData={destinationList && destinationList.hasOwnProperty('pageData') ? destinationList.pageData : null}
								onPageChange={pageChangeHandler}
							/>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	)
}

export default BikeDestination;
