import React, { useEffect, useState } from 'react';
import { GOOGLE_API_KEY } from '../../../../constants/config';

const Map = ( props ) => {
    const { latLong } = props;
    console.log('GOO', GOOGLE_API_KEY );
    return (
        <div className="map">
            {
                latLong &&
                <iframe 
                    width="300" 
                    height="170" 
                    frameBorder="0" 
                    scrolling="no" 
                    marginHeight="0" 
                    marginWidth="0" 
                    src={`https://maps.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${latLong}&zoom=11`}
                >
                </iframe>
            }
        </div>
    )
}

export default Map;
