import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import EventForm from './EventForm'
import { Link } from 'react-router-dom';
import Pagination from '../../reuseables/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getEventList, callLoading, getEventSubList, addingFavourite, clearSearch } from '../../stores/actions/common-action';
import SearchBar from '../../reuseables/SearchBar';
import { Button } from 'react-bootstrap';
import { RouteNames } from '../../includes/route-names';
import queryString from 'query-string';
import Favourite from '../../reuseables/Favourite';
import SortDropDown from '../../reuseables/SortDropDown';
import Characterstic from '../../reuseables/Characterstic';

const EventList = (props) => {
	const { country } = useParams();
	const eventListData = useSelector(state => state.common.eventData);
	const myCountry = useSelector( state => state.common.myCountry );
	const resetSearch = useSelector(state => state.common.resetSearch);
	const [activePage, setActivePage] = useState(1);
	const [ searchCountry, setSearchCountry ] = useState( country );
	const queryData = queryString.parse( window.location.search );
	const [sortValue, setSortValue] = useState('')
    const [searchKeyData,setSearchKeyData] = useState({})
    const [queryDataValue,setQueryDataValue] = useState({})
	const dispatch = useDispatch();
	const [showSearch, setShowSearch] = useState(false)
	
	const _getEventData = (page, filterData = {}) => {

		dispatch(callLoading(true))
		dispatch(getEventList(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;

				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}

	React.useEffect(() => {
		window.scrollTo(0, 0);
		if( Object.keys(queryData).length > 0 && !resetSearch ){
			if(Object.keys(queryData).indexOf('field_country_value') === -1){
                let queryValue = queryData;
                queryValue['sort_by'] = 'search_api_relevance'
                setSortValue('search_api_relevance')
            }
			setQueryDataValue(queryData);
			_getEventData(0, { ...queryData });
		}else{
			if( myCountry )
				_getEventData( 0, {field_country_value: myCountry} )
		}

		if( resetSearch ){
			clearData();
			dispatch( clearSearch( false ) );
		}
		
	}, [myCountry, resetSearch]);

	const pageChangeHandler = (page) => {
		const { keywords, locationData } = searchKeyData;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
        }else if( myCountry ){
            paramData['field_country_value']= myCountry;
        }
        if(sortValue){
            paramData['sort_by']= sortValue;
        }
		_getEventData(page - 1, { ...paramData });
	}

	const searchSubmitHandler = (data) => {
		setQueryDataValue({})
        setSearchKeyData(data)
		const { keywords, locationData } = data;
		let paramData = {};
		if( keywords )
			paramData['body'] = keywords;

		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData.location;
			if(sortValue =='search_api_relevance'){
                setSortValue('');
            }
		}
		if(sortValue){
			if(Object.entries(locationData).length > 0 && keywords && sortValue !='search_api_relevance'){
				paramData['sort_by']= sortValue;
			}
		}
		if(Object.entries(locationData).length == 0 && keywords){
			paramData['sort_by']= 'search_api_relevance';
			setSortValue('search_api_relevance')
			_getEventData(0, { ...paramData });
		   }else{
				_getEventData(0, { ...paramData });
		   }

	}

	const handleDropdown =(e)=>{
		dispatch(callLoading(true))
		dispatch(getEventSubList(e.target.value)).then((res)=>{
			dispatch(callLoading(false));
		})
	}

	const clearData =()=>{
        setSortValue()
        setSearchKeyData({})
        setQueryDataValue({})
    }

	const callSort =(value)=>{
        setSortValue(value)
        let paramData = {};
        if(Object.keys(queryDataValue).length > 0){
            const { body, field_country_value } = queryDataValue;
            if( body )
                paramData['body'] = body;
            if( field_country_value ){
                paramData['field_country_value']= field_country_value;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getEventData(0, { ...paramData });

        }else if(Object.keys(searchKeyData).length > 0){
            const { keywords, locationData } = searchKeyData;
            
            if( keywords )
                paramData['body'] = keywords;
            if( locationData && locationData.location ){
                paramData['field_country_value']= locationData.location;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getEventData(0, { ...paramData });
        }else{
            if( myCountry ){
                if(value){
                    _getEventData(0, { field_country_value: myCountry,sort_by:value } );
                }else{
                    _getEventData(0, { field_country_value: myCountry} );
                }
                
            }else{
                if(value){
                    _getEventData( 0,{sort_by:value});
                }else{
                    _getEventData( 0);
                }
                
            }
                
         }           
        
	}
	
	const handleSearchToggle =(toogle)=>{
		setShowSearch(prevState =>!prevState)
		document.querySelector('.bannerform > form').style.display = !toogle?'block':'none';
	}


	return (
		<>
			<Header />
			<div className="banner innerbanner innerbannernew eventbanner">
				<video id="myvdo" autoPlay="" loop="" muted="">
					<source src={require('../../assets/videos/cycle-race.mp4')} type="video/mp4" />
				</video>
				<span className="searchico">
                    <img src={require('../../assets/images/search1.png')} alt="expertIcon" onClick={()=>handleSearchToggle(showSearch)}/>
                </span>
				<div className="bannercontent">
					<div>
						<h3><span>Events In </span> And Around</h3>
					</div>
				</div>
				<SearchBar is_expert_ask={true} onSearchSubmit={searchSubmitHandler} />
			</div>
			<main className="site-main inner-main">
				<div className="eventSection">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-12">
								<div className="filterholder filterholder1">
									<div className="form-group">
										<select name='for_whom' onChange={handleDropdown}>
											<option value=''>For Whom</option>
											{
												eventListData['facets'] && eventListData['facets']['For Whom'] && 
												eventListData['facets']['For Whom'].map((whom,index)=>(
													<option value={whom.url} key={index}>{whom.values.value}</option>
												))
											}
										</select>
									</div>
									<div className="form-group">
										<select name='competitive' onChange={handleDropdown}>
											<option value=''>Competitive</option>
											{
												eventListData['facets'] && eventListData['facets']['Competttive'] && 
												eventListData['facets']['Competttive'].map((comp,index)=>(
													<option value={comp.url} key={index}>{comp.values.value}</option>
												))
											}
										</select>
									</div>
									<div className="form-group">
										<select name='event_type' onChange={handleDropdown}>
											<option value=''>Event Types</option>
											{
												eventListData['facets'] && eventListData['facets']['Event Type'] && 
												eventListData['facets']['Event Type'].map((event_type,index)=>(
													<option value={event_type.url} key={index}>{event_type.values.value}</option>
												))
											}
										</select>
									</div>
									<div className="form-group">
									<SortDropDown type='event' callSort={callSort} valueData={sortValue}/>
									</div>
									<div className="form-group">
										<Button onClick={()=>{_getEventData(); clearData()}}>Clear</Button>
									</div>

									

									
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-8 col-md-8 col-sm-12 col-12">

								<div className="listing_panel listing_panelnor" id="nor">
									<div className="row">
										{
											eventListData.data && eventListData.data.length > 0 && eventListData.data.map((event, ind) => 
												
													{
														return (ind + 1) % 3 == 0 ?
															<div className="col-lg-12 col-md-12 col-sm-12 col-12" key={ind}><EventContent value={event} hasInner={true} /> </div> :
															<div className="col-lg-6 col-md-6 col-sm-12 col-12" key={ind}><EventContent value={event} /></div>
													}
												
											)
										}

									</div>
								</div>

							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-12">

								<div className="reservationform">
									<div className="box">
										<div className="vdo">
											<video controls="" autoPlay="" muted="">
												<source src={require('../../assets/videos/cycling1.mp4')} type="video/mp4" />
											</video>
										</div>
									</div>
									<div className="box boxmail">
										<h3>Post your Event</h3>
										<div className="reviewright">
											<p>Do you want people to know of a cycling event in your city? Please send the details to <a href="mailto:carryon.enquiry@gmail.com">
												carryon.enquiry@gmail.com
	                               			</a>
											</p>
											<p>You can submit your form <Link to={RouteNames.eventForm}>here</Link> also. 
											</p>
										</div>
									</div>
								</div>
								<div className="sidepart follow">
									<h3 className="hd1"><span>follow us</span></h3>
									<ul className="sociallist">
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-instagram" aria-hidden="true"></i></span>
												<span className="name">instragram</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-facebook" aria-hidden="true"></i></span>
												<span className="name">facebook</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span>
												<span className="name">pinterest</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-youtube-play" aria-hidden="true"></i></span>
												<span className="name">youtube</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-rss" aria-hidden="true"></i></span>
												<span className="name">rss</span>
											</Link>
										</li>
									</ul>
								</div>

							</div>
						</div>
						<Pagination 
						activePage={activePage} 
						pageData={eventListData && eventListData.hasOwnProperty('pageData') ? eventListData.pageData : null }
						onPageChange={pageChangeHandler}
					/>
					</div>
				</div>
			</main>

			<Footer />
		</>
	)
}

const EventContent = (props) => {
	const { value } = props

	const dispatch = useDispatch();
    const addToFavourite = () => {
        if(value.is_already_favourite === '1'){
            return
        }
        dispatch(addingFavourite(value.content_id, 'event')).then(res => {
            if (!res.error) {

            }
        })
	}
	
	return (
		<div className={props.hasInner ? 'eventInner eventInnerSec' : 'eventInner'}>
			<div className="eventImg catimg">
				<Link to={`${RouteNames.eventDetails}${value.alias ? value.alias : ''}`}>
					<img src={value.field_photo ? value.field_photo : require('../../assets/images/event3.jpg')} alt="rproduct1" />
					{/* <span className="ovrly"></span>*/}
                    <Favourite content_id={value.content_id} type='buy' is_already_favourite={value.is_already_favourite} fullData={value}/> 
				</Link>
			</div>
			<div className="event-list-details">
				{/* <div className="iconDiv">
					<Link to="#" title="Share"><i className="fa fa-share-alt" aria-hidden="true"></i></Link>
					<Link to="#" title="Save" onClick={addToFavourite}><i className="fa fa-heart" aria-hidden="true"></i></Link>
				</div> */}
				<h4><i className="fa fa-map-marker" aria-hidden="true"></i> {value.field_country} </h4>
				<h2 className="notranslate"><Link to={`${RouteNames.eventDetails}${value.alias ? value.alias : ''}`}>{value.title}</Link></h2>
				<div className="taglist1">
					<ul>
						{
							value.field_keywords &&
							value.field_keywords.split(',').map((keyword, index) => (
								<li key={index}>{keyword}</li>
							))
						}
					</ul>
				</div>
				<div className="event-list-when">
					<span className="tribe-event-date-start">{value.field_event_start_date}</span>
				</div>
				<div className="event-list-description">
					<span dangerouslySetInnerHTML={{ __html: value.body }}></span>
					<Link className="link" to={{ pathname: value.field_urls }} target="_blank">
						{value.field_urls}
					</Link>
				</div>
				{
                        value.field_shop_icon &&  <CharactersticEvent field_shop_icon={value.field_shop_icon}/>
                    }
				
			</div>
		</div>
	)
}



const CharactersticEvent =(props)=>{
    const {field_shop_icon} = props;
    const [icons, setIcons] = useState([])

    useEffect(() => {
        if(Array.isArray(field_shop_icon)){
            setIcons(field_shop_icon)
        }else{
            setIcons(field_shop_icon.split(',')) 
        }
        
        return () => {
        }
	}, [field_shop_icon])
	
	return (<ul className="carcts-ul event-carcts-ul">
		{
			 icons.length > 0 && icons.map((icon, index)=>(
			<li>
			<Link to="#">
				<img src={icon} alt="shope"/>
			</Link>
		</li>
			))
		}
						
	</ul>)
}

const EventPostForm = (props) => {
	return (
		<>
			<div class="form-group">
				<input type="text" name="" id="event_name" placeholder="Event Name" />
			</div>
			<div class="form-group">
				<input type="text" name="" id="event_country" placeholder="Country" />
			</div>
			<div class="form-group">
				<input type="text" name="" id="event_city" placeholder="City" />
			</div>
			<div class="form-group">
				<input type="text" name="" id="event_dateyear" placeholder="Date and Year" />
			</div>
			<div class="form-group">
				<input type="text" name="" id="event_keyword" placeholder="Event keywords" />
			</div>
			<div class="form-group">
				<textarea placeholder="Description " id="event_description"></textarea>
			</div>
			<input type="hidden" name="category" id="eventcategory" value="Event Form" />
			<div class="recaptha">
				<div class="g-recaptcha" data-sitekey="6LfUx7cZAAAAABXwwVA_jkTu1GUBpDQfO9I9Y6lW"></div>
				<span class="gcaperror" style={{ color: 'red' }}>Invalid Captcha</span>
			</div>
			<div class="form-group">
				<input type="submit" name="" id="event_submit" />
			</div>
			<span id="eventsuccess" style={{ color: 'green' }}></span>
			<span id="eventerror" style={{ color: 'red' }}></span>
		</>
	)
}

export default EventList
