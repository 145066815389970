import React, { useEffect, useState } from 'react';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import MainStructure from '../MainStructure';
import SearchBar from '../../reuseables/SearchBar';
import { RouteNames } from '../../includes/route-names';
import { resetPassword, verifyForgotToken} from '../../stores/actions/auth-action'
import { callLoading } from '../../stores/actions/common-action'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'query-string';

const ResetPassword =(props)=>{
    const [is_loaded, setLoaded] = useState(false);
    const dispatch = useDispatch()
	const [resetPasswordForm, setResetPasswordForm] = useState({
        token:'',
        new_password: '',
        confirm_password: ''
	})

	const [form_error, setFormError] = useState({});
    const queryData = queryString.parse(window.location.search);
    
    useEffect(() => {
        if(queryData && queryData.token){
            verifyForgotToken({token: queryData.token}).then(res=>{
                if(res.error){
                    toast.error(res.msg,
						{
							position: toast.POSITION.TOP_RIGHT,
							autoClose:3000
                        });
                        props.history.push('/')
                        return
                }else{
                    setResetPasswordForm({...resetPasswordForm,['token']: queryData.token})
                }
            })
            
        }
        return () => {
            
        }
    }, [])
	
	const clearForm = () => {
        setResetPasswordForm({
            password: '',
            conf_password: ''
        })
    }

	const handleInput = (e) => {
        setResetPasswordForm({ ...resetPasswordForm, [e.target.name]: e.target.value })
	}
	
	const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        setFormError({ ...form_error, errors });
        let isValid = true;
        let formArr = Object.keys(resetPasswordForm).map(obj => ({ key: String(obj), value: resetPasswordForm[obj] }));
        if (formArr && formArr.length) {
            formArr.forEach(obj => {
                if (obj.value === '') {
                    if(obj.key ==='confirm_password'){
                        errors[obj.key] = `Confirm password field is required`;
                    }else{
                        errors[obj.key] = `Password field is required`;
                    }
                    setFormError({ ...form_error, errors });
                    isValid = false;
                }
            });

        }
        if(resetPasswordForm.confirm_password && resetPasswordForm.confirm_password !== resetPasswordForm.new_password){
            errors['confirm_password'] = 'Confirm password does not matched!'
            isValid = false;
        }
        if (isValid) {
            setFormError({})
            setLoaded(true);
            dispatch(callLoading(true))
            await resetPassword(resetPasswordForm).then( resp => {
                setLoaded(false);
                dispatch(callLoading(false))
                if (!resp.error) {
                    clearForm()
                    toast.success("Successfully update password!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    props.history.push('/')
                } else {
                    let errors = {};
                    errors['confirm_password'] = resp.msg
                    setFormError({ errors })
                }
            })
        }
    }
	
    return (
        <>
           <Header />
           <div class="banner innerbanner reset-passbanner">
                <img  src={require('../../assets/images/eventbanner.jpg')} alt="blog"/> 	
                <div class="bannercontent">
                    <div>
                        <h3><span>Reset </span> Password</h3> 
                    </div>
                </div>       	 
            </div> 
            <main class="site-main inner-main about-main"> 
			 <section class="reset_sec new-reset-sec">
			 	<div class="container">
                 <div className="row">
		
			 			<div class="logholder reset-password">
			        	<div>
			        		<div class="formdiv">
                                <form onSubmit={handleSubmit}>
			        			<div class="form-group">
			        				<input type="password" name="new_password" placeholder="Password" onChange={handleInput}/>
                                    {
                                                form_error['errors'] && form_error['errors']['new_password'] && (
                                                    <span className='require_error'>{form_error['errors']['new_password']}</span>
                                                )

                                            }
			        			</div>
			        			<div class="form-group">
			        				<input type="password" name="confirm_password" placeholder="Confirm Password" onChange={handleInput}/>
                                    {
                                                form_error['errors'] && form_error['errors']['confirm_password'] && (
                                                    <span className='require_error'>{form_error['errors']['confirm_password']}</span>
                                                )

                                            }
			        			</div>
			        			<div class="form-group">
			        				<input type="submit" name="" value="reset"/>
			        			</div>
                                </form>
			        		</div> 
			        	</div>
			        </div>
			 		
                </div>
			 	</div>
			 </section>
		</main>
           <Footer /> 
        </>
    )
}

export default ResetPassword
