import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Banner from '../../../../includes/Banner'
import SearchBar from '../../../../reuseables/SearchBar';
import { RouteNames } from '../../../../includes/route-names';
import { getUserFormDataList, callLoading } from '../../../../stores/actions/common-action';
import Pagination from '../../../../reuseables/Pagination';
import NoData from '../../../../reuseables/no-data';
import Favourite from '../../../../reuseables/Favourite';

const SmartSolution =(props)=>{
    const { country, url } = props;
	const [smartSolutionListData, setSmartSolutionListData] = useState( {} );
	
	const [ activePage, setActivePage ] = useState( 1 );
	

	const dispatch = useDispatch();

	const _getDestinationData = ( page, filterData ={} ) =>{
		//console.log( "PAGE", page );
		dispatch(callLoading(true))
		getUserFormDataList( url, page, filterData ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				setSmartSolutionListData( resp.data );
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}

	useEffect(()=>{
		
		_getDestinationData( 0 );
		
	},[]);

	const pageChangeHandler = ( page )=>{
        console.log("PP", page );
		_getDestinationData( page - 1 );
	}

	console.log("Data ========== Smart ", smartSolutionListData );

    return (
        <>
           <Banner extra_class bannerImage='smart-solutions.jpg' pageName='Smart Solution'>
                <div className="bannercontent">
                    <div>
                        <h3><span>Smart Solutions for </span> the bikers of today</h3> 
                    </div>
                </div> 
           </Banner>
		   <main className="site-main inner-main"> 
			<div className="eventSection">
				<div className="container">		
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">	
							<div className="listing_panel listing_panelnor smart-destination" id="nor">
								<div className="row">
									{
										smartSolutionListData.data && smartSolutionListData.data.length > 0 ?
										smartSolutionListData.data.map( (item, key) =>
											<Item key={key} data={item}/>
										) : <NoData/>
									}
									

								</div>
							</div>

						</div>

					</div>
					<Pagination 
						activePage={activePage} 
						pageData={smartSolutionListData && smartSolutionListData.hasOwnProperty('pageData') ? smartSolutionListData.pageData : null }
						onPageChange={pageChangeHandler}
					/>
				</div>
			</div>
		</main>
        </>
    )
}

const Item = (props) => {
    const { data } = props;
    if( !data ) return ( null );



    return (
        
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                    {
                        !data.field_video_embed && data.field_photo && data.field_photo.length > 0 &&
                        <><img src={ data.field_photo[0] } alt=""/>
                            <span className="ovrly"></span>
                            {
                                data.editable && parseInt( data.editable ) === 1 &&
                                <Link 
                                    className="edit" 
                                    title="Edit" 
                                    to={`${RouteNames.smart_solution_form}?content_id=${data.content_id}`}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link> 
                            }
                           {/*  {
                                data.editable && parseInt( data.editable ) === 1 ?
                                <Link 
                                    className="edit" 
                                    title="Edit" 
                                    to={`${RouteNames.smart_solution_form}?content_id=${data.content_id}`}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link> :
                                <Favourite content_id={data.content_id} type='solution' is_already_favourite={data.is_already_favourite} fullData={data}/>
                            } */}
                            
                        </>
                        
                    }

                    {
                        data.field_video_embed &&
                        <>
                            <iframe 
                                src={data.field_video_embed} 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen="">
                            </iframe>
                           
                        </>
                            
                    }
                                         
                    
                </div>
                <div className="texthl"> 
                    <div className="texthlleft"> 
                    <h4 className="notranslate"><Link to={`${RouteNames.smart_solution_detail}${data.alias}`}>{ data.company_name ? data.company_name : ''}</Link></h4>
                        <span className="cmpnyname">
                            { data.field_product_solution_name ? data.field_product_solution_name : ''}
                        </span> 
                        {
                            data.field_keywords &&
                            <>
                                <h5>Target Audience/end users </h5>
                                <p>{ data.field_keywords }</p>
                            </>
                        }
                        
                    </div> 
                    {
                        data.field_logo &&
                        <Link to="#" className="companyLogo">
                            <img src={ data.field_logo } alt=""/>
                        </Link>
                    }
                    
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{width:'100%'}}></span>
                    </span> 
                </div>
            </div>
        </div> 
    )
}

export default SmartSolution
