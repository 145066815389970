import React, { useEffect, useState, useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';

const PhotoGallary = ( props ) =>{
    const { photos } = props;
    const _gallery = useRef();
    const [photoData, setPhotoData] = useState([])
    
    useEffect(()=>{
        if(Array.isArray(photos)){
            setPhotoData(photos)
        }else{
            setPhotoData(photos.split(','))
        }
    },[photos]);

    const _InitGallery = () =>{
        return (
            <OwlCarousel 
                className="detail-gallery" 
                ref={_gallery}
                items={1}  
                lazyLoad={true}
                loop  
                autoplay={true}
                dots={true}  
                margin={0}
                >{
                    photoData.map( photo => 
                        <li className="item" key={photo}>
                            <a data-fancybox="gallery" data-caption="" className="gallery1">
                                <img src={photo} alt=""/>
                            </a>
                        </li> 
                    )
                }
                
            </OwlCarousel>
        );
    }

    return (
        <div className="box">
            {
                photos && 
                <>
                    <h3>Photo Gallery</h3>
                    <_InitGallery />
                </>
            }
        </div>
    )
}

export default PhotoGallary;
