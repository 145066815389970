import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import ListYourselfPopup from '../reuseables/list-yourself-popup';
import { useSelector, useDispatch } from 'react-redux';
import {openAuthModal} from '../stores/actions/auth-action';
import { clearSearch } from '../stores/actions/common-action';

const Banner = (props) => {
	const { children, bannerImage, pageName, shop_type, is_buy, extra_class } = props;
	const _history = useHistory();
	const _location = useLocation();
	const [ show, setShow ] = useState( false );
	const csrfToken  = useSelector(state => state.auth.csrf_token);
	const dispatch = useDispatch();
	const [showSearch, setShowSearch] = useState(false)
	const showModalHandler = (ev) =>{
		setShow( true );
	}

	const _breadCumbHandler = ( ev, to ) =>{
		ev.preventDefault();
		//console.log( to );
		dispatch( clearSearch( true ) );
		
		if( to )
			_history.push(to);
		else
			_history.push( _location.pathname );
	}

	const handleSearchToggle =(toogle)=>{
		setShowSearch(prevState =>!prevState)
		document.querySelector('.bannerform > form').style.display = !toogle?'block':'none';
	}

	return (
		<>
			<div className={ extra_class ? "banner innerbanner" : "banner innerbanner innerbannernew"}>
				<img src={require(`../assets/images/${bannerImage}`)} alt="blog" />
				 <span className="searchico" onClick={()=>handleSearchToggle(showSearch)}>
                    <img src={require('../assets/images/search1.png')} alt="expertIcon" />
                </span>
				{children}
			</div>
			{
				( ( pageName !== undefined ) || ( shop_type !== undefined ) ) &&
				<div className="breadcumb">
					<div className="container">
						<div className="holder clear">
							{ pageName &&
							<ul>
								<li><Link to="/">home</Link></li>
								{
									props.parentPageName && 
								<li><Link className="notranslate" onClick={(ev) => _breadCumbHandler(ev, props.parentRoute)} to={props.parentRoute}>{props.parentPageName}</Link></li>
								}
								
								<li className="active"><Link className="notranslate" onClick={(ev) => _breadCumbHandler(ev, null)} to="#"> {pageName}</Link></li>
							</ul>
							}
							{
								shop_type &&
								<div className="selectlisting">
									<select>
										<option>Shop Type</option>
										<option>All</option>
										<option>Individual</option>
										<option>Shop</option>
									</select>
								</div>
							}
							
							

						</div>
					</div>
				</div>
			}
			{
				!csrfToken && <Link className="expertBtn" to="#" onClick={()=>dispatch(openAuthModal('login'))}>List Yourself with CarryOn</Link>
			}
			
			{/* <ListYourselfPopup showExpert={show} handleModal={() => setShow( false ) }/> */}
		</>
	)
}

export default Banner
