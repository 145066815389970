import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSmartSolutionDetails, callLoading, getFormContent } from '../../stores/actions/common-action';
import Pagination from '../../reuseables/Pagination';
import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import Banner from '../../includes/Banner'
import SearchBar from '../../reuseables/SearchBar';
import { Link } from 'react-router-dom';
import EnqueryNow from '../../reuseables/enquery-now';
import Keywords from './components/keywords';
import Unique from './components/unique';
import ReviewForm from '../../reuseables/review-form';
import ReviewDetails from '../buy/components/review-details';
import { RouteNames } from '../../includes/route-names';
import ReviewListing from '../../reuseables/review-listing';
import PhotoGallary from '../buy/components/photo-gallery';
import Video from '../buy/components/video';
import Map from '../buy/components/map';
import queryString from 'query-string';

const SmartSolutionDetail = (props) => {

	const [show, setShow] = useState(false);
	const { alias } = useParams();
	const dispatch = useDispatch();
	const queryData = queryString.parse( window.location.search );
	//const [ details, setDetails ] = useState( [] );
	const [activePage, setActivePage] = useState(1);
	const [keywords, setKeywords] = useState('');
	const [locationData, setLocationData] = useState({});
	const smartSolutionDetails = useSelector(state => state.common.smartSolutionDetails);
	//const [smartSolutionDetails, setSmartSolutionDetails ] = useState({});
	const [isPreview, setIsPreview] = useState(false)
	const _openModalHandler = () => setShow(true);

	const _getDestinationData = (page, filterData = {}) => {

		dispatch(callLoading(true))
		dispatch(getSmartSolutionDetails(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;
				//setSmartSolutionDetails( resp.data );
				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}

	const searchSubmitHandler = (data) => {
		const { keywords, locationData } = data;
		console.log('loc======', locationData);
		let paramData = {};
		let queryData = {};
		paramData['alias'] = `/${alias}`;

		if (keywords) {
			paramData['body'] = keywords;
			queryData['body'] = keywords;
			setKeywords(keywords);
		}

		/*
		if (locationData) {
			paramData['field_country_value'] = locationData;
			queryData['field_country_value'] = locationData.location;
		}
		
		if( locationData && Object.keys(locationData).length > 0 ){
			paramData['lat'] = locationData.lat;
			paramData['long'] = locationData.long;
			queryData['lat'] = locationData.lat;
			queryData['long'] = locationData.long;
			if( locationData.city )
				paramData['field_city_value'] = locationData.city;
				queryData['field_city_value'] = locationData.city;
			if( locationData.country ){
				paramData['field_country_value'] = locationData.country;
				//setSearchCountry( locationData.country );
			}
		}
		*/

		var queryString = '';
		Object.entries(queryData).forEach((data, index) => {
			if (index == 0) {
				queryString += `?${data[0]}=${data[1]}`
			} else {
				queryString += `&${data[0]}=${data[1]}`
			}

		})

		props.history.push(RouteNames.smart_solution + queryString)
		//console.log('TEST', queryString);
		return
		//console.log( locationData );
		_getDestinationData(0, { ...paramData });
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		if(queryData && queryData.preview){
			dispatch(callLoading(true))
			setIsPreview(true)
			dispatch(getFormContent(`smartsolution/userdata?page=0&content_id=${alias}`,'fetch_smart_solution_details')).then(res=>{
				dispatch(callLoading(false))
			})
		}else{
			_getDestinationData(0, { alias: `/${alias}` });
		}
		
	}, [alias]);

	const pageChangeHandler = (page) => {
		_getDestinationData(page - 1, { field_city_value: `/${alias}` });
	}

	//console.log(smartSolutionDetails);

	return (
		<>
			<Header />
			<Banner 
				bannerImage='smart-solutions.jpg' 
				parentRoute={RouteNames.smart_solution} 
				parentPageName='Smart Solutions' 
				pageName={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].title}>
				<div className="bannercontent">
					<div>
						<h3><span>Smart Solutions for </span> the bikers of today</h3>
					</div>
				</div>
				{
					!isPreview && <SearchBar 
						is_expert_ask={true} 
						onSearchSubmit={searchSubmitHandler} 
						removeLocation={true}
						removeClass="fieldRemoved"
						/>
				}
				
			</Banner>

			<main className="site-main inner-main">
				<div className="detailsmain">
					<div className="container">
						<div className="detailsholder">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-12">
									<div className="detailsleft">

										{
											smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].title &&
											<>
												{
													smartSolutionDetails.data[0].field_logo &&
													<span className="titlelogo">
														<img src={smartSolutionDetails.data[0].field_logo} alt="" />
													</span>
												}

												<h2 className="notranslate">{smartSolutionDetails.data[0].title}</h2>
																								
											</>
										}
										{
											smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_address &&
											<ul className="address">
												<li>
													<img src={require('../../assets/images/addressico.png')} alt="" />
													<span>{smartSolutionDetails.data[0].field_address}</span>
												</li>
												<li>
													<img src={smartSolutionDetails.data[0].field_country_icon} alt="" />
													<span>{smartSolutionDetails.data[0].field_country || ''}</span>
												</li>
											</ul>
										}

										<div className="dtldiv">
											<ReviewDetails isPreview={isPreview} buyData={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0] ? smartSolutionDetails.data[0] : null} />
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data.field_brands &&
												<h3 className="brandname">
													<span className="ico">
														<img src={require('../../assets/images/brandico.png')} alt="" />
													</span>
													<span>Manufacturer/Brand Name:</span>
													{smartSolutionDetails.data.field_brands}
												</h3>
											}
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_email &&
												<h3 className="shopweb brandname">
													<span className="ico">
														<img src={require('../../assets/images/mail.png')} alt="" />
													</span>
													<span>Email:</span>
													<a className="" href={`mailto:${smartSolutionDetails.data[0].field_email}`}>
														{smartSolutionDetails.data[0].field_email}
													</a>
												</h3>
											}
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_phone &&
												<h3 className="shopweb brandname">
													<span className="ico">
														<img src={require('../../assets/images/phone.png')} alt="" />
													</span>
													<span>Phone:</span>
													<a className="" href={`tel:${smartSolutionDetails.data[0].field_phone}`}>
														{smartSolutionDetails.data[0].field_phone}
													</a>
												</h3>
											}

											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_urls &&
												<h3 className="shopweb brandname">
													<span className="ico">
														<img src={require('../../assets/images/url.png')} alt="" />
													</span>
													<span>URL:</span>
													<Link className="" to={{ pathname: smartSolutionDetails.data[0].field_urls }} target="_blank">
														{smartSolutionDetails.data[0].field_urls}
													</Link>
												</h3>
											}
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_product_solution_name &&
												<span className="proName">{ smartSolutionDetails.data[0].field_product_solution_name }</span>
											}
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_key_features &&
												<div className="brandav">
													<h3>Key Features:</h3>
													<ul className="cmn1">
														{ Array.isArray(smartSolutionDetails.data[0].field_key_features) ? smartSolutionDetails.data[0].field_key_features.map((key, index)=>(
															<li key={index} dangerouslySetInnerHTML={{__html: key}}></li>
														)):
														 smartSolutionDetails.data[0].field_key_features.split(',').map((key, index)=>(
														<li key={index} dangerouslySetInnerHTML={{__html: key}}></li>
														))	
														}
													</ul>
												</div>
											}
											
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].product_unique &&
												<div className="brandav">
												<h3>How is the Product Unique:</h3>
												<div className="taglist1">
													<ul>
														{
															Array.isArray(smartSolutionDetails.data[0].product_unique) ? smartSolutionDetails.data[0].product_unique.map((key, index)=>(
																<li key={index} dangerouslySetInnerHTML={{__html: key}}></li>
															)):
															smartSolutionDetails.data[0].product_unique && smartSolutionDetails.data[0].product_unique.split(',').filter(el=>el).map((key, index)=>(
															<li key={index} dangerouslySetInnerHTML={{__html: key}}></li>
															))
														}
														
													</ul>
												</div>
											</div>
											}
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_target_audience_end_users && 
												<div className="brandav">
													<h3>Target Audience/end users</h3>
													<div className="main">
														<p>{smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_target_audience_end_users}</p>

													</div>
												</div>
											}
											
											{
												smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_customer_review &&
												<div className="brandav">
												<h3>Customer Testimonial:</h3>
												<div className="main">
													<p dangerouslySetInnerHTML={{ __html: smartSolutionDetails.data[0].field_customer_review }}></p>
												</div>
											</div>
											}
											

										</div>


										<ReviewListing title="Review" contentId={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].content_id ? smartSolutionDetails.data[0].content_id : null} />
									</div>


								</div>
								<div className="col-lg-4 col-md-4 col-sm-4 col-12">
									<div className="reservationform">
										{
											smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_geofield &&
											<div className="map">
												<Map latLong={smartSolutionDetails.data[0].field_geofield} />
											</div>
										}
										{
											smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_keywords &&
											<Keywords
												keys={smartSolutionDetails.data[0].field_keywords}
												selectedKey={keywords}
												onselect={searchSubmitHandler}
											/>
										}
										{
											smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_photo &&
											<PhotoGallary photos={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_photo ? smartSolutionDetails.data[0].field_photo : null} />
										}
										
										<Video video={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].field_video_embed ? smartSolutionDetails.data[0].field_video_embed : null} />
										{
											!isPreview && <ReviewForm contentId={smartSolutionDetails.data && smartSolutionDetails.data.length > 0 && smartSolutionDetails.data[0].content_id ? smartSolutionDetails.data[0].content_id : null} />
										}
										
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</main>



			<Footer />
		</>
	)
}

export default SmartSolutionDetail
