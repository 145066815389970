import React, { useState } from 'react';
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import EnqueryNow from '../../reuseables/enquery-now';
import { RouteNames } from '../../includes/route-names';
import { useSelector, useDispatch } from 'react-redux';
import { openAuthModal } from '../../stores/actions/auth-action'

const MemberShip = (props) => {

    const [ show, setShow ] = useState( false );
    const _openModalHandler = () => setShow( true );

    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    const dispatch = useDispatch();

    return (
        <>
            <Header />
            <div className="banner innerbanner membership-banner">
                <img src={require('../../assets/images/mmbrbnr.jpg')} alt="blog" />
            </div>
            <main className="site-main inner-main ">
                <div className="membershipSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                <div className="tophead">
                                    <h2 className="title1">
                                        Carryon.bike Membership Plans
								</h2>
                                    <p>Upgrading membership from Basic to Pro or Premium is straightforward</p>
                                </div>
                                <div className="membershipPlans">
                                    <div className="planDtls">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                                                <div className="planDtlsInner">
                                                    <div className="top">
                                                        <h4>Basic</h4>
                                                        <h2>Free</h2>
                                                    </div>
                                                    <div className="bottom"> 
                                                        <Link className="subscribe" to="#" onClick={()=>dispatch(openAuthModal('resgistration'))}><i className="fa fa-sign-in" aria-hidden="true"></i>Register</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                                                <div className="planDtlsInner">
                                                    <div className="top">
                                                        <h4>Pro</h4>
                                                        <h2>US$ <b>3</b> per month Annual Plan Exclusive of taxes</h2>
                                                    </div>
                                                    <div className="bottom">
                                                        <ul>
                                                            <li> 1 listing on our website</li>
                                                            <li>  At least 1 Social Media Post</li>
                                                            <li>  Additional Posts at US $ 2 per month</li>
                                                            <li>  Insightful reports</li> 
                                                        </ul>
                                                        <Link className="subscribe" to="#" onClick={ _openModalHandler }><i className="fa fa-sign-in" aria-hidden="true"></i>Post Your Advertisement</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-sm-12 col-12">
                                                <div className="planDtlsInner">
                                                    <div className="top">
                                                        <h4>Premium</h4> 
                                                        <span className="newup">Upgrade</span>
                                                    </div>
                                                    <div className="bottom">
                                                        <ul>
                                                            <li>For advertisements, featured posts and other services please contact carryon.bike
                                                            </li>
                                                        </ul>


                                                        <Link className="subscribe" to={RouteNames.contactUs} ><i className="fa fa-sign-in" aria-hidden="true"></i>Contact us</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EnqueryNow showExpert={show} handleModal={() => setShow( false ) } title="Subscribe Now"/>
            </main>
            <Footer />
        </>
    )
}

export default MemberShip
