import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';
import Characterstic from '../../../reuseables/Characterstic';
import Favourite from '../../../reuseables/Favourite';

const Item = (props) => {
    const { data } = props;
    if( !data ) return ( null );



    return (
        
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                    {
                        !data.field_video_embed && data.field_photo && 
                        <Link to={`${RouteNames.smart_solution_detail}${data.alias}`}><img src={ data.field_photo.split(',')[0] } alt=""/>
                        <span className="ovrly"></span>
                        <Favourite content_id={data.content_id} type='solution' is_already_favourite={data.is_already_favourite} fullData={data}/>
                        </Link>
                        
                    }
                    {
                        data.field_video_embed &&
                        <>
                            <iframe 
                                src={data.field_video_embed} 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen="">
                            </iframe>
                            <Favourite content_id={data.content_id} type='solution' is_already_favourite={data.is_already_favourite} fullData={data}/>
                        </>
                            
                    }
                                         
                    
                </div>
                <div className="texthl"> 
                    <div className="texthlleft"> 
                    <h4 className="notranslate"><Link to={`${RouteNames.smart_solution_detail}${data.alias}`}>{ data.company_name ? data.company_name : ''}</Link></h4>
                        <span className="cmpnyname">
                            { data.field_product_solution_name ? data.field_product_solution_name : ''}
                        </span> 
                        {
                            data.field_keywords &&
                            <>
                                <h5>Target Audience/end users </h5>
                                <p>{ data.field_keywords }</p>
                            </>
                        }
                        
                    </div> 
                    {
                        data.field_logo &&
                        <Link to={RouteNames.smart_solution_detail} className="companyLogo">
                            <img src={ data.field_logo } alt=""/>
                        </Link>
                    }
                    
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{width:'100%'}}></span>
                    </span> 
                </div>
                {
                   data.field_shop_icon &&  <Characterstic field_shop_icon={data.field_shop_icon}/>
                }
            </div>
        </div> 
    )
}

export default Item;
