import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserFormDataList, callLoading, clearSearch } from '../../../../stores/actions/common-action';
import Pagination from '../../../../reuseables/Pagination';
import Banner from '../../../../includes/Banner'
import Footer from '../../../../includes/Footer'
import Header from '../../../../includes/Header'
import SearchBar from '../../../../reuseables/SearchBar';
import { RouteNames } from '../../../../includes/route-names';
import queryString from 'query-string';
import NoData from '../../../../reuseables/no-data';
import Favourite from '../../../../reuseables/Favourite';

const BikeExpert = ( props )=>{

    const { country, url } = props;
    const [ bikeExpertData, setBikeExpertData ] = useState( {} );
	const [ activePage, setActivePage ] = useState( 1 );
	const dispatch = useDispatch();
    const queryData = queryString.parse( window.location.search );
    
    const _getDestinationData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		getUserFormDataList( url, page, filterData ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				setBikeExpertData( resp.data );
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
	}

    useEffect(()=>{
        
        _getDestinationData(0);

	},[]);

	const pageChangeHandler = ( page )=>{
		_getDestinationData( page - 1 );
	}

	console.log(bikeExpertData)

    return (
        <>
            <Banner extra_class bannerImage='enthubanner.jpg' pageName='Bike Enthusiasts'>
            <div className="bannercontent">
				<div>
                    <h3>Meet the <span> Bike Enthusiasts</span></h3>
		    	</div>
	    	</div>
            
            </Banner>
            <main className="site-main inner-main ">  
			<div className="listing_panel listing_panelnor" id="nor">
				<div className="container"> 
					<div className="listing_holder">
						<div className="row">

							<div className="col-lg-12 col-md-12 col-12">
								<div className="listingtop clear">
								</div>
								<div className="listing_middle">
									
									<div className="row">
										{
											bikeExpertData.data && bikeExpertData.data.length > 0 ?  bikeExpertData.data.map( ( item, key ) =>
												<Item key={key} data={item}/>
											) : <NoData/>
										}
										
									</div>
									<Pagination 
										activePage={activePage} 
										pageData={bikeExpertData && bikeExpertData.hasOwnProperty('pageData') ? bikeExpertData.pageData : null }
										onPageChange={pageChangeHandler}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
        </>
    )
}

const STR_LEN = 60;

const Item = (props) => {
    const { data } = props;
    if( !data ) return ( null );

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                    {
                        data.field_photo && data.field_photo.length > 0 &&
                        <>
                            <img src={ data.field_photo[0] } alt=""/>
                            <span className="ovrly"></span>
                            {
                                data.editable && parseInt( data.editable ) === 1 &&
                                <Link 
                                    className="edit" 
                                    title="Edit" 
                                    to={`${RouteNames.bikeEnthusticForm}?content_id=${data.content_id}`}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link> 
                            }
                            {/* {
                                data.editable && parseInt( data.editable ) === 1 ?
                                <Link 
                                    className="edit" 
                                    title="Edit" 
                                    to={`${RouteNames.bikeEnthusticForm}?content_id=${data.content_id}`}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link> :
                                <Favourite content_id={data.nid} type='expert' is_already_favourite={data.is_already_favourite} fullData={data}/>
                            } */}
                            
                        </>
                        
                    }
                    
                </div>
                <div className="texthl"> 
                    <div className="texthlleft">
                        {
                            data.title &&
                            <h4 className="notranslate" ><Link to='#'>{ data.title }</Link></h4>
                        }
                        {
                            data.field_about &&
                            <div className="cmpnyname" dangerouslySetInnerHTML={{__html: data.field_about.length > STR_LEN ? data.field_about.substring(0, STR_LEN).concat('...') : data.field_about}}>
                            </div>
                        }
                         
                    </div>
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{width:'100%'}}></span>
                    </span> 
                </div>
            </div>
        </div> 
    )
}

export default BikeExpert
