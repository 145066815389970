import { GOOGLE_CLIENT_ID } from '../../constants/config';

const initAuth = () => {
    return window.gapi.auth2.init({
      //client_id: "1062433382390-26tjbfvt0d90edn4r55m3dmttl8ql3re.apps.googleusercontent.com", //paste your client ID here
      client_id: GOOGLE_CLIENT_ID,
      scope: "https://www.googleapis.com/auth/analytics.readonly",
    });
  };

export const checkSignedIn = () => {
    return new Promise((resolve, reject) => {
      initAuth() //calls the previous function
        .then(() => {
          const auth = window.gapi.auth2.getAuthInstance(); //returns the GoogleAuth object
          resolve(auth.isSignedIn.get()); //returns whether the current user is currently signed in
        })
        .catch((error) => {
          reject(error);
        });
    });
};

export const renderButton = () => {
    window.gapi.signin2.render("signin-button", {
      scope: "profile email",
      width: 240,
      height: 50,
      longtitle: true,
      theme: "dark",
      onsuccess: onSuccess,
      onfailure: onFailure,
    });
};
  
const onSuccess = (googleUser) => {
    console.log("Logged in as: " + googleUser.getBasicProfile().getName());
};
  
const onFailure = (error) => {
    console.error(error);
};