import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import Banner from '../../includes/Banner'
import SearchBar from '../../reuseables/SearchBar';
import EnqueryNow from '../../reuseables/enquery-now';
import { RouteNames } from '../../includes/route-names';
import TopFilter from './components/top-filter';
import Item from './components/item';
import { getSmartSolutionList, callLoading, clearSearch } from '../../stores/actions/common-action';
import Pagination from '../../reuseables/Pagination';
import NoData from '../../reuseables/no-data';
import SortDropDown from '../../reuseables/SortDropDown';

const SmartSolution = (props) => {
	const location = useLocation();
	const { body } = queryString.parse(location.search, true);
	const [show, setShow] = useState(false);
	const _openModalHandler = () => setShow(true);
	const smartSolutionListData = useSelector(state => state.common.smartSolutionData);
	const resetSearch = useSelector(state => state.common.resetSearch);
	const myCountry = useSelector(state => state.common.myCountry);
	const [activePage, setActivePage] = useState(1);
	const [keywords, setKeywords] = useState( body || '');
	const [locationData, setLocationData] = useState({});
	const [searchCountry, setSearchCountry] = useState(null);
	const [sortValue, setSortValue] = useState('')
	const [searchKeyData, setSearchKeyData] = useState({})
	const [queryDataValue, setQueryDataValue] = useState({})

	const dispatch = useDispatch();

	const _getDestinationData = (page, filterData = {}) => {

		dispatch(callLoading(true))
		dispatch(getSmartSolutionList(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;

				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}

	useEffect(() => {
		if ( keywords && !resetSearch ){
			setSortValue('search_api_relevance')
			_getDestinationData(0, {body: keywords,sort_by:'search_api_relevance' });
		}
			
		else {
			if( !resetSearch )
				_getDestinationData(0);
		}
			
		if (resetSearch) {
			clearData();
			dispatch(clearSearch(false));
		}
	}, [resetSearch, keywords]);

	const pageChangeHandler = (page) => {
		const { keywords, locationData } = searchKeyData;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if(sortValue){
            paramData['sort_by']= sortValue;
		}
		
		_getDestinationData(page - 1, { ...paramData });
	}

	const searchSubmitHandler = (data) => {
		setQueryDataValue({})
		setSearchKeyData(data)
		const { keywords, locationData } = data;
		let paramData = {};
		if (keywords) {
			paramData['body'] = keywords;
		}
		if (sortValue) {
			paramData['sort_by'] = sortValue;
		}

		if(Object.entries(locationData).length == 0 && keywords){
			paramData['sort_by']= 'search_api_relevance';
			setSortValue('search_api_relevance')
			_getDestinationData(0, { ...paramData });
		   }else{
				_getDestinationData(0, { ...paramData });
		   }

		//_getDestinationData(0, { ...paramData });
	}

	const clearData = () => {
		_getDestinationData(0);
		setKeywords(null);
		setSortValue()
		setSearchKeyData({})
		setQueryDataValue({})
	}

	const callSort = (value) => {
		setSortValue(value)
		let paramData = {};
		if (Object.keys(queryDataValue).length > 0) {
			const { body } = queryDataValue;
			if (body)
				paramData['body'] = body;
			if (value) {
				paramData['sort_by'] = value
			}
			_getDestinationData(0, { ...paramData });

		} else if (Object.keys(searchKeyData).length > 0) {
			const { keywords } = searchKeyData;
			if (keywords)
				paramData['body'] = keywords;
			if (value) {
				paramData['sort_by'] = value
			}
			_getDestinationData(0, { ...paramData });
		} else {
			if (myCountry) {
				if (value) {
					_getDestinationData(0, { sort_by: value });
				} else {
					_getDestinationData(0);
				}

			} else {
				if (value) {
					_getDestinationData(0, { sort_by: value });
				} else {
					_getDestinationData(0);
				}

			}

		}

	}

	//console.log("Data ========== ", smartSolutionListData );

	return (
		<>
			<Header />
			<Banner bannerImage='smart-solutions.jpg' pageName='Smart Solutions'>
				<div className="bannercontent">
					<div>
						<h3><span>Smart Solutions for </span> the bikers of today</h3>
					</div>
				</div>
				<SearchBar
					is_expert_ask={true}
					onSearchSubmit={searchSubmitHandler}
					removeLocation={true}
					removeClass="fieldRemoved"
					keyword={keywords}
				/>
			</Banner>
			<main className="site-main inner-main">
				<div className="listingtop clear">
					<div className="container">
						<form>
							<SortDropDown type='smart_solution' callSort={callSort} valueData={sortValue} />
						</form>
					</div>

				</div>
				<div className="eventSection top-pad-less">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">

								<div className="listing_panel listing_panelnor smart-destination" id="nor">
									<div className="row">
										{
											smartSolutionListData.data && smartSolutionListData.data.length > 0 ?
												smartSolutionListData.data.map((item, key) =>
													<Item key={key} data={item} />
												) : <NoData />
										}


									</div>
								</div>

							</div>

						</div>
						<Pagination
							activePage={activePage}
							pageData={smartSolutionListData && smartSolutionListData.hasOwnProperty('pageData') ? smartSolutionListData.pageData : null}
							onPageChange={pageChangeHandler}
						/>
					</div>
				</div>
			</main>
			<Footer />

		</>
	)
}

export default SmartSolution
