import React from 'react';
import { FacebookShareButton, FacebookIcon, WhatsappIcon, WhatsappShareButton, PinterestShareButton, PinterestIcon, TwitterIcon, TwitterShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';
import { APP_BASE_URL } from '../constants/config';
const SocialShare = (props) => {

    const { fb, whatsapp, linkedin, pinterest, tw, shareUrl } = props;
    const ICON_SIZE = 36;

    return (
        <>
            { fb && <FacebookShareButton 
                    url={`${APP_BASE_URL}${shareUrl}`}
                    quote={"CampersTribe - World is yours to explore"}
                    hashtag="#camperstribe"
                    className='_fbCustom'>
                    <FacebookIcon size={ICON_SIZE} />
                </FacebookShareButton>
            }

            { whatsapp && <WhatsappShareButton
                    title="CarryOn"
                    url={`${APP_BASE_URL}${shareUrl}`}
                    separator=""
                >
                    <WhatsappIcon size={ICON_SIZE}/>
                </WhatsappShareButton>
            }
            
            { linkedin && <LinkedinShareButton
                    title="check it out"
                    url={`${APP_BASE_URL}${shareUrl}`}
                >
                    <LinkedinIcon size={ICON_SIZE}/>
                </LinkedinShareButton>
            }

            { pinterest && <PinterestShareButton
                    url={`${APP_BASE_URL}${shareUrl}`}
                    title="Check this"
                    media={"https://carryon.bike"}
                >
                    <PinterestIcon size={ICON_SIZE}/>
                </PinterestShareButton>
            }

            { tw && <TwitterShareButton
                    title="Carry on Bike"
                    via="Carryon"
                    url={`${APP_BASE_URL}${shareUrl}`}
                >
                    <TwitterIcon size={ICON_SIZE}/>
                </TwitterShareButton>

            }
        </>
    )
}

export default SocialShare;
