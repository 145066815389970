import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

const Characterstic =(props)=>{
    const {field_shop_icon} = props;
    const [icons, setIcons] = useState([])

    useEffect(() => {
        if(Array.isArray(field_shop_icon)){
            setIcons(field_shop_icon)
        }else{
            setIcons(field_shop_icon.split(',')) 
        }
        
        return () => {
        }
    }, [field_shop_icon])


    return (
            <ul className="carcts-ul">
                {
                    icons.length > 0 && icons.map((icon, index)=>(
                        <li key={index}>
                        <Link to="#">
                            <img src={icon} alt="shope"/>
                        </Link>
                    </li>
                    ))
                }
                    
                </ul>
    )
}

export default Characterstic
