import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../../constants/config';
import {contactUs } from '../../../stores/actions/auth-action'
import { useSelector, useDispatch } from 'react-redux';
import { callLoading } from '../../../stores/actions/common-action'
import { toast } from 'react-toastify';


const GetInTouch = (props) => {
    const dispatch = useDispatch();
    const [form_error, setFormError] = useState({});
    const requiredFiled = ['name', 'email', 'message', 'captcha'];
    const formInit = {name:'', email:'', phone_no:'', message:'', captcha:''}
    const [form, setForm] = useState(formInit)
    const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;

    const handleInput = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
        if (e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === e.target.name)) {
            let errors = {};
            Object.entries(form_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
            setFormError({ ...form_error, errors });
        }
        if (e.target.value === '' && requiredFiled.indexOf(e.target.name) !== -1) {
            console.log(form_error)
            let errors = {};
            errors[e.target.name] = `This is required field`;
            if (form_error.errors && Object.entries(form_error.errors).length) {
                Object.entries(form_error.errors).map(obj => errors[obj[0]] = obj[1]);
            }
            setFormError({ ...form_error, errors });
        }
    }

    const clearForm =()=>{
        setForm(formInit)
        window.grecaptcha.reset();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(form)
        let errors = {};
        setFormError({ ...form_error, errors });
        let isValid = true;
        let formArr = Object.keys(form).map(obj => ({ key: String(obj), value: form[obj] }));
        if (formArr && formArr.length) {
          formArr.forEach(obj => {
            if (obj.value === '' && requiredFiled.indexOf(obj.key)!== -1 ) {
              if(obj.key =='captcha'){
                errors[obj.key] = `Please select captch`;
                isValid = false;
              }else{
                errors[obj.key] = `This is required field`;
                setFormError({ ...form_error, errors });
                isValid = false;            
              }
    
            }
            if (obj.key === 'email' && obj.value !== '' && !emailRegex.test(obj.value)) {
              errors[obj.key] = `Please enter valid email`;
              setFormError({ ...form_error, errors });
              isValid = false;
            }
          });
    
        }
        if (isValid) {
           let formdata = {};
           formArr.forEach(field=>{
             if(field.key !== 'captcha'){
               formdata[field.key] = field.value;
             }
             
           })
    
          dispatch(callLoading(true))
          setFormError({})
           await contactUs(formdata).then(res=>{
            dispatch(callLoading(false))
            console.log(res, '99856')
            if(!res.error){
                clearForm()
                toast.success('Successfully send',
              {
                  position: toast.POSITION.TOP_RIGHT
              });
            }
        });
        }
        
      }


      const onChangeCaptcha =(value)=> {
        setForm({ ...form, ['captcha']: value })
       if(value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj=> obj[0] === 'captcha')){
          let errors = {};
         Object.entries(form_error.errors).filter(obj=> obj[0] !== 'captcha').map(obj=>errors[obj[0]] = obj[1]);
         setFormError({ ...form_error, errors });
        }
    }


    return (
        <Form onSubmit={handleSubmit} id="contact_us"> 
            
            <Form.Group> 
                <Form.Control 
                    className='mandatory'
                    type="text" 
                    placeholder="Name" 
                    name="name"
                    value={form.name}
                    onChange={handleInput}
                />
                {
                      form_error['errors'] && form_error['errors']['name'] && (
                        <span className='form_error'>{form_error['errors']['name']}</span>
                      )

                    }
            </Form.Group>
            <Form.Group> 
                <Form.Control 
                    className='mandatory'
                    type="email" 
                    name="email"
                    value={form.email}
                    placeholder="Email"
                    onChange={handleInput}
                />
                 {
                      form_error['errors'] && form_error['errors']['email'] && (
                        <span className='form_error'>{form_error['errors']['email']}</span>
                      )

                    }
            </Form.Group>
            <Form.Group> 
                <Form.Control 
                    type="number" 
                    name="phone_no"
                    value={form.phone_no}
                    placeholder="Phone No" 
                    onChange={handleInput}
                />
            </Form.Group> 
            <Form.Group>
                <Form.Control 
                    className='mandatory'
                    name="message"
                    as="textarea" 
                    value={form.message}
                    rows="3" 
                    placeholder="Message" 
                    onChange={handleInput}
                />
                 {
                      form_error['errors'] && form_error['errors']['message'] && (
                        <span className='form_error'>{form_error['errors']['message']}</span>
                      )

                    }
            </Form.Group>
            <Form.Group>
                <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={onChangeCaptcha}
                />
                {
                    form_error['errors'] && form_error['errors']['captcha'] && (
                      <span className='form_error' style={{top:10 }}>{form_error['errors']['captcha']}</span>
                    )

                  }
            </Form.Group>
            <Form.Group className="buttonOuter"> 
                <Button 
                    type="submit"
                >
                    Submit
                </Button>  
            </Form.Group>
        </Form>
    );
}

export default GetInTouch;
