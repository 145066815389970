import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch} from 'react-redux';
import MainQuery from './main-query';

const UserSessionWise = (props) => {
    const { noOfDays, slug } = props;
    const [reportData, setReportData] = useState([]);  

    const displayResults = (response) => {
        console.log( response );
        
        const queryResult = response.result.reports[0].data.rows;
            
            let newReportData = [];
            queryResult.forEach((row, idx) => {
            if ( row.dimensions[0] === slug ) {
              
                let tempObj = {
                    path: row.dimensions[0],
                    views: row.metrics[0].values[1],
                };

            newReportData.push(tempObj);
        }
        });
        setReportData(newReportData);      
    };

    useEffect(() => {
        
        const request = {
          
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            metrics: [ "ga:pageviews", "ga:sessions" ],
            dimensions: [ "ga:pagePath", "ga:date" ],
            filter: "ga:pagePath!@localhost/"
        
        };

        setTimeout(()=>{
         // MainQuery( request ).then( resp => displayResults( resp ) ).catch( error => console.error(error));
        }, 600);

      }, []);

    return (
        <div className="col-md-3 col-lg-3 col-sm-12 col-12">
            <div className="planDtlsInner">
                <div className="top"> 
                    <div>
                        <h4>Session</h4> 
                        {reportData && reportData.map((row, id) => (
                            <h2 key={id}>{row.views}</h2>
                            ))
                        }
                        
                        {
                            !reportData.length &&
                            <h2>
                                5
                            </h2>
                            }
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default UserSessionWise
