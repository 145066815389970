import React, { useState, useEffect } from "react";

const Report = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const queryReport = () => {//(1)
      window.gapi.client
        .request({
          path: "/v4/reports:batchGet",
          root: "https://analyticsreporting.googleapis.com/",
          method: "POST",
          body: {
            reportRequests: [
              {
                //viewId: "231232830", //enter your view ID here
                viewId: "231568930",
                dateRanges: [
                  {
                    startDate: "10daysAgo",
                    endDate: "today",
                  },
                ],
                metrics: [
                  {
                    expression: "ga:users",
                  },
                ],
                dimensions: [
                  {
                    name: "ga:date",
                  },
                ],
              },
            ],
          },
        })
        .then(displayResults, console.error.bind(console));
    };

    const displayResults = (response) => {//(2)
        console.log( response );
        /*
        if( !response.code ){
          for( let i = 0, report; report = response.result.reports[ i ]; ++i ){
            if (report.data.rows && report.data.rows.length) {
              var table = ['<table>'];

              // Put headers in table.
              table.push('<tr><th>', report.columnHeader.dimensions.join('</th><th>'), '</th>');
              table.push('<th>Date range #</th>');

              for (let i=0, header; header = report.columnHeader.metricHeader.metricHeaderEntries[i]; ++i) {
                table.push('<th>', header.name, '</th>');
              }

              table.push('</tr>');

              // Put cells in table.
              for (var rowIndex=0, row; row = report.data.rows[rowIndex]; ++rowIndex) {
                for(var dateRangeIndex=0, dateRange; dateRange = row.metrics[dateRangeIndex]; ++dateRangeIndex) {
                  // Put dimension values
                  table.push('<tr><td>', row.dimensions.join('</td><td>'), '</td>');
                  // Put metric values for the current date range
                  table.push('<td>', dateRangeIndex, '</td><td>', dateRange.values.join('</td><td>'), '</td></tr>');
                }
              }
              table.push('</table>');

              setData( table.join('') );
            }else{
              setData( "<p>No rows found.</p>" );
            }
          }

        }
        */
      
        const queryResult = response.result.reports[0].data.rows;
        const result = queryResult.map((row) => {
        const dateSting = row.dimensions[0];
        const formattedDate = `${dateSting.substring(0, 4)}
        -${dateSting.substring(4, 6)}-${dateSting.substring(6, 8)}`;
        return {
          date: formattedDate,
          visits: row.metrics[0].values[0],
        };
      });
      setData(result);

      
    
    };

    queryReport();
  }, []);
  // return (
  //   <p>{data}</p>
  // )
  return data.map((row) => (
    <div key={row.date}>{`${row.date}: ${row.visits} visits`}</div>//(3)
  ));
};

export default Report;