import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import ReviewForm from '../../reuseables/review-form';
import ReviewListing from '../../reuseables/review-listing';
import ReviewDetails from '../buy/components/review-details';
import Keywords from '../buy/components/keywords';
import PhotoGallary from '../buy/components/photo-gallery';
import Video from '../buy/components/video';
import Map from '../buy/components/map';
import { getBikeExpertDetails, callLoading, getFormContent } from '../../stores/actions/common-action';
import { RouteNames } from '../../includes/route-names';
import queryString from 'query-string';

const BikeEnthisticDetail =(props)=>{

    const { alias } = useParams();
	const dispatch = useDispatch();
	const queryData = queryString.parse( window.location.search );
	const [ activePage, setActivePage ] = useState( 1 );
	const [ keywords, setKeywords ] = useState('');
    const bikeExpertDetailsData = useSelector( state => state.common.bikeExpertDetailData );
	const [isPreview, setIsPreview] = useState(false)

	const _getexpertData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getBikeExpertDetails( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
    }
    
    const searchSubmitHandler = ( data ) =>{
        const { keywords, locationData } = data;
        let paramData = {};
        let queryData ={};
        paramData['alias'] = `/${alias}`;
		//console.log( locationData );
		if( keywords ){
			paramData['body'] = keywords;
			queryData['body'] = keywords;
			setKeywords( keywords );
		}
		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}

		var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
		})
		props.history.push(RouteNames.bikeEnthusticList + queryString)
		console.log(queryString );

    }

    useEffect(() => {
		window.scrollTo(0, 0);
		if(queryData && queryData.preview){
			setIsPreview(true)
			dispatch(callLoading(true))
			dispatch(getFormContent(`bike-expert/userdata?page=0&content_id=${alias}`,'fetch_expert_details')).then(res=>{
				dispatch(callLoading(false))
			})
		}else{
			_getexpertData( 0, { alias: `/${alias}` });
		}
		
	}, [alias]);

    return (
		<>
			<Header />
			<Banner bannerImage='buybanner.jpg' parentRoute={RouteNames.bikeEnthusticList} parentPageName='Bike Enthusiasts' pageName={bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 ?bikeExpertDetailsData.data[0].title :''}>
				{
					!isPreview && <SearchBar onSearchSubmit={searchSubmitHandler} />
				}
				
			</Banner>
            <main className="site-main inner-main"> 
				<div className="detailsmain">
					<div className="container">
						<div className="detailsholder">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-12">
									<div className="detailsleft">
									{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].title &&
												<>
												{
													bikeExpertDetailsData.data[0].field_logo && 
													<span className="titlelogo titlelogo1">
														<img src={bikeExpertDetailsData.data[0].field_logo}  alt=""/>
													</span>
												}
													
													<h2 className="notranslate" >{ bikeExpertDetailsData.data[0].title }</h2>
												</>
											}
											{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_address && 
												<ul className="address">
													<li>
														<img src={require('../../assets/images/addressico.png')} alt="" />
														<span>{ bikeExpertDetailsData.data[0].field_address }</span>
													</li>
													<li>
														<img src={bikeExpertDetailsData.data[0].field_country_icon} alt="" />
														<span>{ bikeExpertDetailsData.data[0].field_country || '' }</span>
													</li>
												</ul>
											}
											
										<div className="dtldiv">
											<ReviewDetails isPreview={isPreview}
												buyData={ bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0] ? bikeExpertDetailsData.data[0] : null }/>									 
										</div>

										<div className="brandav">
											<h3>Message to the community</h3>
											<div className="main"> 
										<p><strong dangerouslySetInnerHTML={{__html:bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0] ? bikeExpertDetailsData.data[0]['body'] : null}}></strong> 
												</p>
										</div>
										</div>
										<div className="brandav">
											<h3>About</h3>
											<div className="main" dangerouslySetInnerHTML={{__html:(bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0] ? bikeExpertDetailsData.data[0]['field_about'] : null)}}>
												
										</div>
										</div>
										<div className="brandav">
											{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_urls && 
												<>
												<h3> 
												<span>URLS</span></h3>
													<div className="main"> 
														<p><Link className="" to={{pathname:bikeExpertDetailsData.data[0].field_urls}} target="_blank">
															{ bikeExpertDetailsData.data[0].field_urls }
														</Link>
														</p>
														
												</div>
												</>
											}
											
										</div>
										<div className="dtldiv">
											<ReviewListing 
												contentId={
													bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].content_id
													? bikeExpertDetailsData.data[0].content_id : null
											}/>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-4 col-12">
									<div className="reservationform"> 
									{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_geofield &&
												<div className="map">
													<Map latLong={ bikeExpertDetailsData.data[0].field_geofield }/>
												</div>
											}
										{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_keywords &&
												<Keywords 
													keys={ bikeExpertDetailsData.data[0].field_keywords} 
													selectedKey={keywords}
													onselect={searchSubmitHandler}
												/>
											}
											{
												bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_photo &&
												<PhotoGallary photos={ bikeExpertDetailsData.data[0].field_photo ? bikeExpertDetailsData.data[0].field_photo : null}/>
											}
										
										<Video video={ bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].field_video_embed ? bikeExpertDetailsData.data[0].field_video_embed : null }/>
										{
											!isPreview &&
											<ReviewForm contentId={bikeExpertDetailsData.data && bikeExpertDetailsData.data.length > 0 && bikeExpertDetailsData.data[0].content_id ? bikeExpertDetailsData.data[0].content_id : null} /> 
										}
										
									</div>
								</div>
							</div>
						</div>  
					</div>
				</div>
			</main>
			<Footer />
		</>
    )
}

export default BikeEnthisticDetail
