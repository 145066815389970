import React from 'react'

const DiscoverBikeRoute = (props) => {
    return (
        <section className="route_sec animation-element">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Discover Bike Routes
						</h2> 
						<p>And Create the Casual Biking Culture </p>
					</div>
					<div className="route_panel">
						<div className="row">
							<div className="col-md-6 col-lg-6 col-sm-5 col-12 text-center">
								<div className="routeimg"> 
									 	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d128083.92971862441!2d10.645033905738329!3d59.89378064086971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e61f267f039%3A0x7e92605fd3231e9a!2sOslo%2C%20Norway!5e0!3m2!1sen!2sin!4v1594299598202!5m2!1sen!2sin" llowfullscreen="" aria-hidden="false" tabIndex="0"></iframe> 
									 	<a className="btn low" href="#">high traffic</a>
									 	<a className="btn high" href="#">low traffic</a>
								</div>
							</div>
							<div className="col-md-6 col-lg-6 col-sm-7 col-12">
								<ul className="routelist">
									<li>
										<span className="ico">
											<img src="images/routeico1.png" alt=""/>
										</span>
										<span className="text">Low-Traffic Scenic Bike Routes</span>
									</li>
									<li>
										<span className="ico">
											<img src="images/routeico2.png" alt=""/>
										</span>
										<span className="text">Unfamiliar Side Roads to your workplace</span>
									</li>
									<li>
										<span className="ico">
											<img src="images/routeico3.png" alt=""/>
										</span>
										<span className="text">Riding Around the Town with family and friends</span>
									</li>
									<li>
										<span className="ico">
											<img src="images/routeico4.png" alt=""/>
										</span>
										<span className="text">Turn Your Casual Bike Ride into a Great Workout</span>
									</li>
									<li>
										<span className="ico">
											<img src="images/routeico5.png" alt=""/>
										</span>
										<span className="text">Collaborate and Discuss Annual weekend Bike Tips. </span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
    )
}

export default DiscoverBikeRoute;
