import React, { useState, useRef, useEffect } from 'react'
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import { RouteNames } from '../../includes/route-names';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfileImage, userLogout, updateUserProfile } from '../../stores/actions/auth-action';
import { submitForm, callLoading } from '../../stores/actions/common-action';
import { toast } from 'react-toastify';

const MyProfile = (props) => {
	const roles = useSelector(state => state.common.roles);
	const interest_master = useSelector(state => state.common.interest_master);
	const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
	const phonenoRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
	const dispatch = useDispatch();
	const csrfToken = useSelector(state => state.auth.csrf_token);
	const userData = useSelector(state => state.auth.user);
	const passwordRef = useRef();
	const [showDropDown, setShowDropDown] = useState(false)
	const [showRoleDropDown, setShowRoleDropDown] = useState(false)
	const dropDownRef = useRef();
	const dropDownRef1 = useRef();
	const [userInterestMaster, setUserInterestMaster] = useState([])
	const requireFiled = ['field_members_for', 'field_full_name', 'mail', 'pass'];
	const [form_error, setFormError] = useState({});

	const [field_user_interest_value, setfield_user_interest_value] = useState(userData !=null ? (userData.interests && Object.entries(userData.interests).length ?Object.entries(userData.interests).map(obj=>({value:obj[0], label: obj[1]})):null):null)
	const [selectedLabel, setSelectdLabel] = useState(userData !=null ? (userData.interests && Object.entries(userData.interests).length ?Object.entries(userData.interests).map(obj=> obj[1]):null):null)
	const [selectedRoleLabel, setSelectdRoleLabel] = useState([])
	const [myProfileForm, setMyProfileForm] = useState({
		field_members_for: userData !=null ? (userData.roles && userData.roles.length > 0 ? userData.roles : []):[],
		field_full_name: userData !=null ? userData.name : '',
		field_user_interest:  userData !=null ? (userData.interests && Object.entries(userData.interests).length ?Object.entries(userData.interests).map(obj=>obj[0]):null):null ,
		field_expertise: userData !=null ? (userData.expertise && Object.entries(userData.expertise).length ? Object.entries(userData.expertise)[0][0]:'' ):'',
		field_user_interest_misc: '',
	});
	const [password_error, setPasswordError] = useState({});
	const [chanePasswordForm, setChangePasswordForm] = useState({
		current_password: '',
		password: '',
		confirm_password: ''
	})

	const [activeTab, setActiveTab] = useState(0);

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}

	const query = useQuery();

	useEffect(() => {
		setSelectdRoleLabel(roles.filter(obj=> userData.roles.indexOf(obj.id) != -1).map(obj =>obj.label))
		window.scrollTo(0, 0);
		if (query && query.get("tab") && query.get("tab") == 3) {
			setActiveTab('3')
			passwordRef.current.focus()
		}
		const interests = interest_master.filter(obj => obj.field_taxonomy_type === "user_interest").map(obj => ({ value: obj.tid, label: obj.name }))
		setUserInterestMaster(interests)
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		}
		
	}, [interest_master])

	const handleClickOutside = event => {
		if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
		  setShowDropDown(false)
		}
		if (dropDownRef1.current && !dropDownRef1.current.contains(event.target)) {
			setShowRoleDropDown(false)
		  }
	  };


	const logOut = (e) => {
		dispatch(userLogout({ token: csrfToken })).then(res => {
			props.history.push('/');
			if (!res.error) {
				localStorage.removeItem('user');
				localStorage.removeItem('csrf_token');
				toast.success('Successfully logout',
					{
						position: toast.POSITION.TOP_RIGHT
					});
			}
		})
	}

	const clearPasswordForm =()=>{
		setChangePasswordForm({
			current_password: '',
			password: '',
			confirm_password: ''
		})
	}

	const handleInput = (e) => {
		setMyProfileForm({ ...myProfileForm, [e.target.name]: e.target.value })
		if (e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === e.target.name)) {
			let errors = {};
			Object.entries(form_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
			setFormError({ ...form_error, errors });
		}

		if (e.target.value === '' && requireFiled.indexOf(e.target.name) !== -1) {
			let errors = {};
			errors[e.target.name] = `This is required field`;
			if(form_error.errors && Object.entries(form_error.errors).length){
				Object.entries(form_error.errors).map(obj=>errors[obj[0]] = obj[1]);
			  }
			setFormError({ ...form_error, errors });
		}
	}

	const handlePasswordInput = (e) => {
		setChangePasswordForm({ ...chanePasswordForm, [e.target.name]: e.target.value })
		if (e.target.value && password_error.errors && Object.entries(password_error.errors).length > 0 && Object.entries(password_error.errors).find(obj => obj[0] === e.target.name)) {
			let errors = {};
			Object.entries(password_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
			setPasswordError({ ...password_error, errors });
		}

	}

	const handleCheckBox =(e)=>{
		const labelValue = userInterestMaster.find(obj=>obj.value == e.target.value)
		if(e.target.checked){
			if(myProfileForm.field_user_interest && myProfileForm.field_user_interest.length){
				setMyProfileForm({ ...myProfileForm, ['field_user_interest']: myProfileForm.field_user_interest.concat(e.target.value) });
				setSelectdLabel(selectedLabel.concat(labelValue.label))

			}else{
				setMyProfileForm({ ...myProfileForm, ['field_user_interest']: [e.target.value] });
				setSelectdLabel([labelValue.label])				
			}

		}else{
			setMyProfileForm({ ...myProfileForm, ['field_user_interest']: myProfileForm.field_user_interest.filter(obj => obj != e.target.value) });
		  setSelectdLabel(selectedLabel.filter(obj=> obj != labelValue.label))
		}
	  }

	  const handleRoleCheckBox =(e)=>{
		const labelValue = roles.find(obj=>obj.id == e.target.value)
		if(e.target.checked){
			if(myProfileForm.field_members_for && myProfileForm.field_members_for.length){
				setMyProfileForm({ ...myProfileForm, ['field_members_for']: myProfileForm.field_members_for.concat(e.target.value) });
				setSelectdRoleLabel(selectedRoleLabel.concat(labelValue.label))

			}else{
				setMyProfileForm({ ...myProfileForm, ['field_members_for']: [e.target.value] });
				setSelectdRoleLabel([labelValue.label])				
			}

		}else{
			setMyProfileForm({ ...myProfileForm, ['field_members_for']: myProfileForm.field_members_for.filter(obj => obj != e.target.value) });
			setSelectdRoleLabel(selectedRoleLabel.filter(obj=> obj != labelValue.label))
		}
	  }

	const handleSubmit = async (e) => {
		e.preventDefault();
		let errors = {};
		console.log(myProfileForm.field_members_for)
		setFormError({ ...form_error, errors });
		let isValid = true;
		let formArr = Object.keys(myProfileForm).map(obj => ({ key: String(obj), value: myProfileForm[obj] }));
		if (formArr && formArr.length) {
			formArr.forEach(obj => {
				if (obj.value === '' && requireFiled.indexOf(obj.key) !== -1) {
					errors[obj.key] = `This is required field`;
					setFormError({ ...form_error, errors });
					isValid = false;
				}
				if (obj.key === 'mail' && obj.value !== '' && !emailRegex.test(obj.value)) {
					errors[obj.key] = `Please enter valid email`;
					setFormError({ ...form_error, errors });
					isValid = false;
				}
			});

		}
		if (isValid) {
			dispatch(callLoading(true))
			setFormError({})
			await dispatch(updateUserProfile(myProfileForm)).then(resp => {
				console.log(resp)
				dispatch(callLoading(false))
				setUserDataToStore(resp);
				if (!resp.error) {
					toast.success('Successfully update',
						{
							position: toast.POSITION.TOP_RIGHT
						});
				} else {
					toast.error(resp.msg,
						{
							position: toast.POSITION.TOP_RIGHT
						});
				}

			});
		}

	}

	const handlePasswordSubmit = async (e) => {
		e.preventDefault();
		let errors = {};
		setFormError({ ...password_error, errors });
		let isValid = true;
		let formArr = Object.keys(chanePasswordForm).map(obj => ({ key: String(obj), value: chanePasswordForm[obj] }));
		if (formArr && formArr.length) {
			formArr.forEach(obj => {
				if (obj.value === '') {
					let fieldName = obj.key.split('_')
					errors[obj.key] = `${fieldName[0].charAt(0).toUpperCase() + fieldName[0].slice(1)}  ${fieldName[1] ? fieldName[1] : ''} ${fieldName[2] ? fieldName[2] : ''} field is required`;
					setPasswordError({ ...password_error, errors });
					isValid = false;
				}else if(chanePasswordForm.confirm_password !== chanePasswordForm.password){
					errors['confirm_password'] = 'Confirm password not match!'
				}
			});

		}
		if (isValid) {
			dispatch(callLoading(true))
			setPasswordError({})

			await submitForm('reset/password', chanePasswordForm).then(resp => {
				dispatch(callLoading(false))
				if (!resp.error) {
					clearPasswordForm()
					toast.success(resp.data.msg,
						{
							position: toast.POSITION.TOP_RIGHT
						});
				} else {
					toast.error(resp.msg,
						{
							position: toast.POSITION.TOP_RIGHT
						});
				}
			});
		}
	}

	const handleImageUpload = (e) => {
		const file = e.target.files[0];
		if (!file.type.includes('image')) {
			toast.error('File type should be image.',
				{
					position: toast.POSITION.TOP_RIGHT
				});
			return
		}
		const reader = new FileReader();
		 reader.onload = async function(e) {
			 let imageData = e.target.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
			 dispatch(callLoading(true))
			await dispatch(updateProfileImage({image: imageData})).then(resp=>{
				dispatch(callLoading(false))
				if (!resp.error) {
					let user = JSON.parse(localStorage.getItem('user'))
					user.picture = resp.data.picture
					localStorage.setItem('user', JSON.stringify(user))
					/* toast.success('Successfully update image',
						{
							position: toast.POSITION.TOP_RIGHT
						}); */
				} else {
					toast.error(resp.msg,
						{
							position: toast.POSITION.TOP_RIGHT
						});
				}
			})
		};
		// Read the file as Data URL (since we accept only images)
		reader.readAsDataURL(file);


	}

	const setUserDataToStore = (resp) => {
		localStorage.setItem('user', JSON.stringify(resp.data))
		let userData = resp.data;
		var formArr =[];
		Object.entries(userData.form).forEach(val=>{
			if(val[0] == 'bike_destination_form' && val[1] === true){
				formArr.push({name:'destination', order:6, icon:'destinationico.png'})
			}else if(val[0] == 'bike_expert_form' && val[1] === true){
				formArr.push({name:'enthusiasts', order:4, icon:'expertico.png'})
			}else if(val[0] == 'buy_form' && val[1] === true){
				formArr.push({name:'product', order:1, icon:'productico.png'})
			}else if(val[0] == 'event_form' && val[1] === true){
				formArr.push({name:'event', order:5, icon:'eventico.png'})
			}else if(val[0] == 'smart_solutions_form' && val[1] === true){
				formArr.push({name:'solutions', order:3, icon:'smartico.png'})
			}
		})
		formArr.push({ name: 'services', order: 2, icon: 'destinationico.png' })
		localStorage.setItem('user_forms', JSON.stringify(formArr.sort((a,b)=>(a.order - b.order) )))

	}

	return (
		<>
			<Header />
			<Banner bannerImage='buybanner.jpg' pageName='My Profile'>

				<SearchBar is_expert_ask={false} />
			</Banner>
			<main className="site-main inner-main">
				<div className="container">
					<div className="profileholder">
						<div className="sidebar1">
							<div className="profiletop">
								<span className="img">
									{
										userData && userData.picture ? <img src={userData.picture} alt="Carryon.bike" style={{ height: 75, width: 75, borderRadius: 75 / 2 }} /> : <img src={require('../../assets/images/profile.png')} style={{ height: 75, width: 75, borderRadius: 75 / 2 }} alt="Carryon.bike" />
									}
									<div className="add1">
										<input type="file" id="addid" onChange={handleImageUpload} />
										<label htmlFor="addid">+</label>
									</div>
								</span>
								<span className="name">{userData.name}</span>
							</div>
							<ul className="profiledetailslist">
								<li className={activeTab == 0 ? "active" : ""} ><Link to='#' onClick={() => setActiveTab(0)}>My Profile</Link></li>
								{/*<li><Link to={RouteNames.buy_form}>Post Listing</Link></li>*/}
								<li><Link to="#"> My Statistics</Link></li>
								{
									userData !=null && userData.user_type && userData.user_type !== 'social' && 
									<li className={activeTab == '3' ? "active" : ""}><Link to="#" onClick={() => { setActiveTab('3'); passwordRef.current.focus() }}>Change Password</Link></li>
								}
								
								<li><Link to="#" onClick={logOut}>Logout</Link></li>
							</ul>
						</div>
						<div className="rightbar">
							<h3>your personal profile info</h3>
							<div className="formdiv">
								<div className="row">

									<div className={userData !=null && userData.user_type && userData.user_type !== 'social' ? 'col-md-8 col-lg-8 col-sm-12 col-12':'col-md-12 col-lg-12 col-sm-12 col-12'}>
										<h4>profile</h4>
										<form onSubmit={handleSubmit}>

												<div className="form-group multiselect" ref={dropDownRef1}>
														<input type="text" autoComplete='off' placeholder="Join our Platform as"  onClick={()=> setShowRoleDropDown(prevState=> !prevState)} id="msinp" value={selectedRoleLabel.length >2 ?selectedRoleLabel.slice(0,2).toString() + '...':selectedRoleLabel.toString()}/>
														<ul className="dropdown" style={{display: showRoleDropDown ? 'block':'none' }}>
														<li>Join our Platform as</li>
														{
															roles.length && roles.map((data, index)=>(
															<li key={index}>
																<input type="checkbox" name={data.label} checked={myProfileForm.field_members_for.indexOf(data.id)!= -1?true:false } value={data.id} onChange={handleRoleCheckBox}/>
																<label>{data.label}</label>
															</li>
															))
														}
														
														
														</ul> 
													</div>


											<div className="form-group">
												<input className="mandatory" type="text" name="field_full_name" placeholder="Full Name" onChange={handleInput} value={myProfileForm.field_full_name} />
												{
													form_error['errors'] && form_error['errors']['field_full_name'] && (
														<span className='form_error'>{form_error['errors']['field_full_name']}</span>
													)

												}
											</div>
											<div className="form-inline">
												<div className="form-group multiselect"  ref={dropDownRef}>
												<input type="text" placeholder="Interest Area"  onClick={()=> setShowDropDown(prevState=> !prevState)} id="msinp" value={selectedLabel && selectedLabel.length? (selectedLabel.length >2 ?selectedLabel.slice(0,2).toString() + '...':selectedLabel.toString()):'' } autoComplete='off' style={{color:'black'}}/>
													<ul className="dropdown" style={{display: showDropDown ? 'block':'none' }}>
													<li>I am interested in</li>
													{
														userInterestMaster.length && userInterestMaster.map((data, index)=>(
														<li key={index}>
															<input type="checkbox" name={data.label} checked={myProfileForm.field_user_interest && myProfileForm.field_user_interest.indexOf(data.value)!= -1?true:false } value={data.value} onChange={handleCheckBox}/>
															<label>{data.label}</label>
														</li>
														))
													}
													
													
													</ul> 

												</div>
												<div className="form-group">
													<select className="" value={myProfileForm.field_expertise} name='field_expertise' onChange={handleInput}>
														<option value=''>Select Expertise</option>
														{
															interest_master.length && interest_master.filter(obj => obj.field_taxonomy_type == "user_expertise").map((expert, index) => (
																<option value={expert.tid} key={expert.tid}>{expert.name}</option>
															))
														}
													</select>
													{
														form_error['errors'] && form_error['errors']['field_expertise'] && (
															<span className='form_error'>{form_error['errors']['field_expertise']}</span>
														)

													}
												</div>
											</div>
											<div className="form-group">
												<input type="email" className="mandatory" name="mail" placeholder="Email" value={userData ? userData.email : ''} readOnly />
												{
													form_error['errors'] && form_error['errors']['mail'] && (
														<span className='form_error'>{form_error['errors']['mail']}</span>
													)

												}
											</div>
											<div className="form-group">
												<input type="submit" name="" value="Update" />
											</div>
										</form>
									</div>
									{
										userData !=null && userData.user_type && userData.user_type != 'social' &&
										<div className="col-md-4 col-lg-r col-sm-12 col-12">
										<h4>password</h4>
										<form onSubmit={handlePasswordSubmit}>
											<div className="form-group">
												<input className="mandatory" ref={passwordRef} type="password" value={chanePasswordForm.current_password} name="current_password" placeholder="Current Password" onChange={handlePasswordInput} />
												{
													password_error['errors'] && password_error['errors']['current_password'] && (
														<span className='form_error'>{password_error['errors']['current_password']}</span>
													)

												}
											</div>
											<div className="form-group">
												<input className="mandatory" type="password" value={chanePasswordForm.password} name="password" placeholder="New Password" onChange={handlePasswordInput} />
												{
													password_error['errors'] && password_error['errors']['password'] && (
														<span className='form_error'>{password_error['errors']['password']}</span>
													)

												}
											</div>
											<div className="form-group">
												<input className="mandatory" type="password" value={chanePasswordForm.confirm_password} name="confirm_password" placeholder="Confirm Password" onChange={handlePasswordInput} />
												{
													password_error['errors'] && password_error['errors']['confirm_password'] && (
														<span className='form_error'>{password_error['errors']['confirm_password']}</span>
													)

												}
											</div>
											<div className="form-group">
												<input type="submit" name="" value="Change"/>
											</div>
										</form>
									</div>
									}
									
								</div>
							</div>

						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	)
}

export default MyProfile
