export const RouteNames = {
    //repair: '/listing-bike-repair-and-bike-accessory-repairing',
    buy:'/listing-bikes-and-accessories',
    buy_form:'/list-yourself-in-carryon-bike-products',
    buy_form_preview:'/preview-list-in-carryon-bike-products',
    //rent:'/listing-bike-rent-and-bike-accessory-renting',
    //rent_form:'/rent-form',
    //repair_form:'/repair-form',
    smart_solution_form:'/list-yourself-in-carryon-bike-smart-solutions',
    smart_solution_form_preview:'/preview-list-in-carryon-bike-smart-solutions',
    //offers: '/bike-coupon-and-offers',
    //usedBike: '/listing-used-bike',
    smart_solution:'/listing-for-smart-solutions-for-bikes-and-bike-accessories',
    smart_solution_detail:'/listing-for-smart-solutions-for-bikes-and-bike-accessories/detail',
    member_ship:'/carryon-bike-membership',
    compare: '/products-compare',
    buy_detail:'/listing-bikes-and-accessories/details',
    //rent_detail:'/rent-from',
    //repairDetails: '/listing-bike-repair-and-bike-accessory-repairing/details',
    about: '/about-us',
    contactUs: '/contact-us',
    biking: '/listing-for-biking-destinations',
    event:'/listing-for-bike-activities',
    eventForm:'/list-yourself-in-carryon-bike-activities',
    eventForm_preview:'/preview-list-in-carryon-bike-activities',
    eventDetails:'/listing-for-bike-activities/detail',
    test: '/test',
    reset_password:'/reset-password',
    destinationForm: '/list-yourself-in-carryon-bike-biking-destinations',
    destinationFormPreview: '/preview-list-in-carryon-bike-destinations',
    myprofile:'/my-carryonbike-profile',
    myStatistic: '/my-statistic',
    
    bikingDestinationDetails: '/listing-for-biking-destinations/details',
    bikeEnthusticList:'/listing-for-global-bike-enthusiasts',
    bikeEnthusticForm:'/list-yourself-in-carryon-bike-enthusiasts',
    bikeEnthusticFormPreview:'/preview-list-in-carryon-bike-enthusiasts',
    bikeEnthusticDetails:'/listing-for-global-bike-enthusiasts/detail',
    userList: '/vendor-view-list-in-carryon-bike',
    services:'/listing-for-services-of-bikes-and-bike-accessories',
    servicesDetail:'/listing-for-services-of-bikes-and-bike-accessories/detail',
    serviceForm:'/list-yourself-in-carryon-bike-services',
    serviceFromPreview:'/preview-List-in-carryon-bike-services',
    termsAndCondition:'/terms',
    privacyPolicy:'/privacy-policy',
    partners:'/partner'
    
};