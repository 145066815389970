import React from 'react';

const Item = (props) => {
    return (
        <div className="catdiv">
            <div className="top">
                <div className="catimg">
                    <img src={ require("../../../assets/images/product1.jpg") } alt="catimg"/>  
                </div>
                <div className="texthl"> 
                    <div className="texthlleft">
                        <h4><a href="repair-details.php">Spin Bicycle Shop</a></h4> 
                    </div> 
                </div>
            </div> 

            <ul className="listholder">
                <li>Oslo</li>
                <li>222m</li>
                <li> Oslo, Alesunds</li>
                <li>City Bike,  Eike, Road Bike</li>
                <li>Gear, Helmet, Glass</li>
                <li>Strong locks & map for free.</li>
                <li> 
                    <span className="price">15,00-18,00</span> 
                    <select className="converter">
                        <option>Euro</option>
                        <option>Pound</option>
                        <option>Rupee</option>
                    </select> 
                </li>
                <li>10% off</li>
                <li>
                    <span className="rating">
                        <span className="rate"  style={{ width: "80%" }}></span>
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default Item;
