import React from 'react';

const OurAvailabilityDetails = (props) => {
    return (
        <>
            <h2>Our Availibility Details</h2>
            <div className="contactinfoInner">
                <span className="contactPhone"></span>
                <div className="contRight">
                    <h5>Talk to us</h5>
                    <p><a href="tel:4746577716">+47 46577716</a></p>
                </div>
            </div>
            <div className="contactinfoInner">
                <span className="contactMail"></span>
                <div className="contRight">
                    <h5>Mail Us</h5>
                    <p>
                        <a className="mail" href="mailto:carryon.enquiry@gmail.com"> carryon.enquiry@gmail.com</a>
                    </p>
                </div>
            </div>
            <div className="contactinfoInner">
                <span className="contactLoaction"></span>
                <div className="contRight">
                    <h5>Location</h5>
                    <p> Sandefjord, NORWAY
                    </p>
                </div>
            </div>
        </>
    )
}

export default OurAvailabilityDetails;
