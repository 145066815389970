import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import MainRoutes from './routes/route';
import AppProvider from './stores/reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <AppProvider>
      <Router>
        <MainRoutes/>
        <ToastContainer autoClose={3000}/>
      </Router>
    </AppProvider>
  );
}

export default App;
