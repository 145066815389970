import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
//import { RouteNames } from '../../../includes/route-names';
 
const Keywords = (props) => {
    const { keys, onselect, selectedKey } = props;
    const [keyData, setKeyData] = useState([]);

    
    useEffect(() => {
        if(Array.isArray(keys)){
            setKeyData(keys)
        }else{
            setKeyData(keys.split(',').filter(el=>el))
        }
        return () => {
            
        }
    }, [keys])
    const _keyWordSubmitHandler = ( e, keywords ) =>{
        e.preventDefault();
        onselect({ keywords });
    }

    //console.log('KEY========', selectedKey );

    return (
        <div className="box">
            <h3>Keywords</h3>
            <ul className="cmn taglist">
                { keyData &&
                    keyData.map((key, index) => 
                        <li key={index} className={ selectedKey === key ? 'active' : null}>
                            <Link to="#" onClick={ (ev)=> _keyWordSubmitHandler(ev, key) }>
                                {key}
                            </Link>
                        </li>
                    )
                }
                  
            </ul>
        </div>
    )
}

export default Keywords;