import { APP_BACKEND_API_URL, BACKEND_API_STATUS, headerData, headerForFileData, notLoginHeaderData  } from '../../constants/config';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';

export default class Api{

    constructor(){
    }

    async submitWithOutLogin(uri, method = METHOD_GET){
        try{

            const response = await fetch( `${APP_BACKEND_API_URL}${uri}`,{
                method,
                headers:notLoginHeaderData()
            });
            
            const result = await response.json();
            
            if( result.type === BACKEND_API_STATUS.ok ){
                
                return {
                    status: 'ok',
                    data: result.data,
                    extra: result.extra,
                    pageData: result.currentPage >= 0 ? {currentPage: result.currentPage, itemPerPage: result.itemPerPage, totalPages: result.totalPages } : {},
                    facets:result.facets ? result.facets:{},
                    error: false
                };
            }
            
            else if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    extra: [],
                    error: true,
                    msg: result.data[0].msg
                };
            }

        }catch( error ){
           // window.location.reload()
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

    async submitWithDirectUrl(uri, method = METHOD_GET){
        try{

            const response = await fetch(uri,{
                method,
                headers:notLoginHeaderData()
            });
            
            const result = await response.json();
            
            if( result.type === BACKEND_API_STATUS.ok ){
                
                return {
                    status: 'ok',
                    data: result.data,
                    extra: result.extra,
                    pageData: result.currentPage >= 0 ? {currentPage: result.currentPage, itemPerPage: result.itemPerPage, totalPages: result.totalPages } : {},
                    facets:result.facets ? result.facets:{},
                    error: false
                };
            }
            
            else if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    extra: [],
                    error: true,
                    msg: result.data[0].msg
                };
            }

        }catch( error ){
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

    async submit(uri, method = METHOD_GET){
        try{

            const response = await fetch( `${APP_BACKEND_API_URL}${uri}`,{
                method,
                headers:headerData()
            });
            
            const result = await response.json();
            
            if( result.type === BACKEND_API_STATUS.ok ){
                return {
                    status: 'ok',
                    data: result.data,
                    pageData: result.currentPage >= 0 ? {currentPage: result.currentPage, itemPerPage: result.itemPerPage, totalPages: result.totalPages } : {},
                    facets:result.facets ? result.facets:{},
                    extra: result.extra,
                    error: false
                };
            }
            
            else if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    extra: [],
                    error: true,
                    msg: result.data[0].msg
                };
            }

        }catch( error ){
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

    async submitWithParamsWithOutLOgin(uri, method = METHOD_POST, params){
        try{

            const response = await fetch( `${APP_BACKEND_API_URL}${uri}`,{
                method,
                headers:notLoginHeaderData(),
                body: JSON.stringify( params ) 
            });
            
            const result = await response.json();
            console.log(result);
            //console.log("UPDATED BALANCE=============", result);
            
            if( result.type === BACKEND_API_STATUS.ok ){
                return {
                    status: 'ok',
                    data: result.data ? result.data : (result.user ? result.user : []),
                    csrf_token: result.csrf_token ? result.csrf_token :'',
                   // msg: result.data.msg? result.data.msg:'',
                    //extra: result.extra,
                    error: false
                };
            }
            
            if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    //extra: [],
                    error: true,
                    msg: Array.isArray(result.data) ? result.data[0].msg : (result.data.msg ? result.data.msg : result.data)
                };
            }

        }catch( error ){
            /* localStorage.removeItem('user');
            localStorage.removeItem('csrf_token');
            window.location.reload() */
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

    async submitWithParams(uri, method = METHOD_POST, params){
        try{
            //console.log("Method", method );
            const response = await fetch( `${APP_BACKEND_API_URL}${uri}`,{
                method,
                headers:headerData(),
                body: JSON.stringify( params ) 
            });

            console.log( response );
            
            const result = await response.json();
            
            if( result.type === BACKEND_API_STATUS.ok ){
                return {
                    status: 'ok',
                    data: result.data ? result.data : (result.user ? result.user : []),
                    csrf_token: result.csrf_token ? result.csrf_token :'',
                    content_id: result.content_id ? result.content_id:'',
                    //extra: result.extra,
                    error: false
                };
            }
            
            if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    //extra: [],
                    error: true,
                    msg: Array.isArray(result.data) ? result.data[0].msg : (result.data.msg ? result.data.msg : result.data)
                };
            }

        }catch( error ){
            //localStorage.removeItem('user');
            //localStorage.removeItem('csrf_token');
            window.location.reload() 
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

    async submitWithParamsAndFile(uri, method = METHOD_POST, params){
        try{

            //console.log("======== PARAMS==============", params );

            const response = await fetch( `${APP_BACKEND_API_URL}${uri}`,{
                method,
                headers:headerForFileData(),
                body: params 
            });
            
            const result = await response.json();

            console.log("FOR PAN===============", result);
            
            if( result.type === BACKEND_API_STATUS.ok ){
                return {
                    status: 'ok',
                    data: result.data,
                    extra: result.extra,
                    error: false
                };
            }
            
            if ( result.type === BACKEND_API_STATUS.error ){
                return {
                    status: 'error',
                    data: [],
                    extra: [],
                    error: true,
                    msg: result.error[0].msg
                };
            }

        }catch( error ){
            console.log("MY RESP ERR===========", error);
            
            return {
                status: 'error',
                data: [],
                extra: [],
                error: true,
                msg: error
            };
        }
    }

}