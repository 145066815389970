export const ADD_TO_COMPARE = 'add_to_compare';
export const REMOVE_FROM_COMPARE_LIST = 'remove_from_compare_list';
export const FETCH_COMPARE_LIST = 'fetch_compare_list';

export const getComapreList = () =>{
    return dispatch =>{
        dispatch(
            {
                type: FETCH_COMPARE_LIST
            }
        )
    }
};

export const addToCompareList = ( data ) =>{
    return dispatch =>{
        dispatch({
            type: ADD_TO_COMPARE,
            data: data,
            error: false
        });
    }
}

export const removeFromCompareList = ( data ) => {
    return dispatch =>{
        dispatch({
            type: REMOVE_FROM_COMPARE_LIST,
            data: data,
            error: false
        })
    }
}
 
