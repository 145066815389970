import React from 'react';
import {applyMiddleware, createStore, combineReducers} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import AuthReducer from './auth-reducer';
import Compare from './compare';
import CommonReducer from './common-reducer';

const reducers = combineReducers({
    auth: AuthReducer,
    compare: Compare,
    common:CommonReducer
});

const store = createStore(reducers, applyMiddleware( thunk ));

const AppProvider = (props) =>{
    const { children } = props;
    return (
        <Provider store={store}>
            { children }
        </Provider>
    )
}

export default AppProvider;