import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import Item from './components/item';

const AboutUs = ( props ) => {
	React.useEffect(()=>{
		window.scrollTo(0,0);
	}, []);
    return (
        <>
            <Header/>
            <div className="banner innerbanner ">
	    	<img  src={ require("../../assets/images/aboutbanner.jpg") } alt="blog" /> 	
	    	<div className="bannercontent">
				<div>
		    		<h3><span> Fun Without Fuel</span> - A Green commute initiative</h3> 
		    	</div>
	    	</div>       	 
		</div> 
		<main className="site-main inner-main about-main"> 
			<section className="aboutsec">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-7 col-12">
							<div className="tophead">
								<h2 className="title1">about us</h2> 
							</div>
							<div className="para">
								
								<p>The idea to build this platform was born in Sandefjord, Norway  in the spring of 2020 when the pandemic outbreak news was all around. 
								As this new era of cycling emerges, the target audience for CarryOn.Bike is going to be diverse. This portal will strive to provide useful information and utilities for the diverse population related in some way or the other with cycling. 
								High School and University students, Corporates, Delivery services like Pizza and Florists, Museums, Safaris and National Parks, Office Goers, Family Cyclist Teams, Tourists, Bike Collectors are all part of carryon.bike platform and will benefit from the information, features and functionalities. 
								It is evident that people are looking for alternative modes to commute; especially when public transportation is generally being discouraged by physicians and experts. The number of people using the ferry, bus and train are on a record low. Lockdown residents also crave a form of release and outdoor exercise.  As more and more people adopt cycling, there will be varied needs like smart parking, smart apps to collaborate and more.
								We wish to build a platform that is responsive to the growing and varied needs of cycle lovers with the support from all. Our objective is to capture the consumer sentiments worldwide and develop a solution that will address the NEEDS arising from use of cycle in Commuting, Transport, Delivery of Items, Leisure, Sport, and Fitness.</p>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 col-12">
							<div className="videodiv">
								<video controls="" autoplay="" muted="">
									<source src={require("../../assets/videos/cycling.mp4") } type="video/mp4" />
								</video>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="platform_sec animation-element">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Carryon.bike platform - For Whom
						</h2> 
						<p>Bringing the cycle community under 1 umbrella</p>
					</div>
					<div className="platform_panel platform_panel1"> 
						<div className="row">
                            <Item title="Online Bike Retailers and Offline Shops" img="plimg1.jpg"/>
                            <Item title="University Students and Faculty" img="plimg2.jpg"/>
                            <Item title="High School Students" img="plimg3.jpg"/>
                            <Item title="Service Provider – Repairing (Individual)" img="plimg4.jpg"/>
                            <Item title="Service Provider – Repairing (Shop/ Enterprise)" img="plimg5.jpg"/>
                            <Item title="Event Organisers" img="plimg6.jpg"/>
						</div>
						<div className="row" style={{display: "none"}}>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg7.jpg") } alt=""/>
									</span>
									<h3>Tourists on City Tour with rented bikes</h3> 
								</div>
							</div> 
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg8.jpg") } alt=""/>
									</span>
									<h3>Daily Commuters</h3> 
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg9.jpg") } alt=""/>
									</span>
									<h3>Cycle Clubs</h3> 
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg10.jpg") } alt=""/>
									</span>
									<h3>Casual Riders</h3> 
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg11.jpg") } alt=""/>
									</span>
									<h3>Tourist Offices</h3> 
								</div>
							</div>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg12.jpg") } alt=""/>
									</span>
									<h3>Cycle enthusiasts for Training & Fitness</h3> 
								</div>
							</div>
						</div> 
						<div className="row" style={{ display: "none" }}>
							<div className="col-lg-4 col-md-6 col-sm-6 col-12">
								<div className="platformdiv wow fadeIn" data-wow-duration="0.5s" data-wow-delay="0s">
									<span className="img">
										<img src={ require("../../assets/images/plimg13.jpg") } alt=""/>
									</span>
									<h3>Bike Experts, Health Experts.</h3> 
								</div>
							</div>
						</div>
						<div className="btnholder">
							<Link className="btn abtbtn" to="#">
								<span>view all</span>
							</Link>
						</div> 
					</div> 
				</div>
			</section>

			<section className="aboutsec2">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-5 col-12">
							<div className="abtimg">
								<img src={ require("../../assets/images/cycle9.png") } alt=""/>
							</div> 
						</div>
						<div className="col-lg-7 col-md-7 col-12">
							<div className="para">
								<p>Carryon.bike is a platform for casual riders, school and university students, and cycle enthusiasts; with features and functionalities that would help in quicker and large scale adoption, whilst making the initiative fun without fuel</p>
							</div>	 
						</div>
					</div>
				</div>
			</section>

		</main>
            <Footer/>
        </>
    )
}

export default AboutUs;
