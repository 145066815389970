import React from 'react';

const ReportWithFilter = (props) => {
    return (
        <div className="graphholder">
            <div className="top">
                <div className="sort"> 
                    <select>
                        <option>Last 7Days</option>
                        <option>Last 1Month</option> 
                    </select>
                </div>
            </div>
            <div className="graphdiv">
                <img src={require("../../../../assets/images/graphImg.jpg")} alt="graph"/>
            </div>
        </div>
    )
}

export default ReportWithFilter
