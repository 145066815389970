import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SearchBar from '../../../reuseables/SearchBar';

const banner = (props) => {
    return (
        <div className={`banner ${props.is_home ? 'homebanner':''} `}>

	    	
			<OwlCarousel 
				className="banner_slider owl-theme"
				items={1}  
				lazyLoad={true}
				autoplay={true}
				loop  
				nav  
				margin={0} >  

	    		<div className="item">
	    			<img src={require('../../../assets/images/banner1.jpg') } alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>fun without <span>fuel</span></h2>
				    		<p>Peddling to a green future</p>
				    	</div>
			    	</div>
	    		</div> 
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner2.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>Collaborate & <span>CarryOn</span></h2>
				    		<p>Explore Bike Trails  with fellow enthusiasts and get recommendations through AI enabled engine</p>
				    	</div>
			    	</div>
	    		</div>
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner4.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>Suggestions <span>please</span></h2>
				    		<p>Feedback on Trails and Tours And Expert Advise for Casual Riders</p>
				    	</div>
			    	</div>
	    		</div>  
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner5.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>nearest <span>repair buddy</span></h2>
				    		<p>Find the professional mechanic or bike repair shop nearest to breakdown point</p>
				    	</div>
			    	</div>
				</div>
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner6.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>choose the <span>right bike</span></h2>
				    		<p>Compare and get the best deals. Pick the best bike for your needs. If not satisfied get expert tips, insights and trends</p>
				    	</div>
			    	</div>
	    		</div> 
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner7.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>bike rentals <span>for fun</span></h2>
				    		<p>Rent electric bikes for your vacation and weekends</p>
				    	</div>
			    	</div>
	    		</div>
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner8.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>bringing cycle <span>community together</span></h2>
				    		<p>Competitions and Events amongst corporates  or school teams to encourage cycling to be fun</p>
				    	</div>
			    	</div>
	    		</div>
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner9.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>share your <span>experiences</span></h2>
				    		<p>Share tips and experiences from city tours, tracks and bike trails. and outdoor cycling workouts</p>
				    	</div>
			    	</div>
	    		</div>
	    		<div className="item">
	    			<img src={require('../../../assets/images/banner3.jpg')} alt=""/>
	    			<div className="bannercontent">
	    				<div>
				    		<h2>time to <span>move on</span></h2>
				    		<p>Adapting to post-COVID times</p>
				    	</div>
			    	</div>
	    		</div>
			</OwlCarousel> 
	    	<SearchBar is_home={props.is_home}/>         	 
		</div> 
    )
}

export default banner
