import React, { useState, useEffect, useCallback } from 'react';
import CountryWiseReport from '../reuseables/reports/countryWiseReport';
import PageWiseReport from '../pageWiseReport';
import Report from '../report';
//import DragDropzone from '../reuseables/drag-drop';
//import PreviewImageList from '../reuseables/preview-image-list';
import { renderButton, checkSignedIn } from "../reuseables/reports/utils";
import BrowserWiseReport from '../reuseables/reports/browserWise';

function Test() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [ fileList, setFileList ] = useState([]);

    const updateSignin = (signedIn) => { //(3)
        setIsSignedIn(signedIn);
        if (!signedIn) {
          renderButton();
        }
      };
    
      const init = () => { //(2)
        checkSignedIn()
          .then((signedIn) => {
            updateSignin(signedIn);
          })
          .catch((error) => {
            console.error(error);
          });
      };
    
      useEffect(() => {
        window.gapi.load("auth2", init); //(1)
      });

    return (
        <div>
            {!isSignedIn ? (
                <div id="signin-button"></div>
            ) : (
                <Report/>
                // <PageWiseReport/>
                // <CountryWiseReport/>
                // <BrowserWiseReport/>
            )}
        </div>
    )
}

export default Test
