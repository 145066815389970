import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from '../../../../reuseables/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getUserFormDataList, callLoading } from '../../../../stores/actions/common-action';
import { Button } from 'react-bootstrap';
import { RouteNames } from '../../../../includes/route-names';
import queryString from 'query-string';
import Favourite from '../../../../reuseables/Favourite';

const Event = (props) => {
	const { country, url } = props;
    const [ eventListData, setEventListData ] = useState( {} );
	const myCountry = useSelector( state => state.common.myCountry );
	const [activePage, setActivePage] = useState(1);
    const queryData = queryString.parse( window.location.search );
	const dispatch = useDispatch();
    
    const _getEventData = (page, filterData = {}) => {

		dispatch(callLoading(true))
		getUserFormDataList(url, page, filterData).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;
                setEventListData( resp.data );
				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}

	React.useEffect(() => {
		
		_getEventData( 0 )
		
	}, []);

	const pageChangeHandler = (page) => {
		_getEventData(page - 1);
	}

	const handleDropdown =(e)=>{
		console.log(e.target.value)
		// dispatch(callLoading(true))
		// dispatch(getEventSubList(e.target.value)).then((res)=>{
		// 	dispatch(callLoading(false));
		// })
	}


	return (
		<>
			<div className="banner innerbanner eventbanner">
				<video id="myvdo" autoPlay="" loop="" muted="">
					<source src={require('../../../../assets/videos/cycle-race.mp4')} type="video/mp4" />
				</video>
				<div className="bannercontent">
					<div>
						<h3><span>Events In </span> And Around</h3>
					</div>
				</div>
			</div>
			<main className="site-main inner-main">
				<div className="eventSection">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">

								<div className="listing_panel listing_panelnor" id="nor">
									<div className="row">
										{
											eventListData.data && eventListData.data.length > 0 && eventListData.data.map((event, ind) => 
												
													{
														return (ind + 1) % 3 == 0 ?
															<div className="col-lg-12 col-md-12 col-sm-12 col-12" key={ind}><EventContent value={event} hasInner={true} /> </div> :
															<div className="col-lg-6 col-md-6 col-sm-12 col-12" key={ind}><EventContent value={event} /></div>
													}
												
											)
										}

									</div>
								</div>

							</div>
						</div>
						<Pagination 
						activePage={activePage} 
						pageData={eventListData && eventListData.hasOwnProperty('pageData') ? eventListData.pageData : null }
						onPageChange={pageChangeHandler}
					/>
					</div>
				</div>
			</main>

		</>
	)
}

const EventContent = (props) => {
	const { value } = props

    const dispatch = useDispatch();
	
	return (

		<div className={props.hasInner ? 'eventInner eventInnerSec' : 'eventInner'}>
            {
                value.field_photo && value.field_photo.length > 0 &&
                    <div className="eventImg catimg">
                    
                        <img src={ value.field_photo[0] } alt="" />
                        <span className="ovrly"></span>
						{
                            value.editable && parseInt( value.editable ) === 1 &&
                            <Link 
                                className="edit" 
                                title="Edit" 
                                to={`${RouteNames.eventForm}?content_id=${value.content_id}`}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link>
                        }
                        {/* {
                            value.editable && parseInt( value.editable ) === 1 ?
                            <Link 
                                className="edit" 
                                title="Edit" 
                                to={`${RouteNames.eventForm}?content_id=${value.content_id}`}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link> : 
                            <Favourite content_id={value.content_id} type='event' is_already_favourite={value.is_already_favourite} fullData={value}/> 
                        } */}
                        
                    
                    </div>
            }
			
			<div className="event-list-details">
				{/* <div className="iconDiv">
					<Link to="#" title="Share"><i className="fa fa-share-alt" aria-hidden="true"></i></Link>
					<Link to="#" title="Save" onClick={addToFavourite}><i className="fa fa-heart" aria-hidden="true"></i></Link>
				</div> */}
				<h4><i className="fa fa-map-marker" aria-hidden="true"></i> {value.field_country} </h4>
				<h2 className="notranslate"><Link to='#'>{value.title}</Link></h2>
				<div className="taglist1">
					<ul>
						{
                            value.field_keywords &&
                            value.field_keywords.length > 0 &&
							value.field_keywords.map((keyword, index) => (
								<li key={index}>{keyword}</li>
							))
						}
					</ul>
				</div>
				<div className="event-list-when">
					<span className="tribe-event-date-start">{value.field_event_start_date}</span>
				</div>
				<div className="event-list-description">
					<span dangerouslySetInnerHTML={{ __html: value.body }}></span>
					<Link className="link" to={{ pathname: value.field_urls }} target="_blank">
						{value.field_urls}
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Event;
