import React from 'react'

const ExploreCarryon = (props) => {
    return (
        <section className="explore_sec animation-element">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Explore the CarryOn Mindset	
							<span>of local cyclists</span>
						</h2>  
					</div>
					<div className="explorepanel">
						<div className="explorepart">

							<div className="explorediv">
								<img src={ require("../../../assets/images/vid1.jpg")} alt=""/>
								<iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/5ghFEylYNco" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								<span className="play">
									<img src="images/play.png" alt=""/>
								</span>
								<div className="abs">
									<address>
										<i className="fa fa-map-marker" aria-hidden="true"></i>oslo
									</address>
									<h3>
										Biking to work is Fun without Fuel
									</h3>
								</div>
							</div>
						</div>
						<div className="explorepartsmall">
							<div className="explorediv">
								<img src={ require("../../../assets/images/vid2.jpg")} alt=""/>
								<iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/5ghFEylYNco" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								<span className="play">
									<img src="images/play.png" alt=""/>
								</span>
								<div className="abs">
									<address>
										<i className="fa fa-map-marker" aria-hidden="true"></i>oslo
									</address>
									<h3>
										Biking to work is Fun without Fuel
									</h3>
								</div>
							</div>
							<div className="explorediv">
								<img src={ require("../../../assets/images/vid3.jpg")} alt=""/>
								<iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/35gLCEFJens" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								<span className="play">
									<img src="images/play.png" alt=""/>
								</span>
								<div className="abs">
									<address>
										<i className="fa fa-map-marker" aria-hidden="true"></i>SANDEFJORD
									</address>
									<h3>
										Energize your Mind, Body and Soul
									</h3>
								</div>
							</div>
							<div className="explorediv">
								<img src={ require("../../../assets/images/vid4.jpg")} alt=""/>
								<iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/COzyRevToCA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								<span className="play">
									<img src="images/play.png" alt=""/>
								</span>
								<div className="abs">
									<address>
										<i className="fa fa-map-marker" aria-hidden="true"></i>LARVIK
									</address>
									<h3>
										Fun without Fuel- Cycling in Norway 🇳🇴
									</h3>
								</div>
							</div>
							<div className="explorediv">
								<img src={ require("../../../assets/images/vid5.jpg")} alt=""/>
								<iframe loading="lazy" src="https://www.youtube.com/embed/ulnZ0z8l9Ck" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
								<span className="play">
									<img src="images/play.png" alt=""/>
								</span>
								<div className="abs">
									<address>
										<i className="fa fa-map-marker" aria-hidden="true"></i>SANDEFJORD
									</address>
									<h3>
										A casual bike rider out in sun with DBS electric bike and GoPro Hero 8 Black in Sandefjord, Norway
									</h3>
								</div>
							</div>
						</div> 
					</div>
					<div className="btnholder">
						<a className="btn" href="#">
							<span>view all</span>
						</a>
					</div>
				</div>
			</section>
    )
}

export default ExploreCarryon
