import React from 'react'


const SortDropDown = (props) => {
    const {type, callSort, valueData} = props;

    const handleChanges =(e)=>{
        callSort(e.target.value)
    }

    return (
        <div className="sort" style={{ marginLeft: 10 }}>
            <select name='max_price' value={valueData} onChange={(e) => handleChanges(e)}>
                <option value=''>Sort By</option>
                <option value='changed'>Latest</option>
                <option value='search_api_relevance'>Relevance</option>
                {
                   type!=='smart_solution' &&  <option value='distance'>By Distance</option>
                }
               
            </select>
        </div>
    )
}

export default SortDropDown
