import React from 'react';

const SocialLinks = (props) => {
    return (
        <div className="contactinfoInner">
            <ul className="social">
                <li>
                    <a href="https://www.facebook.com/funwithoutfuel/" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </li> 
                <li>
                    <a href="https://www.instagram.com/carryonbike/" target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCas94cAYyAxiVhqhQoUo7Rw" target="_blank">
                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.tripadvisor.com/Profile/funwithoutfuel" target="_blank">
                        <i className="fa fa-tripadvisor" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default SocialLinks
