import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../includes/route-names';
import Characterstic from '../../../reuseables/Characterstic';
import Favourite from '../../../reuseables/Favourite';

const STR_LEN = 60;

const Item = (props) => {
    const { data } = props;
    if( !data ) return ( null );

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                    {
                        data.field_photo &&
                        <Link to={`${RouteNames.bikeEnthusticDetails}${data.alias ? data.alias : ''}`}>
                            <img src={ data.field_photo } alt=""/>
                            <span className="ovrly"></span>
                        <Favourite content_id={data.nid} type='expert' is_already_favourite={data.is_already_favourite} fullData={data}/>
                        </Link>
                        
                    }
                     
                    
                </div>
                <div className="texthl"> 
                    <div className="texthlleft">
                        {
                            data.title &&
                            <h4 className="notranslate" ><Link to={`${RouteNames.bikeEnthusticDetails}${data.alias ? data.alias : ''}`}>{ data.title }</Link></h4>
                        }
                        {
                            data.field_about &&
                            <div className="cmpnyname" dangerouslySetInnerHTML={{__html: data.field_about.length > STR_LEN ? data.field_about.substring(0, STR_LEN).concat('...') : data.field_about}}>
                            </div>
                        }
                         
                    </div>
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{width:'100%'}}></span>
                    </span> 
                </div>
                {
                        data.field_shop_icon &&  <Characterstic field_shop_icon={data.field_shop_icon}/>
                    }
            </div>
        </div> 
    )
}

export default Item;
