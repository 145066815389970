import React from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../includes/route-names';

const Goto = (props) => {
    const { to, title } = props;
    return (
        <Link className="goto" to={`${RouteNames.userList}/${to}`} >{ title || 'Go to Listing'}</Link>
    )
}

export default Goto;
