import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import SearchBar from '../../reuseables/SearchBar';
import Pagination from '../../reuseables/Pagination';
import { getEventDetails, callLoading, getFormContent } from '../../stores/actions/common-action';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../includes/route-names';
import Keywords from '../buy/components/keywords';
import queryString from 'query-string';
import FavouriteDetail from '../../reuseables/FavouriteDetail';

const EventDetail =(props)=>{

	const { alias } = useParams();
	const queryData = queryString.parse( window.location.search );
	const dispatch = useDispatch();
	const [ activePage, setActivePage ] = useState( 1 );
    const [ keywords, setKeywords ] = useState('');
	const eventDetailsData = useSelector( state => state.common.eventDetailsData );
	const [isPreview, setIsPreview] = useState(false)

	console.log( alias );

    const _getEventData = ( page, filterData ={} ) =>{
		
		dispatch(callLoading(true))
		dispatch( getEventDetails( page, filterData ) ).then( resp => { 
			
			if( !resp.error && resp.data && resp.data.pageData ){
				const { currentPage } = resp.data.pageData;
				
				setActivePage( currentPage + 1 );
				window.scrollTo(0,0);
			}

			dispatch(callLoading(false)); } )
		.catch( error => dispatch(callLoading(false)) );
	
		}
		
	useEffect(() => {
		window.scrollTo(0, 0);
		if(queryData && queryData.preview){
			setIsPreview(true)
			dispatch(callLoading(true))
			dispatch(getFormContent(`event/userdata?page=0&content_id=${alias}`,'fetch_event_details')).then(res=>{
				dispatch(callLoading(false))
			})
		}else{
			_getEventData( 0, { alias: `/${alias}` });
		}
			
	}, [alias]);
    
    const searchSubmitHandler = ( data ) =>{
        const { keywords, locationData } = data;
        //console.log( 'kkk======', keywords );
        let paramData = {};
        let queryData ={};
        paramData['alias'] = `/${alias}`;
		queryData['alias'] = `/${alias}`;

		if( keywords ){
			paramData['body'] = keywords;
			queryData['body'] = keywords;
            setKeywords( keywords );
		}
		if( locationData && locationData.location ){
			paramData['field_country_value']= locationData;
			queryData['field_country_value']= locationData.location;
		}
		/*
		if( locationData && Object.keys(locationData).length > 0 ){
			paramData['lat'] = locationData.lat;
			paramData['long'] = locationData.long;
			queryData['lat'] = locationData.lat;
			queryData['long'] = locationData.long;
			if( locationData.city )
				paramData['field_city_value'] = locationData.city;
				queryData['field_city_value'] = locationData.city;
			if( locationData.country ){
				paramData['field_country_value'] = locationData.country;
				queryData['field_country_value'] = locationData.country;
				//setSearchCountry( locationData.country );
			}
		}
		*/
		var queryString = '';
		Object.entries(queryData).forEach((data, index)=>{
			if(index == 0){
				queryString += `?${data[0]}=${data[1]}`
			}else{
				queryString += `&${data[0]}=${data[1]}`
			}
			
		})
		//console.log('TEST', queryString);
		props.history.push(RouteNames.event + queryString)
		return
		//console.log( locationData );
		_getEventData( 0, { ...paramData } );
    }

    return (
        <>
            <Header />
			<Banner bannerImage='eventbanner.jpg' parentRoute={RouteNames.event} parentPageName='Events' pageName={eventDetailsData.data && eventDetailsData.data.length > 0 ? eventDetailsData.data[0].title :''}>
				{
					!isPreview && <SearchBar onSearchSubmit={searchSubmitHandler} />
				}
				
			</Banner>
            <main className="site-main inner-main">
			<section className="blogmainsec blogdetailsmainsec">
				<div className="container">  
					<div className="blogbottom"> 
						<div className="row">
							<div className="col-lg-8 col-md-7 col-sm-8 col-12">
								<div className="blogbottomleft">
									{
										eventDetailsData.data && eventDetailsData.data.length > 0 && 
										<article className="artdiv ">
											<span className="img">
												<img src={eventDetailsData.data[0].field_photo} alt=''/>
											</span>
											
											<div className="text clear">
											<h3 className="notranslate" >{eventDetailsData.data[0].title}</h3>
												<div className="bar">
													<Link className="expl" to="#">Upcoming</Link>
													<span className="date">
														<i className="fa fa-clock-o" aria-hidden="true"></i>
														{eventDetailsData.data[0].field_event_start_date}  {eventDetailsData.data[0].field_event_end_date ? '- ' + eventDetailsData.data[0].field_event_end_date :''}
													</span>
												</div>
												<div className="dtldiv">
												<h3 className="brandname rtng">
													<FavouriteDetail data={eventDetailsData.data[0]} content_id={eventDetailsData.data[0].content_id} type='event' isPreview={isPreview}/>
												</h3>
												{
													eventDetailsData.data[0].field_urls &&
													<h3 className="shopweb brandname">
													<span className="ico">
														<img src={require('../../assets/images/url.png')} alt="" />
													</span>
													<span>URL:</span>
													<Link className="" to={{pathname:eventDetailsData.data[0].field_urls}} target="_blank">
														{ eventDetailsData.data[0].field_urls }
													</Link>
												</h3>
												}
												</div>
												
												
												
												<p dangerouslySetInnerHTML={{__html:eventDetailsData.data[0].body}}></p>
											</div>

										</article> 

										}
									
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-12">						

							<div className="reservationform">
									<div className="box">
										<div className="vdo">
											<video controls="">
												<source src={require('../../assets/videos/cycling1.mp4')} type="video/mp4" />
											</video>
										</div>
									</div>
									<div className="box boxmail">
										<h3>Post your Event</h3>
										<div className="reviewright">
											<p>Do you want people to know of a cycling event in your city? Please send the details to <a href="mailto:carryon.enquiry@gmail.com">
												carryon.enquiry@gmail.com
	                               			</a>
											</p>
											<p>You can submit your form <Link to={RouteNames.eventForm}>here</Link> also. 
											</p>
										</div>
									</div>
								</div>
								{
											eventDetailsData.data && eventDetailsData.data.length > 0 && eventDetailsData.data[0].field_keywords &&
											<Keywords 
												keys={ eventDetailsData.data[0].field_keywords} 
												selectedKey={keywords}
												onselect={searchSubmitHandler}
											/>
										}
							<div className="sidepart follow">
									<h3 className="hd1"><span>follow us</span></h3>
									<ul className="sociallist">
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-instagram" aria-hidden="true"></i></span>
												<span className="name">instragram</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-facebook" aria-hidden="true"></i></span>
												<span className="name">facebook</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span>
												<span className="name">pinterest</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-youtube-play" aria-hidden="true"></i></span>
												<span className="name">youtube</span>
											</Link>
										</li>
										<li>
											<Link className="" to="#">
												<span className="ico"><i className="fa fa-rss" aria-hidden="true"></i></span>
												<span className="name">rss</span>
											</Link>
										</li>
									</ul>
								</div>

						</div>
						</div>
					</div>
				</div>

			</section> 
		</main>
            <Footer />
        </>
    )
}

export default EventDetail
