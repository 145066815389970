import React from 'react';
import { Link } from 'react-router-dom';

function TagList() {
    return (
        <div class="box">
            <h3>Taglist</h3>
            <ul class="cmn taglist">
                <li>
                    <Link to="repair.php">
                        Digitalising Bicycle Repairs
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Workshops
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        collect
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        repair and return services
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        expert mechanics
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        quick turnaround
                    </Link>
                </li> 
            </ul>
        </div>
    )
}

export default TagList;
