import React, { useState, useEffect } from 'react';
import queryString from 'query-string'
import { Link } from 'react-router-dom';
import Banner from '../../../../includes/Banner';
import { RouteNames } from '../../../../includes/route-names';
import Pagination from '../../../../reuseables/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { getUserFormDataList, callLoading, clearSearch } from '../../../../stores/actions/common-action';
import NoData from '../../../../reuseables/no-data';
import Favourite from '../../../../reuseables/Favourite';

const Buy = (props) => {
    const { country, url } = props;
    //const userBuyData = useSelector(state => state.common.userBuyData);
    const [userBuyData, setUserBuyData ] = useState( {} );
    const myCountry = useSelector( state => state.common.myCountry );
    const resetSearch = useSelector( state => state.common.resetSearch );
	const [activePage, setActivePage] = useState(1);
    const dispatch = useDispatch();
    
    const queryData = queryString.parse( window.location.search );
    
    const _getButData = (page, filterData = {}) => {
        //console.log( filterData );
		dispatch(callLoading(true))
		getUserFormDataList(url, page, filterData).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;
                setUserBuyData( resp.data );
				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

    }
    
    //console.log( myCountry );

    React.useEffect(()=>{
                      
        _getButData(0);       
        
    },[]);

    //console.log('BUY DATA ========== ', userBuyData );
    
    const pageChangeHandler = (page) => {
		_getButData(page - 1);
    }


    return (
        <>
         
            <Banner extra_class bannerImage='buybanner.jpg' pageName='Products'>
                <div className="bannercontent">
                    <div>
                        <h3><span>Want to Buy a Bike? </span></h3>
                        <p>Compare and get the best match for your needs!</p>
                    </div>
                </div>
            </Banner>
			<main className="site-main inner-main">
                <div className="listing_panel listing_panelnor" id="nor">
                    <div className="container">
                        <div className="listing_holder">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="listing_middle">
                                        <div className="row">
                                            {
                                                userBuyData.data && userBuyData.data.length > 0 ? userBuyData.data.map((item, index) => (
                                                    <BuyCardContainer dataValue={item} detail_link="" key={index}/>
                                                )) : <NoData message="No data found.."/>
                                            }
                                        </div>
                                        <Pagination 
                                            activePage={activePage} 
                                            pageData={userBuyData && userBuyData.hasOwnProperty('pageData') ? userBuyData.pageData : null }
                                            onPageChange={pageChangeHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            
        </>
    )
}

const BuyCardContainer = (props) => {
    const { detail_link, dataValue } = props;

    return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                    <div>
                        {
                            dataValue.field_shop_icon &&
                            <span className="shptp">
                                <img src={dataValue.field_shop_icon} alt="icon" className="icon"/>
                            </span>
                        }
                        {
                            dataValue.field_image && dataValue.field_image.length > 0 && dataValue.field_image[0] &&
                                <img src={dataValue.field_image[0]} alt="" />
                        }
                                        
                        <span className="ovrly"></span>
                        {
                            dataValue.editable && parseInt( dataValue.editable ) === 1 && 
                            <Link 
                            className="edit" 
                            title="Edit" 
                            to={`${RouteNames.buy_form}?content_id=${dataValue.content_id}`}>
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                        </Link>
                        }
                        {/* {
                            dataValue.editable && parseInt( dataValue.editable ) === 1 ?
                            <Link 
                                className="edit" 
                                title="Edit" 
                                to={`${RouteNames.buy_form}?content_id=${dataValue.content_id}`}>
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                            </Link> 
                            : <Favourite content_id={dataValue.content_id} type='buy' is_already_favourite={dataValue.is_already_favourite} fullData={dataValue}/>
                        } */}
                        
                        
                    </div>
                </div>
                <div className="texthl">
                    <div className="texthlleft">
                        <h4 className="notranslate"><Link to="#">{dataValue.title}</Link></h4>
                        <span className="cmpnyname">
                            {dataValue.field_address}
                        </span>
                    </div>
                    <span className="price">
                        <span>{dataValue.field_currency} {Math.round(dataValue.field_minimum_price)} -<br /> {dataValue.field_currency} {Math.round(dataValue.field_maximum_price)}</span>
                    </span>
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{ width: '100%' }}></span>
                    </span>
                </div>
            </div>
        </div>


    )
}

export default Buy;
