import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getReviews, callLoading, resetReviewListing } from '../stores/actions/common-action';
// url : carryon/comments ( GET )
const ReviewListing = (props) => {
    
    const { contentId, title } = props;
    const dispatch = useDispatch();
    const [ reviews, setReviews ] = useState( [] );
    const [ pages, setPages ] = useState( {} );
    const [ currentPage, setCurrentPage ] = useState( 0 );
    const resetReviewList = useSelector( state => state.common.resetReviewList );
    const _getComments = ( page = 0 ) =>{
        getReviews('comments', contentId, page ).then( resp => 
            {
                if( !resp.error){
                    const { data, pageData } = resp.data;
                    setReviews( data );
                    setPages( pageData );
                    setCurrentPage( page );
                }
            }
        ).catch( error => console.log( error ));
    }

    useEffect(()=>{
        if( contentId || (contentId && resetReviewList ) )
            _getComments();
        if( resetReviewList )
            dispatch( resetReviewListing( false ) );
    },[contentId, resetReviewList]);

    const ReviewPagination = () => {
        if( pages && pages.totalPages > 1 ){
            const limit = 3; 
            let _element = [];

            for( let i = 0; i < ( pages.totalPages > limit ? limit : pages.totalPages ); i++ ){
                _element.push(<li key={i + 1}><Link to="#" onClick={() => _getComments( i ) }>{ i + 1 }</Link></li>);
            }

            //console.log( _element );
            return (
                    <ul className="pagination1">
                        
                        <li><Link to="#" onClick={ _goToPrev }><i className="fa fa-angle-double-left" aria-hidden="true"></i></Link></li>
                        { _element }
                        <li><Link to="#" onClick={ _goToNext }><i className="fa fa-angle-double-right" aria-hidden="true"></i></Link></li>
                    
                    </ul> 
            );
        }
        else
            return null;
    }

    console.log( "Pages", pages )

    const _goToPrev = (ev) =>{
        ev.preventDefault();
        
        if( currentPage > 0 )
            _getComments( currentPage - 1 );
    }

    const _goToNext = (ev) =>{
        ev.preventDefault();
        
        if( currentPage < ( pages.totalPages - 1 ) )
            _getComments( currentPage + 1 );
    }

    return (
        <div className="brandav">
            <h3>{ title || 'Review' }</h3>
            <div className="reviewdiv reviewdiv1">
                <div className="reviewleft">
                    {
                        reviews && reviews.length > 0 ?
                        <ul className="rvwlist">
                            {
                                reviews.map( ( review, key) =>
                                <li key={key}>
                                    {
                                        review.comment_body &&
                                        <span className="dtl" dangerouslySetInnerHTML={{__html: review.comment_body}}></span>

                                    }
                                    {
                                        review.rating && 
                                        <span className="rating">
                                            <span className="rate" style={{width:`${ review.rating * 20 }%`}}></span>
                                        </span>
                                    }
                                    
                                </li>
                                )
                            }
                        </ul>
                        : <p>No review found</p>
                    }

                    <ReviewPagination/>

                </div>
            </div>
        </div>
    )
}

export default ReviewListing;
