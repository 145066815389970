import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Banner from '../../../includes/Banner';
import Footer from '../../../includes/Footer'
import Header from '../../../includes/Header'
import SearchBar from '../../../reuseables/SearchBar';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { getUserFormDataList, callLoading, clearSearch } from '../../../stores/actions/common-action';
import Buy from './components/buy';
import Service from './components/service';
import SmartSolution from './components/smartsolution';
import BikeExpert from './components/bike-expert';
import Event from './components/events';
import BikeDestination from './components/bike-destination';
 
const URL_LIST = {
    product: 'buy',
    services:'service',
    'bike-destination': 'bike-destination',
    'bike-enthusiasts': 'bike-expert',
    event: 'event',
    smartsolution: 'smartsolution'
};

const List =(props)=>{
    const { country, listType } = useParams();
    
    const ComponentForListing = () =>{
        switch( listType ){
            case 'product' : return ( <Buy country={country} url={URL_LIST[listType]} /> );
            case 'services' : return ( <Service country={country} url={URL_LIST[listType]} />);
            case 'smartsolution' : return ( <SmartSolution country={country} url={URL_LIST[listType]} />);
            case 'bike-enthusiasts' : return (<BikeExpert country={country} url={URL_LIST[listType]}/> );
            case 'event' : return ( <Event country={country} url={URL_LIST[listType]} />);
            case 'bike-destination' : return ( <BikeDestination country={country} url={URL_LIST[listType]}/> );
            default : return ( <p>No route found</p> );
        }
    }

    return (
        <>
            <Header/>
            <ComponentForListing />
            <Footer/>
        </>
    )
}



export default List;
