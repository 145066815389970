import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Banner from '../../includes/Banner'
import Footer from '../../includes/Footer'
import Header from '../../includes/Header'
import { Link } from 'react-router-dom';
import SearchBar from '../../reuseables/SearchBar';
import { RouteNames } from '../../includes/route-names';
import Pagination from '../../reuseables/Pagination';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { getServicesList, callLoading, clearSearch } from '../../stores/actions/common-action';
import { Button } from 'react-bootstrap';
import GetInTouch from '../../reuseables/get-in-touch';
import NoData from '../../reuseables/no-data';
import Favourite from '../../reuseables/Favourite';
import SortDropDown from '../../reuseables/SortDropDown';
import Characterstic from '../../reuseables/Characterstic';

const ServicesList = (props) => {

	const { country } = useParams();
    //const paramData = queryString.parse( location.search );
    const serviceListData = useSelector(state => state.common.servicesData);
    const myCountry = useSelector( state => state.common.myCountry );
    const resetSearch = useSelector( state => state.common.resetSearch );
    const [activePage, setActivePage] = useState(1);
	const [ searchCountry, setSearchCountry ] = useState( country );
	const [show, setShow] = useState(false);
    const _openModalHandler = () => setShow(true);
    const dispatch = useDispatch();
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const queryData = queryString.parse( window.location.search );
    const [keywords, setKeywords] = useState( queryData && queryData.body ? queryData.body : '');
    const [sortValue, setSortValue] = useState('')
    const [searchKeyData,setSearchKeyData] = useState({})
    const [queryDataValue,setQueryDataValue] = useState({})

	const _getButData = (page, filterData = {}) => {
		dispatch(callLoading(true))
		dispatch(getServicesList(page, filterData)).then(resp => {

			if (!resp.error && resp.data && resp.data.pageData) {
				const { currentPage } = resp.data.pageData;

				setActivePage(currentPage + 1);
				window.scrollTo(0, 0);
			}

			dispatch(callLoading(false));
		})
			.catch(error => dispatch(callLoading(false)));

	}
	


    React.useEffect(()=>{
        window.scrollTo(0,0);
        
        if(Object.keys(queryData).length > 0 && !resetSearch ){
            if(Object.keys(queryData).indexOf('field_country_value') === -1){
                let queryValue = queryData;
                queryValue['sort_by'] = 'search_api_relevance'
                setSortValue('search_api_relevance')
            }
             setQueryDataValue(queryData);
            _getButData(0, { ...queryData });
        }else{
            if( myCountry )
                _getButData(0, { field_country_value: myCountry } );
        }

        if( resetSearch){
            clearData();
            dispatch( clearSearch( false ) );
        }
        
	},[myCountry, resetSearch, ]);
	
	const searchSubmitHandler = (data) => {
        setQueryDataValue({})
        setSearchKeyData(data)
		const { keywords, locationData } = data;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
            if(sortValue =='search_api_relevance'){
                setSortValue('');
            }
        }
        if(sortValue){
            if(Object.entries(locationData).length > 0 && keywords && sortValue !='search_api_relevance'){
                paramData['sort_by']= sortValue;
            }
        }

        if(Object.entries(locationData).length == 0 && keywords){
            paramData['sort_by']= 'search_api_relevance';
            setSortValue('search_api_relevance')
            _getButData(0, { ...paramData });
           }else{
            _getButData(0, { ...paramData });
           }
    }
    
    const clearData =()=>{
        _getButData( 0 );
        setSortValue()
        setKeywords( null );
        setSearchKeyData({})
        setQueryDataValue({})
    }

    const callSort =(value)=>{
        setSortValue(value)
        let paramData = {};
        if(Object.keys(queryDataValue).length > 0){
            const { body, field_country_value } = queryDataValue;
            if( body )
                paramData['body'] = body;
            if( field_country_value ){
                paramData['field_country_value']= field_country_value;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getButData(0, { ...paramData });

        }else if(Object.keys(searchKeyData).length > 0){
            const { keywords, locationData } = searchKeyData;
            
            if( keywords )
                paramData['body'] = keywords;
            if( locationData && locationData.location ){
                paramData['field_country_value']= locationData.location;
            }
            if(value){
                paramData['sort_by'] = value
            }
            _getButData(0, { ...paramData });
        }else{
            if( myCountry ){
                if(value){
                    _getButData(0, { field_country_value: myCountry,sort_by:value } );
                }else{
                    _getButData(0, { field_country_value: myCountry} );
                }
                
            }else{
                if(value){
                    _getButData( 0,{sort_by:value});
                }else{
                    _getButData( 0);
                }
                
            }
                
         }           
        
    }

	

	const pageChangeHandler = (page) => {
        const { keywords, locationData } = searchKeyData;
		let paramData = {};
		if( keywords )
            paramData['body'] = keywords;
        if( locationData && locationData.location ){
            paramData['field_country_value']= locationData.location;
        }
        else if( myCountry ){
            paramData['field_country_value']= myCountry;
        }
        if(sortValue){
            paramData['sort_by']= sortValue;
        }
		_getButData(page - 1, { ...paramData });
    }

    return (
        <>
         <Header/>
            <Banner bannerImage='rentbanner.jpg' pageName='Services'>
            <div className="bannercontent">
				<div>
		    		<h3><span>Punctures, chain break, brake rub suddenly on way to school, office or university?</span></h3>
		    		<p>Explore Options for varied services</p>
		    	</div>
	    	</div>
            <SearchBar 
                is_expert_ask={true} 
                onSearchSubmit={searchSubmitHandler}
                keyword={keywords}
            />
            </Banner>
			<main className="site-main inner-main">
            <div className="listing_panel listing_panelnor" id="nor">
                <div className="container">
                    <div className="listing_holder">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="listingtop clear">
									
                                    <form>
                                    <SortDropDown type='service' callSort={callSort} valueData={sortValue}/>
                                    </form>

                                </div>
                                <div className="listing_middle">
                                    <div className="row">
                                        {
                                            serviceListData.data && serviceListData.data.length > 0 ? serviceListData.data.map((item, index) => (
                                                <ServiceCardContainer dataValue={item} detail_link="" key={index}/>
                                            )) : <NoData message="No data found.."/>
                                        }
                                    </div>
									<Pagination 
										activePage={activePage} 
										pageData={serviceListData && serviceListData.hasOwnProperty('pageData') ? serviceListData.pageData : null }
										onPageChange={pageChangeHandler}
									/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="getintouch">
                            <div className="btnholder">
                                <p>If you want to list your brand, shop/store, or retail chain because you want to reach out to the global cycle community, please get in touch with us.</p>
                                <Link className="btn contctBtn getintouchBtn" to="#" onClick={() => _openModalHandler()}>
                                    <span>Get In Touch</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GetInTouch showExpert={show} handleModal={() => setShow(false)} />
        </main>
         <Footer/>   
        </>
    )
}

const ServiceCardContainer =(props)=>{

	const { detail_link, dataValue } = props;

	return (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="catdiv">
                <div className="catimg">
                <Link to={`${RouteNames.servicesDetail}${dataValue.alias ? dataValue.alias : ''}`}>
                    
                    
                   {/*  {
                        dataValue.field_shop_icon &&
                        <span className="shptp">
                            <img src={dataValue.field_shop_icon} alt="icon" className="icon"/>
                        </span>
                    } */}
                     
                    <img src={dataValue.field_image? dataValue.field_image: require('../../assets/images/mountainbike2.jpg')} alt="mountainbike" />
                   
                    <span className="ovrly"></span>
                    
                    <Favourite content_id={dataValue.content_id} type='buy' is_already_favourite={dataValue.is_already_favourite} fullData={dataValue}/>
                </Link>
                </div>
                <div className="texthl">
                    <div className="texthlleft">
                        <h4 className="notranslate"><Link to={`${RouteNames.servicesDetail}${dataValue.alias ? dataValue.alias : ''}`}>{dataValue.title}</Link></h4>
                        <span className="cmpnyname">
                            {dataValue.field_address}
                        </span>
                    </div>
                    <span className="price">
                        <span>{dataValue.field_currency} {Math.round(dataValue.field_minimum_price)} -<br /> {dataValue.field_currency} {Math.round(dataValue.field_maximum_price)}</span>
                    </span>
                </div>
                <div className="textbtm clear">
                    <span className="rating">
                        <span className="rate" style={{ width: '100%' }}></span>
                    </span>
                </div>
                {
                    dataValue.field_shop_icon &&  <Characterstic field_shop_icon={dataValue.field_shop_icon}/>
                }
            </div>
        </div>


    )

}

export default ServicesList
