import { Link } from 'react-router-dom';
import React from 'react';
import FavouriteDetail from '../../../reuseables/FavouriteDetail';

const ReviewDetails = (props) => {
    const { buyData,isPreview } = props;
    return (
        <h3 className="brandname rtng">
            <span className="rating">
                <span className="rate" style={{ width: `${ buyData && buyData.field_rating ? buyData.field_rating * 20 : 0 }%` }}></span>
            </span>
            {/*<span className="revwconunt">
                { buyData && buyData.feedback_count ? buyData.feedback_count : 0 } Reviews
            </span>
        */}
        {
            buyData && <FavouriteDetail data={buyData} content_id={buyData.content_id} type='buy' isPreview={isPreview}/>
        }
        
            {/* <div className="hoverdiv">
                <div>
                    <Link className="review" to="#"><i class="fa fa-comment-o" aria-hidden="true"></i>
                        
                    </Link>
                    <span className="count">1</span>
                </div>
                <div>
                    <Link className="wishlist" to="#"><i className="fa fa-heart" aria-hidden="true"></i>
                        
                    </Link>
                    <span className="count">1</span>
                </div>
                <div>
                    <Link className="view" title="View" to="#"><i className="fa fa-eye" aria-hidden="true"></i>
                        
                    </Link>
                    <span className="count">1</span>
                </div>
            </div> */}
        </h3>
    )
}

export default ReviewDetails;
