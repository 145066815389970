export const countryLanguageList = [
    {
        region: 'Americas', 
        content: [
            //{country: 'Argentina', label: 'Argentina (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            {country: 'Brazil', label: 'Brazil (Portuguese)', language: 'pt', tag: '#googtrans(en|pt)'},
            {country: 'Canada', label: 'Canada (English)', language: 'en', tag: '#googtrans(en|en)'},
            //{country: 'Chile', label: 'Chile (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            //{country: 'Colombia', label: 'Colombia (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            //{country: 'Costa Rica', label: 'Costa Rica (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            //{country: 'Mexico', label: 'Mexico (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            {country: 'United States', label: 'United States (English)', language: 'en', tag: '#googtrans(en|en)'}
        ]
    },
    {
        region: 'Europe',
        content: [
            {country: 'Austria', label: 'Austria (German)', language: 'de', tag: '#googtrans(en|de)'},
            {country: 'Belgium', label: 'Belgium (Dutch)', language: 'nl', tag: '#googtrans(en|nl)'},
            {country: 'Belgium', label: 'Belgium (French)', language: 'fr', tag: '#googtrans(en|fr)'},
            {country: 'Czech Republic', label: 'Czech Republic (Czech)', language: 'cs', tag: '#googtrans(en|cs)'},
            {country: 'Denmark', label: 'Denmark (Danish)', language: 'da', tag: '#googtrans(en|da)'},
            {country: 'Estonia', label: 'Estonia (Estonian)', language: 'et', tag: '#googtrans(en|et)'},
            {country: 'Finland', label: 'Finland (Finnish)', language: 'fi', tag: '#googtrans(en|fi)'},
            {country: 'France', label: 'France (French)', language: 'fr', tag: '#googtrans(en|fr)'},
            {country: 'Germany', label: 'Germany (German)', language: 'de', tag: '#googtrans(en|de)'},
            {country: 'Hungary', label: 'Hungary (Hungarian)', language: 'hu', tag: '#googtrans(en|hu)'},
            {country: 'Italy', label: 'Italy (Italian)', language: 'it', tag: '#googtrans(en|it)'},
            {country: 'Netherlands', label: 'Netherlands (Dutch)', language: 'nl', tag: '#googtrans(en|nl)'},
            {country: 'Norway', label: 'Norway (Norwegian)', language: 'no', tag: '#googtrans(en|no)'},
            {country: 'Poland', label: 'Poland (Polish)', language: 'pl', tag: '#googtrans(en|pl)'},
            // {country: 'Portugal', label: 'Portugal (Portuguese)', language: 'pt', tag: '#googtrans(en|pt)'},
            {country: 'Russia', label: 'Russia (Russian)', language: 'ru', tag: '#googtrans(en|ru)'},
            {country: 'Slovakia', label: 'Slovakia', language: 'sk', tag: '#googtrans(en|sk)'},
            {country: 'Slovenia', label: 'Slovenia (Slovenian)', language: 'sl', tag: '#googtrans(en|sl)'},
            {country: 'Spain', label: 'Spain (Spanish)', language: 'es', tag: '#googtrans(en|es)'},
            {country: 'Sweden', label: 'Sweden (Swedish)', language: 'sv', tag: '#googtrans(en|sv)'},
            {country: 'Switzerland', label: 'Switzerland (French)', language: 'fr', tag: '#googtrans(en|fr)'},
            {country: 'Switzerland', label: 'Switzerland (German)', language: 'de', tag: '#googtrans(en|de)'},
            {country: 'Turkey', label: 'Turkey (Turkish)', language: 'tr', tag: '#googtrans(en|tr)'},
            {country: 'UK', label: 'UK (English)', language: 'en', tag: '#googtrans(en|en)'},
            {country: 'Ukraine', label: 'Ukraine (Russian)', language: 'ru', tag: '#googtrans(en|ru)'},
        ]
    },
    {
        region: 'Asia Pacific',
        content: [
            // {country: 'Australia', label: 'Australia (English)', language: 'en', tag: '#googtrans(en|en)'},
            {country: 'India', label: 'India(Hindi)', language: 'hi', tag: '#googtrans(en|hi)'},
            {country: 'China', label: 'China (Simplified Chinese)', language: 'zh-CN', tag: '#googtrans(en|zh-CN)'},
            {country: 'Hong Kong SAR', label: 'Hong Kong SAR (Traditional Chinese)', language: 'zh-TW', tag: '#googtrans(en|zh-TW)'},
            // {country: 'Hong Kong SAR', label: 'Hong Kong SAR (English)', language: 'en', tag: '#googtrans(en|en)'},
            {country: 'Japan', label: 'Japan (Japanese)', language: 'ja', tag: '#googtrans(en|ja)'},
            // {country: 'New Zealand', label: 'New Zealand (English)', language: 'en', tag: '#googtrans(en|en)'},
            // {country: 'Singapore', label: 'Singapore (English)', language: 'en', tag: '#googtrans(en|en)'},
            // {country: 'Taiwan Region', label: 'Taiwan Region (Traditional Chinese)', language: 'zh-TW', tag: '#googtrans(en|zh-TW)'},
        ]
    },
    // {
    //     region: 'Africa & Middle East',
    //     content: [
    //         {country: 'South Africa', label: 'South-Africa (English)', language: 'en', tag: '#googtrans(en|en)'},
    //         {country: 'UAE', label: 'UAE (English)', language: 'en', tag: '#googtrans(en|en)'},
    //     ]
    // }
    
];
