import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Pagination = (props) => {
    const { activePage, onPageChange, pageData } = props;
    
    const _setActivePage = ( page, active ) =>{
        return page === active ? 'active' : null;
    }

    const _pageChangeHandler = ( event, _p ) =>{
        event.preventDefault();
        onPageChange(_p);
    }

    const GeneratePages = ( props ) =>{
        const maxPage = props.totalPages > 5 ? 5 : props.totalPages; 
        const showNext = props.totalPages > maxPage ? true : false;
        let arrayElements = []; 
        
        for( let _p = 1; _p <= props.totalPages; _p++ ){
            if( _p <= maxPage ){
                arrayElements.push(<li key={_p} className={ _setActivePage(_p, activePage) }>
                    <Link to="#" onClick={ (ev) => _pageChangeHandler(ev, _p) }>{_p}</Link>
                </li>)
            }
        }
        
        if( showNext )
            arrayElements.push (
                <li key="next" className="last"><Link to="#" onClick={ (ev) => onPageChange( activePage + 1 ) } >next</Link></li>
            )

        return arrayElements;

        
    }
    
    if( pageData && pageData.hasOwnProperty('totalPages') && pageData.totalPages > 1)
        return (
            <div className="row">
                <div className="col-12"> 
                    <ul className="pegination">
                        
                        <GeneratePages totalPages={pageData.totalPages} />
                        
                    </ul>	 
                </div>
            </div>
        )
    else
        return null;
}

export default Pagination;
