import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../constants/config';

const INPUT_CHANGED = 'input_changed';
const INPUT_TOUCHED = 'input_touched';

const formReducer = (state, action) =>{
    switch( action.type ){
        case INPUT_CHANGED: {
            
            const { formData, fromError, isFormValid } = state;
            let errorFlag = false;
            
            if( action.value ){
                formData[ action.field ] = action.value;
                fromError[ action.field ] = false;
            }

            for( let _k in formData ){
                if( !formData[_k] )
                    errorFlag = true;
            }

            return {
                ...state,
                formData,
                fromError,
                isFormValid : errorFlag
            };
        }

        case INPUT_TOUCHED: {
            const { formTouch } = state;
            formTouch[ action.field ] = true;
            
            return {
                ...state,
                formTouch
            };
        }
    }

    return state;
}

const EnqueryNow = (props) => {

    const {showExpert, handleModal, title } = props;
    const [ show, setShow ] = useState( showExpert );
    const handleClose = () => {setShow(false); handleModal(); }

    // state initialization
    const initState = {
        formData: {
            email: '',
            message: ''
        },

        fromError: {
            email: true,
            message : true
        },

        formTouch: {
            email: false,
            message : false
        },

        fromErrorMsg: {
            email: 'Email is required',
            message : 'Message is required',
        },
        isFormValid: false
    };

    const formSubmitHandler = ( ev ) =>{
        ev.preventDefault();
        const { formData, isFormValid } = formState;
        console.log( formData );
        if( isFormValid ){
            handleClose();
        }else
            _showErrors();
            
    }

    function _showErrors(){
        const { formData } = formState;
        for ( let _k in formData ){
            formDispatch({ type: INPUT_TOUCHED, field: _k });
            formDispatch({ type: INPUT_CHANGED, field: _k, value: formData[_k] });
        }
    }

    function onChangeCaptcha(value) {
        console.log("Captcha value:", value);
    }

    useEffect(()=>{
        setShow( showExpert );
    },[showExpert]);

    const [ formState, formDispatch ] = useReducer( formReducer, initState );

    return (
        <Modal className="cmnmodal logmodal gensmallmodal" size="lg" centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Form className="logholder" onSubmit={formSubmitHandler}> 
                    <Link className="logo" to="#">
                        <img src={ require("../assets/images/logo.png")} alt=""/>
                    </Link>
                    <h3>{ title ? title : 'Enquire Now' }</h3>
                    
                    <Form.Group> 
                        <Form.Control 
                            className={ formState.fromError.email && formState.formTouch.email ? 'error' : null }
                            type="email" 
                            placeholder="Email"
                            onBlur={(ev) => formDispatch({type: INPUT_TOUCHED, field: 'email'})}
                            onChange={(ev) => formDispatch({ type: INPUT_CHANGED, field: 'email', value: ev.target.value })}
                        />
                        { formState.fromError.email && formState.formTouch.email &&
                            <span className='error'>
                                { formState.fromErrorMsg.email }
                            </span>
                        }
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Control 
                            className={ formState.fromError.message && formState.formTouch.message ? 'error' : null }
                            as="textarea" 
                            rows="3" 
                            placeholder="Message" 
                            onBlur={(ev) => formDispatch({type: INPUT_TOUCHED, field: 'message'})}
                            onChange={(ev) => formDispatch({ type: INPUT_CHANGED, field: 'message', value: ev.target.value })}
                        />
                        { formState.fromError.message && formState.formTouch.message &&
                            <span className='error'>
                                { formState.fromErrorMsg.message }
                            </span>
                        }
                    </Form.Group>
                    <Form.Group>
                        <ReCAPTCHA
                            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={onChangeCaptcha}
                        />
                    </Form.Group>
                    <Form.Group> 
                        <Button 
                            type="submit"
                            disabled={ !formState.isFormValid ? true : false } 
                            className={ !formState.isFormValid ? 'formDisabled' : null } 
                        >
                            Submit
                        </Button>  
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default EnqueryNow;
